import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { gql, useMutation,useQuery } from "@apollo/client";
import { Loaderr } from "./components/Loaderr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFormApiHelper from "./utils/useFormApiHelper";
import { FormPlanContext } from "./pages/form-builder/dashboard/ContextHelper";
import 'bootstrap/dist/css/bootstrap.min.css';



const createTerminateForm = gql`mutation CreateTerminateForm {
  CreateTerminateForm {
    team_id
    form_id
  }
}`
const customeFieldCount = gql`query CountCustomFields {
  CountCustomFields {
    count
  }
}`

const getDefaultFeilds = gql`
mutation CreateDefaultFields {
  CreateDefaultFields {
    response
  }
}`

const FormDetails = ({

   FormStatusInfo,
   refetch,
   formId,
   formName,
   DashboardForms,
   setDashboardForms,
   base_form_template,
   form_type,
   deleteForm
}) => {
   const NavigateToURL = useNavigate();
   const {
      DuplicateForm,
      CountFormEntries,
      GetFormEntries,
      UpdateFormName,

   } = useFormApiHelper();
   const [FormTitleName, setFormTitleName] = useState(formName);
   const [formStatus] = useState(FormStatusInfo);
   const [isHovered, setHovered] = useState(false);
   const { countFormEntriesInOneCallData } = CountFormEntries(formId);
   const { duplicateFormMutation, duplicateFormData } = DuplicateForm();
   const { data } = GetFormEntries(formId);
   const { UpdateFormData } = UpdateFormName();
   // console.log("npm",typeof(formName))

   const handleFormNameEdit = (id) => {
      if (index == null) {
         NavigateToURL(`build/${id}`);
      }
   };

   const handelEntryClick = () => {
      NavigateToURL(`entries/${formId}`, {
         state: { entries: data },
      });
   };


   function copyTextz() {
      const copyText = "https://app.datalabz.re/forms/" + formId;

      const Myicon = <i className="iconify fs-5" data-icon="noto:clipboard"></i>;

      navigator.clipboard.writeText(copyText).then(() => {
         toast.success("copied to clipboard!" + copyText, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
            icon: Myicon,
            hideProgressBar: true,
            pauseOnHover: false,
            closeOnClick: true,
            theme: "dark",
         });
      });
   }

   const handleDuplicateChange = () => {
      duplicateFormMutation({
         variables: {
            formId: formId,
         },
      });

      setTimeout(() => {
         setDashboardForms([...DashboardForms, duplicateFormData?.DuplicateForm]);
      }, 2000);
      window.location.reload();
   };

   const [index, setIndex] = useState(null);
   const handleFormRename = (formId) => {
      setIndex(formId);
   };
   const handleFormTitleChange = (e) => {
      setFormTitleName(e.target.value);
   };
   const handleFormTitleSave = (e) => {
      e.preventDefault();
      UpdateFormData({
         variables: {
            formId: formId,
            formName: FormTitleName,
         },
      });
      setIndex(null);
   };
   const handleFormDelete = () => {
      deleteForm({
         variables: {
            formId: formId,
         },
      });
      const newForm = DashboardForms.filter((item) => item.form_id !== formId);
      refetch();
      setDashboardForms(newForm);
   };
   const handleFormTitleChangeCancel = (e) => {
      e.preventDefault();
      setIndex(null);
      setFormTitleName(formName);
   };


   return (
      <div
         className="d-flex flex-wrap border rounded-3 bg-white fm-list-container mb-1 "
         key={formId}
      >
         <div
            className={`d-flex flex-wrap col-12 col-md-6 align-items-center p-4 border-0 border-end border your ${isHovered ? "hovered" : ""
               }`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
         >
            <span
               className=" fs-6 p-0 m-0 text-capitalize pointer pint ml-2"
               onClick={(e) => {
                  handleFormNameEdit(formId);
               }}
            >
               {index === formId ? (
                  <>
                     <input
                        type="text"
                        className="text pb-1 pt-0 tr-ease"
                        style={{
                           border: "none",
                           borderBottom: "1px solid #dee2e6",
                           outline: "none",
                           padding: "0",
                           margin: "0",
                           fontSize: "14px",
                           textTransform: "capitalize",
                        }}
                        value={FormTitleName}
                        onChange={handleFormTitleChange}
                     />
                     <span
                        onClick={(e) => {
                           handleFormTitleSave(e);
                        }}
                        style={{ color: "green" }}
                     >
                        <i
                           className="iconify me-2 fs-5"
                           data-icon="iconoir:check"
                        // width="25"
                        // height="25"
                        ></i>
                     </span>
                     <span
                        onClick={(e) => {
                           handleFormTitleChangeCancel(e);
                        }}
                        style={{ color: "red" }}
                     >
                        <i
                           className="iconify fs-5"
                           data-icon="iconoir:cancel"

                        ></i>
                     </span>
                  </>
               ) : (
                  <>
                     <span>{FormTitleName}</span>
                  </>
               )}
            </span>
            <span>
               {index == null && (
                  <span
                     className="icon"
                     style={{ marginLeft: "10px" }}
                     onClick={() => {
                        handleFormRename(formId);
                     }}
                  >
                     <i
                        className="iconify pointer text-secondary"
                        data-icon="iconamoon:edit-fill"
                        width="22"
                        height="20"
                     ></i>
                  </span>
               )}
            </span>
            <span
               className="badge bg-secondary fs-small fw-normal rounded-pill"
               style={{ marginLeft: "auto" }}
            >
               {" "}
               {base_form_template || "Custom Form"}
            </span>

            <span
               className="badge bg-primary fs-small fw-normal rounded-pill"
               style={{ marginLeft: "5px" }}
            >
               {form_type}
            </span>
         </div>

         <div className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint"
            onClick={() => handelEntryClick()}>
            <b>{countFormEntriesInOneCallData?.countFormEntriesToday?.count || 0}</b>
            <i className="iconify fs-5" data-icon="bx:list-ul" />
         </div>

         <div
            className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint"
            onClick={() => handelEntryClick()}
         >
            <b>{countFormEntriesInOneCallData?.countFormEntries.count || 0}</b>
            <i className="iconify fs-5" data-icon="bx:list-ul" />
         </div>



         {
            formStatus ? (<div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
               <span className="p-0 m-0 small"> <i className="iconify" style={{ color: "green" }} data-icon="fluent:live-20-filled"></i> Live</span>
            </div>) : (<div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
               <span className="p-0 m-0 small"> <i className="iconify" style={{ color: "red" }} data-icon="fluent:live-20-filled"></i> Draft</span>
            </div>)
         }

         <div className="d-flex align-items-center dropdown justify-content-center p-4 col col-md-1 btnn pointer" >
            <div>
               <i
                  className="iconify dropdown-toggle fs-3"
                  data-icon="fe:elipsis-h"
                  data-bs-toggle="dropdown"
                  ></i>

               <ul className="dropdown-menu shadow-lg border-0 py-0">
                  <li
                     id={formId}
                     onClick={(e) => {
                        handleFormNameEdit(formId);
                     }}
                  >
                     <a here="#" className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                        Edit
                        <i className="iconify" data-icon="clarity:note-edit-line"></i>
                     </a>
                  </li>

                  <NavLink to={`/forms/entries/${formId}`}>
                     <li>
                        <a here="" className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                           View entries
                           <i className="iconify" data-icon="bx:list-ul"></i>
                        </a>
                     </li>
                  </NavLink>

                  <li onClick={() => { handleDuplicateChange(formId); }}>
                     <a here="" className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                        Duplicate
                        <i className="iconify" data-icon="fluent:copy-24-regular"></i>
                     </a>
                  </li>


                  <NavLink to={`/forms/${formId}`} target="_blank">
                     <li>
                        <a className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                           Preview
                           <i className="iconify" data-icon="fluent:eye-24-regular"></i>
                        </a>
                     </li>
                  </NavLink>

                  <li className="" onClick={copyTextz}>
                     <a className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                        Copy Link
                        <i className="iconify" data-icon="fluent:link-24-regular"></i>
                     </a>
                  </li>

                  {
                     
                     formName === "Terminate Form" ? <></> : (
                        <li
                           onClick={() => {
                              handleFormDelete(formId);
                           }}

                        >
                           <a className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                              Delete
                              <i className="iconify" data-icon="clarity:trash-line"></i>
                           </a>
                        </li>
                     )
                  }

               </ul>
            </div>

         </div>

         
      </div>
   );
};

const FormList = () => {
   const [DashboardForms, setDashboardForms] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [duplicate, setDuplicate] = useState(false);
   const { formPlan } = useContext(FormPlanContext);
   const [postsPerPage] = useState(10);
   const { GetFormDataForFormList, FormCount, DeleteForm } = useFormApiHelper();
   const { deleteForm, deleteFormLoading } = DeleteForm();




   const NavigateToURL = useNavigate();
   const { getFormsLoading, getFormsData, fetchMore, refetch } = GetFormDataForFormList(
      currentPage,
      postsPerPage
   );
   const { countTotalFormsData } = FormCount();
   const count = countTotalFormsData?.countTotalForms?.count;
   console.log("count", count);
   useEffect(() => {
      let forms = getFormsData?.forms;
      setDashboardForms(forms);
   }, [getFormsData?.forms]);


   const [TerminateForm, { loading }] = useMutation(createTerminateForm, {
      onCompleted: (data) => {
         refetch();
      }
   })
   const [defaultFeilds] = useMutation(getDefaultFeilds, {
      onCompleted: (data) => {
         refetch();
      }
   })

   const { data: customFieldCountData } =useQuery(customeFieldCount)
   console.log("customFieldCountData", customFieldCountData)


   useEffect(() => {

      // refetch()
      console.log("getFormsData>", getFormsData?.forms?.length)
      if (getFormsData?.forms?.length == 0) {
         defaultFeilds()
         TerminateForm()
      }
      console.log("getFormsData",typeof getFormsData?.forms.length)


   }, [ getFormsData?.forms])



   // const paginate = (pageNumber) => setCurrentPage(pageNumber);
   // const pageNumbers = [];
   // for (let i = 1; i <= Math.ceil(count / postsPerPage); i++) {
   //    pageNumbers.push(i);
   // }
   // const handlePageNumbers = async (number) => {
   //    paginate(number);
   //    fetchMore({
   //       variables: {
   //          offset: String(currentPage * postsPerPage - postsPerPage),
   //       },
   //    });
   // };
   const totalPages = Math.ceil(count / postsPerPage);

   const paginate = (pageNumber) => setCurrentPage(pageNumber);

   const handlePageNumbers = async (number) => {
      paginate(number);
      fetchMore({
         variables: {
            offset: String((number - 1) * postsPerPage),
         },
      });
   };

   const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 3;

      if (totalPages <= maxPagesToShow + 2) {
         for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
               <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
                  <a onClick={() => handlePageNumbers(i)} className="page-link px-3 text-dark">
                     {i}
                  </a>
               </li>
            );
         }
      } else {
         pageNumbers.push(
            <li key={1} className={`page-item ${1 === currentPage ? "active" : ""}`}>
               <a onClick={() => handlePageNumbers(1)} className="page-link px-3 text-dark">
                  1
               </a>
            </li>
         );

         if (currentPage > maxPagesToShow) {
            pageNumbers.push(
               <li key="dot-start" className="page-item">
                  <span className="page-link">...</span>
               </li>
            );
         }

         for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
            pageNumbers.push(
               <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
                  <a onClick={() => handlePageNumbers(i)} className="page-link px-3 text-dark">
                     {i}
                  </a>
               </li>
            );
         }

         if (currentPage < totalPages - maxPagesToShow + 1) {
            pageNumbers.push(
               <li key="dot-end" className="page-item">
                  <span className="page-link">...</span>
               </li>
            );
         }

         pageNumbers.push(
            <li key={totalPages} className={`page-item ${totalPages === currentPage ? "active" : ""}`}>
               <a onClick={() => handlePageNumbers(totalPages)} className="page-link px-3 text-dark">
                  {totalPages}
               </a>
            </li>
         );
      }

      return pageNumbers;
   };


   if (deleteFormLoading) return <Loaderr />
   if (getFormsLoading && loading) return <Loaderr />;


   return (
      <div>
         <ToastContainer />
         <div className="main fmc-main p-0">

            <section className="container-lg container-fluid pt-5 pb-5 mb-5" style={{ minHeight: "700px" }}>
               <div
                  className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
                  role="alert"
               >
                  <div className="d-flex flex-wrap align-items-center">
                     <div className="d-flex align-items-center my-2">
                        <i className="bx bx-mobile fs-5"></i>
                        <i className="bx bx-arrow-to-right me-2 fs-5"></i>
                        <i className="bx bx-desktop me-2 fs-5"></i>
                     </div>

                     <span className="my-2">
                        Please switch to desktop and optimize this view. Smaller screens
                        are not efficient. To learn more click here
                     </span>
                  </div>
               </div>


               <div className="d-flex flex-wrap justify-content-between">
                  <h5>Forms and Integration</h5>
                  <div className="left">
                     {
                        DashboardForms?.length >= formPlan?.FormLimit ? (<> <button
                           className="btn btn-primary float-end "
                           onClick={() => NavigateToURL("create-form")}
                           disabled={true}
                        >
                           Create New Form

                        </button>  </>) : (
                           <button
                              className="btn btn-primary float-end"
                              onClick={() => NavigateToURL("create-form")}
                           >
                              Create New Form
                           </button>
                        )
                     }

                  </div>
               </div>


               <div className="w-100">
                  {
                     DashboardForms?.length >= formPlan?.FormLimit ?
                        (
                           <div className="limit-alert w-100 my-3" >
                              <div className="limit-icon">
                                 <i className="iconify" data-icon="dashicons:lock" ></i>
                              </div>
                              You have reached the limit of {formPlan?.FormLimit} forms. To add more forms please upgrade your plan.
                           </div>
                        ) : (
                           <span style={{ marginTop: "10px" }} className="text-secondary small mt-2">Customise forms and Stages with your own fields or use predefined inputs. You can create upto {formPlan?.FormLimit} forms
                           </span>
                        )
                  }
               </div>


               <div className="d-flex flex-wrap gap-4 py-3">

                  <div className="fm-crd">
                     <i className="iconify fs-1" data-icon="pepicons-pencil:file" ></i>
                     <div className="vr"></div>
                     <b className="fs-2">{count|| 0}</b>
                     <span>Out of {formPlan?.FormLimit || 0} forms</span>
                  </div>

                  <div className="fm-crd">
                     <i className="iconify fs-1" data-icon="bi:input-cursor" ></i>
                     <div className="vr"></div>
                     <b className="fs-2">
                        {customFieldCountData?.CountCustomFields?.count || 0}
                     </b>
                     <span>Out of {formPlan?.CustomFieldLimit} Custom fields</span>
                     {/* <i className="iconify text-danger fs-5" data-icon="mdi:warning" ></i> */}
                  </div>

                  <div className="fm-crd">
                     <i className="iconify fs-1" data-icon="carbon:list" ></i>
                     <div className="vr"></div>

                     <div className="d-flex flex-column gap-3">
                        {/* <span className="fs-6">{formPlan?.EntryLimit || 0} DashboardForms entries per month <span className="small text-secondary">- Renews 12/02/24</span> </span> */}

                        <span className="fs-6">{formPlan?.EntryLimit || 0} DashboardForms entries per month  </span>
                        <div className="progress w-100 rounded-pill mb-2" style={{ height: "10px" }} >
                           <div className="progress-bar bg-success" role="progressbar" style={{ width: "10%" }}>
                           </div>
                        </div>

                     </div>
                  </div>

               </div>

               {DashboardForms?.length > 0 && (
                  <>
                     <section className="d-flex flex-column py-4 gap-3" style={{ paddingBottom: "400px !important" }}>
                        <div className="d-md-flex flex-wrap border rounded-3 d-none fm-list-container-thead">
                           <div className="d-flex flex-wrap col-12 col-md-6 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                              <b className="p-0 m-0">Forms</b>
                           </div>
                           <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                              <p className="p-0 m-0">Today</p>
                           </div>

                           <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                              <p className="p-0 m-0">Total Entries</p>
                           </div>
                           <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
                              <p className="p-0 m-0">Status</p>
                           </div>
                           <div className="d-flex align-items-center justify-content-center px-4 col col-md-1"></div>
                        </div>

                        <section className="d-flex flex-column py-4 gap-3" style={{ paddingBottom: "800px !important" }}>
                           
                           {DashboardForms?.length > 0 &&
                              DashboardForms.map((item) => (
                                 <FormDetails
                                    deleteForm={deleteForm}
                                    refetch={refetch}
                                    Formlength={DashboardForms?.length}
                                    key={item?.form_id}
                                    formId={item?.form_id}
                                    base_form_template={item?.base_form_template}
                                    formName={item?.form_name}
                                    form_fields={item?.form_fields?.length}
                                    form_type={item?.form_type}
                                    setDashboardForms={setDashboardForms}
                                    DashboardForms={DashboardForms}
                                    FormStatusInfo={item?.status}
                                    duplicate={duplicate}
                                    setDuplicate={setDuplicate}
                                 />
                              ))}
                        </section>
                        {/* <div className="d-flex justify-content-center">
                           <nav aria-label="Page navigation example">
                              <ul className="pagination pagination-mb">
                                 {pageNumbers.map((number) => (
                                    <li key={number} className="page-item">
                                       <a
                                          onClick={() => handlePageNumbers(number)}
                                          className="page-link px-3 text-dark"
                                       >
                                          {number}
                                       </a>
                                    </li>
                                 ))}
                              </ul>
                           </nav>
                        </div> */}

                     <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                           <a onClick={() => handlePageNumbers(currentPage - 1)} className="page-link px-3">
                              <span>&lt;</span>
                           </a>
                        </li>
                        {renderPageNumbers()}
                        <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                           <a onClick={() => handlePageNumbers(currentPage + 1)} className="page-link px-3">
                              <span>&gt;</span>
                           </a>
                        </li>
                     </ul>
                     </section>
                  </>
               )}
               {DashboardForms?.length === undefined ? <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                     <span className="sr-only">Loading...</span>
                  </div>
               </div> : (
                  <>
                  </>
               )}
            </section>
            <div className="my-5 py-5"></div>
         </div>
      </div>
   );
};

export default FormList;