import React, { useContext } from "react";
import { boardConetxt } from "../../../../pages/form-builder/dashboard/ContextHelper";
import { gql, useMutation } from "@apollo/client";
import useFormApiHelper from "../../../../utils/useFormApiHelper";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
const UpdateFormFields = gql`
mutation UpdateField($fieldId: String, $formId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, form_id: $formId, field_data: $fieldData) {
    field_id
  }
}`
const updateForm = gql`
mutation UpdateForm($formData: GraphQLJSON, $formId: String) {
  UpdateForm(form_data: $formData, form_id: $formId) {
    team_id
    form_id
  }
}`
export function ModalFooter({ ElementSettingData, handleCloseModalClick, elementid, showErrorInFooter }) {
  const { board, setboard } = useContext(boardConetxt);
  const { GetFormData } = useFormApiHelper();
  const params = useParams();

  const { formData } = GetFormData(params);


  const [UpdateField] = useMutation(UpdateFormFields);
  const [UpdateFormSettings] = useMutation(updateForm);
  useEffect(() => {
    const fieldToUpdate = board.find((item) => item?.elementid === elementid);
    if (fieldToUpdate) {
      UpdateField({
        variables: {
          fieldId: elementid,
          formId: params?.id,
          fieldData: {
            field_name: ElementSettingData?.label,
            input_type: fieldToUpdate?.type,
            settings: ElementSettingData,
          },
        },
      });
    }
  }, [ElementSettingData, elementid]); // Trigger when ElementSettingData or elementid changes

  const handleFormSettingSubmit = (e) => {
    e.preventDefault();
    const names = board.find((item) => item?.elementid === elementid)?.name;
    const newboard = board.map((item) => {

      const obj = { ...item }
      if (obj?.elementid === elementid) {
        const name = obj?.id === elementid ? obj?.name : obj?.name
        // console.log("obj>>>>", names, ElementSettingData?.label);
        obj.settings = ElementSettingData;
        UpdateField({
          variables: {
            fieldId: elementid,
            formId: params?.id,
            fieldData: {
              field_name: ElementSettingData?.label,
              input_type: item?.type,
              settings: ElementSettingData,
            },
          },
        });

      }

      return obj;

    });
    console.log("newboard", newboard);
    setboard(newboard);
    UpdateFormSettings({
      variables: {
        formId: params?.id,
        formData: {
          ...formData?.formData,
          form_name: formData?.formData?.form_name,
          form_description: formData?.formData?.form_description,
          form_fields: board?.map((item) => item?.elementid),
          field_settings_data: newboard,
          form_conditions: formData?.formData?.form_conditions,
          status: formData?.formData?.status,
          pipeline: formData?.formData?.pipeline,
          stage: formData?.formData?.stage
        },
      },
    });

    handleCloseModalClick(e);
  };

  return (
    <>
      <div className="modal-footer">
        <button
          type="button"
          onClick={(e) => {
            handleCloseModalClick(e);
          }}
          className="btn btn-primary-outline"
        >
          Close
        </button>


        <button type="button" onClick={handleFormSettingSubmit} className="btn btn-primary" disabled={showErrorInFooter && ElementSettingData?.options.length > 0}>
          Save changes
        </button>

      </div>
      {
        showErrorInFooter && ElementSettingData?.options?.length > 0 ? <div className="d-flex justify-content-end"><p></p><p className="text-danger me-3"   >
          Please Fill the value in the Blank option
        </p></div> : null
      }
    </>
  );
}
