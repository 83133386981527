import { ModalFooter } from "./ModalFooter";
import { useState } from "react";

const EmailSettings = ({ elementid, board, maxLengthofLabel, handleCloseModalClick, elementSettingsData }) => {
  const ElementName = board.find(
    (item) => item?.elementid === elementid
  ).name;
  const initialvalue = {
    label: ElementName,
    predefine: "",
    size: "large",
    inputType: "Email",
    required: false,
    // duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };
  const showingFieldId = /\d/.test(elementid)

  const [Email, setEmail] = useState(
    Object.keys(elementSettingsData).length !== 0 ? elementSettingsData : initialvalue
  );

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEmail({ ...Email, [name]: value });
  };


  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Field Settings
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Label Field</span>
            </span>
          </label>
          <input
            placeholder="Placeholder for your field"
            defaultValue={Email.label == "" ? ElementName : Email.label}
            name="label"
            onBlur={handleInputChange}
            className="form-control input-bn"
            type="text"
            maxLength={maxLengthofLabel}
          />
          {/* placeholder for errors */}
        </div>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Predefined Value</span>
            </span>
          </label>
          <input
            placeholder="Leave empty if you dont have a default value"
            className="form-control input-bn"
            name="predefine"
            type="text"
            defaultValue={Email.predefine || ""}
            onBlur={handleInputChange}
            maxLength="30"
            minLength="20"
          />
          {/* placeholder for errors */}
        </div>
        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Position</span>
                {/* <div>
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-left"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="entypo:align-horizontal-middle"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-right"
                  />
                </div> */}
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Email.size || ""}
              name="size"
              onChange={handleInputChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              disabled="true"
              className="form-select input-bn"
              value={Email.inputType || ""}
              name="inputType"
              onChange={handleInputChange}
            >
              <option value="Email">Email</option>
              {/* <option value="Text">Text</option>
              <option value="Password">Password</option>
              <option value="Numbers">Numbers</option> */}
            </select>
          </div>
        </div>
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Email.required || ""}
                onChange={handleInputChange}
                name="required"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Email.hidden || ""}
                onChange={handleInputChange}
                name="hidden"
                id="check-10"
              />
              <label className="form-check-label ms-1" htmlFor="check-10">
                Hidden
              </label>
            </div>
            {/* <div className="me-3 ">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Email.duplicate || ""}
                onChange={handleInputChange}
                name="duplicate"
                id="check-2"
              />
              <label className="form-check-label ms-1" htmlFor="check-2">
                No Duplicate
              </label>
            </div> */}
          </div>
        </div>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Character Range</span>
            </span>
          </label>
          <div className=" d-flex">
            <div className="col-6 pe-1">
              <input
                placeholder="min value"
                defaultValue={Email.minValue || ""}
                onBlur={handleInputChange}
                name="minValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
            <div className="col-6 ps-1">
              <input
                placeholder="max value"
                defaultValue={Email.maxValue || ""}
                onBlur={handleInputChange}
                name="maxValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
          </div>
          {/* placeholder for errors */}
        </div>
        {/* text-area--------- */}
        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={Email.instructions || ""}
              name="instructions"
              onBlur={handleInputChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
          {/* placeholder for errors */}
        </div>
        {!showingFieldId && <span className="h6">System Field Id : {showingFieldId ? null : `${elementid}`}</span>
        }
      </div>
      <ModalFooter
        ElementSettingData={Email}
        handleCloseModalClick={handleCloseModalClick}
        elementid={elementid}
      />
    </>
  );
};

EmailSettings.propTypes = {};

export default EmailSettings;
