import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/LOGO-BLUE.png";
import { DatePicker } from "antd";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Controller, useForm, useFormContext } from "react-hook-form";



dayjs.extend(customParseFormat)


export function FormEntryDataTable({
  editable,
  formData,
  formEntryData,
  handleInputChange,
}) {
  console.log("formEntryData>>>>", formEntryData, "FormData", formData)
  const { register, handleSubmit, formState: { errors }, control, trigger } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [check, setCheck] = useState([true, false]);


  console.log("errors>>>>", errors)
  console.log(errors.email && errors.email.message)
  const navigate = useNavigate();
  const handleCheckboxChange = (elementId, value) => {
    const updatedCheckboxValues = [...formEntryData.formEntry.form_entry_data[0][elementId]];
    if (updatedCheckboxValues.includes(value)) {
      updatedCheckboxValues.splice(updatedCheckboxValues.indexOf(value), 1);
    } else {
      updatedCheckboxValues.push(value);
    }
    handleInputChange({
      target: {
        id: elementId,
        value: updatedCheckboxValues,
      },
    });
  };
  useEffect(() => {
    trigger()

  }, []);
  const handleInputType = (entry, formEntryData, handleInputChange, editable) => {
    if (entry?.type === "Email") {
      console.log("entry>>>><", formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.length)

      return (

        <input
          className="col-5 form-control"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ] || ""
          }
          // onChange={(e) => handleInputChange(e)}
          disabled={editable}
          {...register(entry?.elementid, {
            // required: {
            //   value: formEntryData?.formEntry?.form_entry_data[0][entry?.elementid].length == 0 ? true : false,
            //   message: "Please Enter Email Address",
            // },
            maxLength: {
              value: 31,
              message: "Email Address should not exceed 31 characters",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Address",
            },
            onChange: (e) => {
              handleInputChange(e);
            }
          })}

        />
      )





    } else if (entry?.type === "NumberField") {
      return (


        <input
          className="col-5 form-control"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          {...register(entry?.elementid, {
            maxLength: {
              value: 13,
              message: "Please Enter less then 13 Characters",
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Please Enter Number Only",
            },
            onChange: (e) => {
              handleInputChange(e);
            }
          })}
          // onChange={(e) => handleInputChange(e)}
          disabled={editable}
        />
      )
    } else if (entry?.type === "Date") {
      console.log("entry>>>>", formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])

      return (
        <DatePicker
          format="MM/DD/YYYY"
          disabled={editable}
          className="date-picker-input form-control input-bn w-100"
          onChange={(date, dateString) => {
            console.log("dateString", dateString, date);
            handleInputChange({
              target: {
                name: entry?.elementid,
                value: dateString,
              },
            });
          }
          }
          defaultValue={""}
          // value={formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]}
          value={dayjs(formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])}
          id={entry?.elementid}
        />

      )

    } else if (entry?.type === "RadioButton" || entry?.type === "DropDown") {
      return (
        <select
          className="col-5 form-select"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          onChange={(e) => handleInputChange(e)}
          disabled={editable}
        >
          <option value="">Please Select</option>
          {entry?.settings?.options?.map((item) => (
            <option key={item.id} value={item?.value}>
              {item?.value}
            </option>
          ))}
        </select>
      )
    } else if (entry?.type === "CheckBox") {
      console.log("entry>>>>", formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])
      let number = formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]
      console.log("number>>>>", number)

      return (
        <div className="form-check">

          {
            entry?.settings?.options?.map((item) => (
              console.log("item>>>>", item),
              <div key={item.id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={item.id}
                  value={item?.value}
                  checked={
                    formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.includes(item?.value) &&
                    formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.includes(item?.value) !== false
                  }
                  disabled={editable}
                  onChange={(e) => handleCheckboxChange(entry?.elementid, item?.value)}

                // {...register(entry?.elementid, {
                //  required:{
                //     value:formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.includes(item?.value) &&
                //     formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.includes(item?.value) !== false,
                //     message:"Please select an option"

                //  },

                // })}



                />
                <label
                  className="form-check-label text-secondary ms-1"
                  htmlFor={item.value}
                >
                  {item?.value}
                </label>
              </div>
            ))
          }
        </div>
      )
    } else if (entry?.type === "InputField") {
      return (
        <input
          className="col-5 form-control"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          // onChange={(e) => handleInputChange(e)}
          disabled={editable}
          {...register(entry?.elementid, {
            maxLength: {
              value: 80,
              message: "Please Enter Maximum 60 Characters",
            },
            onChange: (e) => {
              handleInputChange(e);
            }
          })}
        />
      )
    } else if (entry?.type === "TextArea") {
      return (
        <textarea
          className="col-5 form-control"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          // onChange={(e) => handleInputChange(e)}
          disabled={editable}
          {...register(entry?.elementid, {
            maxLength: {
              value: 500,
              message: "Please Enter Maximum 500 Characters",
            },
            onChange: (e) => {
              handleInputChange(e);
            }
          })}
        />
      )
    } else if (entry?.type === "Contact") {
      return (
        <input
          className="col-5 form-control"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          // onChange={(e) => handleInputChange(e)}

          disabled={editable}
          {...register(entry?.elementid, {
            maxLength: {
              value: 10,
              message: "Please Enter Maximum 10 Characters",
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Please Enter Number Only",
            },
            onChange: (e) => {
              handleInputChange(e);
            }
          })}
        />
      )
    } else if (entry?.type === "Time") {
      console.log("entry>>>>", formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])
      return (
        <TimePicker
          className="form-control input-bn"
          format={"HH:mm a"}
          minuteStep={5}
          disabled={editable}
          // onChange={(date, dateString) => {
          //  let e={
          //     target:{
          //       name:entry?.elementid,
          //       value:dateString
          //     }
          //  }
          //  handleInputChange(e)
          // }}
          onChange={(time, timeString) => {
            handleInputChange({
              target: {
                name: entry?.elementid,
                value: timeString,
              },
            });

          }}
          defaultValue={dayjs(formEntryData?.formEntry?.form_entry_data[0][entry?.elementid], "HH:mm a")}
          id={entry?.elementid}
        />


        //   value={dayjs(formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])}
        //   id={entry?.elementid}
        // />
        //   name={entry?.elementid}
        //   // value={formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]}
        //   // defaultValue={formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]}
        // />
      )
    } else if (entry?.type === "Ratings") {
      console.log("entry>>>>", formEntryData?.formEntry?.form_entry_data[0][entry?.elementid])
      return (
        <select
          className="col-5 form-select"
          id={entry?.elementid}
          value={
            formEntryData?.formEntry?.form_entry_data[0][
            entry?.elementid
            ]
          }
          onChange={(e) => handleInputChange(e)}
          disabled={editable}

        >
          <option value="">Please Select</option>
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <option key={index} value={index}>
                {index}
              </option>
            );
          })}

        </select>
      )

    }
  }
  console.log("entry>>>>", errors)
  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">
                Form Dashboard
              </span>
            </div>
          </div>
        </div>
      </header>
      <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
        <div className="d-flex flex-column gap-5 mb-3 mt-3">
          {
            // List all the form data fields and map them to the form entry data
            formData?.form_fields?.map((entry, index) => (
              // console.log("entry>>>>",entry.type),
              <div className="fs-6" key={index}>
                <div className="text-black-50 fw-bold">
                  {entry?.type === "Headings" ? entry?.settings.title :
                    entry?.type === "FreeText" ? entry?.settings.description :
                      entry?.settings.label || entry?.name
                  }
                </div>
                {(entry?.type !== "Headings" && entry?.type !== "FreeText") &&
                  <>
                    <hr className="hr op-10" />
                    {
                      handleInputType(entry, formEntryData, handleInputChange, editable)
                    }
                    {/* {
                  entry?.type == "RadioButton" || entry?.type == "DropDown" ? ( <select
                    className="col-5 form-select"
                    id={entry?.elementid}
                    value={
                      formEntryData?.formEntry?.form_entry_data[0][
                        entry?.elementid
                      ]
                    }
                    onChange={(e) => handleInputChange(e)}
                    disabled={editable}
                  >
                    <option value="">Please Select</option>
                    {entry?.settings?.options?.map((item) => (
                      <option key={item.id} value={item?.value}>
                        {item?.value}
                      </option>
                    ))}
                  </select>
                  )
                  : 
                  entry.type == "CheckBox" ? (
                    <div className="form-check">
                     {
                        entry?.settings?.options?.map((item) => (
                          console.log("item>>>>",item),
                          <div key={item.id} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item.id}
                              value={item?.value}
                              checked={
                                formEntryData?.formEntry?.form_entry_data[0][
                                  entry?.elementid
                                ]?.includes(item?.value)
                              }
                              onChange={(e) => handleInputChange(e)}
                              disabled={editable}
                            />
                            <label
                              className="form-check-label text-secondary ms-1"
                              htmlFor={item.value}
                            >
                              {item?.value}
                            </label>
                          </div>
                        ))



                     }
                     
                    </div>
                  ) : entry.type == "Date" ? (  
                    <input
                    type="date"
                    className="col-5 form-control"
                    id={entry?.elementid}
                    value={
                      formEntryData?.formEntry?.form_entry_data[0][
                        entry?.elementid
                      ]
                    }
                    onChange={(e) => handleInputChange(e)}
                    disabled={editable}
                  />
                  ):
                  (
                    (
                      <input
                      className="col-5 form-control"
                      id={entry?.elementid}
                      value={
                        formEntryData?.formEntry?.form_entry_data[0][
                          entry?.elementid
                        ]
                      }
                      onChange={(e) => handleInputChange(e)}
                      disabled={editable}
                    />
                    )
                  )
                } */}

                  </>
                }
                {errors[entry?.elementid] && <span className="text-danger">{errors[entry?.elementid].message}</span>}
                {formEntryData?.formEntry?.form_entry_data[0][entry?.elementid]?.length === 0 && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            ))
          }

        </div>
      </div>
    </>
  );
}
