import React, { useContext, useEffect, useState, useRef } from "react";
import { boardConetxt } from "../dashboard/ContextHelper";
import logo from "../../../assets/images/FULL-LOGO-BLUE.png";
import { BoardElemForPre } from "./BoardElemForPre";
import { useLocation, useNavigate } from "react-router-dom";
import { conditionContext } from "../dashboard/ContextHelper";
import {  useForm, useFormContext, useWatch } from "react-hook-form";
import { get_fields_to_show } from "../../../utils/conditions_parser";
import { useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import { gql,useMutation, useQuery } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllFormData = gql`
query FormData($formId: String, $oppKey: String) {
  formData(form_id: $formId) {
    form_id
    team_id
    form_name
    form_description
    form_fields
    form_conditions
    status
    form_created_date
    form_updated_date
    update_by
    base_form_template
    color_scheme
    logo_url
    form_type
    pipeline
    stage
  }
  opportunity(opp_key: $oppKey) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    opp_price
    opp_commission_percent
    fub_appt_id
    stage_id
    pipeline_id
    custom_fields
  }
  GetFieldsByTeamId {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

const FormData = gql`
      query FormData($formId: String) {
        formData(form_id: $formId) {
          form_id
          form_name
          team_id
          form_description
          form_fields
          form_conditions
          status
          form_created_date
          form_updated_date
          update_by
          base_form_template
          color_scheme
          logo_url
          form_type
          pipeline
          stage
          

        }
      }
    `;

const CreateFormEntry = gql`
      mutation CreateFormEntry($formId: String, $formEntryData: GraphQLJSON) {
        CreateFormEntry(form_id: $formId, form_entry_data: $formEntryData) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;

const opportunityData=gql`query Opportunity($oppKey: String) {
  opportunity(opp_key: $oppKey) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    opp_price
    opp_commission_percent
    fub_appt_id
    stage_id
    pipeline_id
    opp_custom_fields
    custom_fields
  }
}`
const GetFieldsByTeamId = gql`
query GetFieldsByTeamId($teamId: String) {
  GetFieldsByTeamId(team_id: $teamId) {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

const getISAUser=gql`
query GetISAOSAUsers($teamId: String) {
  GetISAOSAUsers(team_id: $teamId) {
    isa_list
    osa_list
  }
}`
const getAdminUser=gql`query GetAdminUsers($teamId: String) {
  GetAdminUsers(team_id: $teamId) {
    id
    name
  }
}`



function Preview() {
  const { board, setboard } = useContext(boardConetxt);
  const { condition, setCondition } = useContext(conditionContext);
  const [OppFieldData, setOppFieldData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  const [elementVisiblity, setElementVisiblity] = useState({});
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [fieldsToHide, setFieldsToHide] = useState([]);
  const [initElementVisiblity, setInitElementVisiblity] = useState({});
  const useWatchdata = useWatch();
  const methods = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const [paramsContext, setParamsContext] = useState(null);
  const [signature, setSignature] = useState(null);
  const { watch } = methods;
  const FormValues = useRef(watch());
  const params = useParams();
  
    const previewFlag = true;
    const { handleSubmit, formState: { errors } } = useFormContext();
  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);

 
  const { data:Formdata  } = useQuery(AllFormData, {
    variables: {
      formId: params.id,
      oppKey: getURLParams.get('InputField-opp-key'),
    },
  });
  
  const { data:TeamData,loading:TeamFieldsLoading } = useQuery(GetFieldsByTeamId, {
    variables: { teamId: Formdata?.formData?.team_id },
    
  });
  // console.log(">>MM", teamField)
  const { data: oppData } = useQuery(opportunityData, {
    variables: { oppKey: getURLParams.get('InputField-opp-key') },
  });
  const {
    loading: formDataLoading,
    data: formData,
  } = useQuery(FormData, {
    variables: { formId: params.id },
    onCompleted: (data) => {
      //console.log("data", data);
    },
  });
console.log("form",formData)
  useEffect(() => {
    FormValues.current = watch();
  }, [watch()]);

  const { data, loading } = useQuery(getISAUser, {
    variables: {
      teamId:Formdata?.formData?.team_id
    },
  });

  useEffect(() => {
    const signatureParam = getURLParams.get('signature');
    const contextParam = getURLParams.get('context');
    setSignature(signatureParam);
    setParamsContext(contextParam);
    setShowLoader(true);


  }, []);

  const { data:adminData, loading:adminLoading } = useQuery(getAdminUser, {
    variables: {
      teamId:Formdata?.formData?.team_id
    },
  });


  const [createFormEntry, { loading: createFormEntryLoading,}] =
    useMutation(CreateFormEntry, {
      onError: (error) => {
        toast.error('some error occurred, please refresh the page', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setShowLoader(false);
      },
      onCompleted: (data) => {
        console.log('data>', data);
        setShowLoader(false);
        navigate(`/thankyou?signature=${signature}&context=${paramsContext}`);
      },
    });

  useEffect(() => {
    if (formDataLoading || createFormEntryLoading) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [formDataLoading, createFormEntryLoading]);

  const onSubmit = (data) => {
    if (!signature || !paramsContext) {
      toast.error('some error occurred, please refresh the page', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      return;
    }

    console.log('elementVisiblity', data);
    createFormEntry({
      variables: {
        formId: params.id,
        formEntryData: [data],
      },
    });
  };
  console.log('elementVisiblity', errors);
  console.log("Opp>>",OppFieldData)

  
  useEffect(() => {
    setOppFieldData(oppData);
  }, [oppData?.opportunity]);
  
  console.log('data>>>', TeamData);
  useEffect(() => {
    setboard(() => formData?.formData.form_fields);
    setCondition(() => formData?.formData.form_conditions);
  }, [formData?.formData]);
  
  useEffect(() => {
    console.log("Loaded Form Data:", formData);
    console.log("Loaded Opportunity Data:", oppData);
    console.log("Team Fields Data:", TeamData);
    console.log("Element Visibility:", elementVisiblity);
  }, [formData, oppData, TeamData, elementVisiblity]);
  
  useEffect(() => {
    let getfieldsToShow = Array.from(get_fields_to_show(useWatchdata, condition));
    const arr = Array.from(getfieldsToShow[0]);
    const arr2 = Array.from(getfieldsToShow[1]);
    setFieldsToShow(arr);
    setFieldsToHide(arr2);
  }, [useWatchdata]);

  useEffect(() => {
    let elemVisiblity = {};
    let Visiblity = '';
    board?.map((element) => {
      let elemkey = '';
      Visiblity = condition?.map((item) => {
        const found = item?.Fields?.find((element) => {
          elemVisiblity[element] = item?.Visibility;
        })?.Visibility;
      });
    });
    setInitElementVisiblity(() => elemVisiblity);
    setElementVisiblity((prev) => ({ ...prev, ...elemVisiblity }));
  }, [fieldsToHide, fieldsToShow, board]);

  useEffect(() => {
    setElementVisiblity((prev) => {
      let newElementVisiblity = prev;
      if (fieldsToShow?.length === 0 && fieldsToHide?.length === 0) {
        return { ...initElementVisiblity };
      }
      for (let key in initElementVisiblity) {
        if (fieldsToShow.includes(key)) {
          newElementVisiblity[key] = 'hide';
        } else if (fieldsToHide.includes(key)) {
          newElementVisiblity[key] = 'show';
        }
      }
      return { ...newElementVisiblity };
    });
  }, [fieldsToShow, fieldsToHide]);

  const col = '';
  const styles = col ? { backgroundColor: col, marginTop: '0px' } : { backgroundColor: '#065697', marginTop: '0px' };

  if (showLoader || TeamFieldsLoading ) return <Loaderr />;
 



  

  return (
    <>
      {createFormEntryLoading && <Loaderr/>}
    <ToastContainer/>
    <div className="main fmc-main px-0" style={styles}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="fm-condition mt-0">
          <section className="container-fluid mb-5 pb-5 col-lg-12 mx-auto">
            <div
              className="bg-white d-flex flex-wrap justify-content-center align-items-center py-3 px-4 mb-lg-5 mb-4 fixed-top"
              style={{ marginTop: "0px" }}
            >
              <span className="m-0 d-flex align-items-center">
                <i
                  className="iconify nav_icon me-1 fs-4"
                  data-icon="ic:baseline-dynamic-form"
                />
                {formData?.formData?.form_name}
              </span>
            </div>
            <section className="cols-xxl-6 col-xl-7 col-lg-8 col-md-8 col-sm-10 col-12 mb-5 pb-5 mx-auto d-flex align-items-center justify-content-center mt-5 pt-3">
              <div className="bg-white p-4 p-xl-5 mb-5 rounded-3 border w-100">

                <div className="w-100 px-2">
                  {/* we will use this later */}
                  {/* {
                    countFormEntriesInOneCallData?.countFormEntries.count >= FormCountLimit ? 
                    (
                      <div className="d-flex align-items-center">
                        <div className="limit-alert w-100 my-3" >
                          <div className="limit-icon">
                            <i className="iconify" data-icon="dashicons:lock" ></i>
                          </div>
                          Form Entries Exceeded per month
                        </div>
                      </div>
                    ) : null
                  } */}
                </div>
                <div className="d-flex border-bottom pb-3 mb-4">
                  <img
                    className="img-fluid mx-lg-auto"
                    src={logo}
                    width={130}
                    alt=""
                  />
                </div>
                <div className="container">
                  <div className="row align-items-end">
                    {board?.length > 0 &&
                      board?.map((element, index) => {
                        const showHide = elementVisiblity[element?.elementid];
                        const OppFieldName = TeamData?.GetFieldsByTeamId?.find((item) => item?.field_id == element?.elementid)?.opportunity_field_id;

                        const fieldName=OppFieldName?.split('_').slice(1).join('_');
                        const OppFieldName2 = TeamData?.GetFieldsByTeamId?.map(item => (item?.field_id === element?.elementid ? item.opportunity_field_id : null)).filter(id => id !== null)[0];

                        console.log("Opp Level 0",TeamData?.GetFieldsByTeamId,element?.elementid,OppFieldName2)
                        console.log("Opp Level 1",element.elementid,OppFieldData?.opportunity)
                        console.log("Opp Level 2",OppFieldData?.opportunity?.[fieldName],OppFieldData?.opportunity?.opp_custom_fields?.[OppFieldName])
                        console.log("Opp Level 3",OppFieldData,OppFieldData?.opportunity,OppFieldData?.opportunity?.opp_custom_fields,OppFieldName)

                        return (
                          <BoardElemForPre
                            element={element}
                            key={index}
                            OppFieldName={OppFieldName}
                            oppData={OppFieldData?.opportunity?.[fieldName] || OppFieldData?.opportunity?.opp_custom_fields?.[OppFieldName]}
                            showHide={showHide}
                            setElementVisiblity={setElementVisiblity}
                            previewFlag={previewFlag}
                            IsaList={data?.GetISAOSAUsers?.isa_list}
                            osaList={data?.GetISAOSAUsers?.osa_list}
                            AdminList={adminData?.GetAdminUsers}
                          />
                        );
                      })}
                  </div>
                </div>
                
                  <div className="d-flex justify-content-end mt-4 w-100">
                   {/* {countFormEntriesInOneCallData?.countFormEntries.count >= FormCountLimit ? (<div className="d-flex align-items-center">
                      <button className="btn btn-primary" type="submit" disabled={true}>
                        Submit
                      </button>
                    </div>) : (<div className="d-flex align-items-center">
                      <button className="btn btn-primary" type="submit" >
                        Submit
                      </button>
                    </div>)
                    } */}
                    <div className="d-flex align-items-center">
                      <button className="btn btn-primary" type="submit" >
                        Submit
                      </button>
                    </div>
                  </div>
                  
              </div>
            </section>
          </section>
        </section>
      </form>
    </div>
    </>
  );
}

export default Preview;
