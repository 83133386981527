import React, { useContext, useEffect, useState } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useFormContext } from "react-hook-form";
import { gql } from "@apollo/client";

const UpdateFormFields = gql`
mutation UpdateField($fieldId: String, $formId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, form_id: $formId, field_data: $fieldData) {
    field_id
  }
}`;

export const DropDownUi = ({
  elementid,
  showHide,
  oppFields,
  previewFlag = false,
  IsaList,
  osaList,
  AdminList
}) => {
  const disableElementForBuild = window.location.pathname.includes("build");

  const { board,setboard } = useContext(boardConetxt);
  const {
    formState: { errors }, control, setValue
  } = useFormContext();

  const [optValue, setOptValue] = useState([]);
  const [osaVal, setOsaVal] = useState([]);
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    if (oppFields) {
      setValue(elementid, oppFields);
    }
  }, [oppFields]);

  useEffect(() => {
    if (elementid === "DropDown-opp_isa" && IsaList) {
      const newOptions = IsaList.map((item, index) => ({
        id: index,
        value: item,
        isCheck: false
      }));
      setOptValue(newOptions);
    }
  }, [IsaList, elementid]);

  useEffect(() => {
    if (elementid === "DropDown-opp_assigned_osa" && osaList) {
      const newOptions = osaList.map((item, index) => ({
        id: index,
        value: item,
        isCheck: false
      }));
      setOsaVal(newOptions);
    }
  }, [osaList, elementid]);

  useEffect(() => {
    if (elementid === "DropDown-Admin" && AdminList) {
      const newOptions = AdminList.map((item, index) => ({
        id: index,
        value: item.name,
        isCheck: false
      }));
      setAdmin(newOptions);
    }
  }, [AdminList, elementid]);

  const ElementSettingsData = board.find(
    (item) => item?.elementid === elementid
  )?.settings;

  const { label, instructions, required, hidden,options } = ElementSettingsData || {};

  const initialStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  const ElementName = board.find((item) => item?.elementid === elementid)?.name;

  const currentOptions =
    elementid === "DropDown-opp_isa" ? optValue :
    elementid === "DropDown-opp_assigned_osa" ? osaVal :
    elementid === "DropDown-Admin" ? admin :
    options;

  const isOptionsEmpty = currentOptions?.length === 0;

  const checkingValidation = () => {
    if (required && showHide === "show") return false;
    if (!required && showHide === "show") return false;
    if (required && showHide === "hide") return false;
    if (!required && showHide === "hide") return false;
    if (required && hidden) return false;
    return required;
  };
console.log("elementid>",board)
const normalizeFieldName = (name) => {
  return name.replace(/[.\s]/g, '_'); // Replace dots and spaces with underscores
};

  return (
    <div
      className={(previewFlag && GetStyleClasses(elementid)) || ""}
      style={initialStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floatding form-group">
        <label>
          <span className="d-flex mb-2 align-items-center">
            <span>
              {label === undefined || label === "" ? ElementName : label}
              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>

        <Controller
          name={normalizeFieldName(elementid)}
         control={control}
          rules={{
            required: {
              value: checkingValidation(),
              message: "Please Select an option",
            },
          }}
          render={({ field }) => (
            <select
              id={elementid}
              className="form-select input-bn"
              placeholder="Please Select"
              disabled={disableElementForBuild}
              {...field}
            >
              <option value="">Choose an option</option>
              {currentOptions?.map((item) => (
                <option key={item?.id} value={item?.value}>
                  {item?.value}
                </option>
              ))}
            </select>
          )}
        />
      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {isOptionsEmpty && <span className="text-secondary">Please add options</span>}
        {errors && <span className="text-danger">{errors[elementid]?.message}</span>}
      </div>
    </div>
  );
};
