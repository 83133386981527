import { useState, useEffect, useContext, useCallback } from "react";
import { Droppable } from "react-beautiful-dnd";
import DragableElement from "../../../components/DragableElement";
import { ElementsUIGenrate } from "../../../components/ElementsUIGenrate";
import { ElemContext, boardConetxt } from "./ContextHelper";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import fublogo from "../../../assets/images/fub-circle.png"
import { gql } from "@apollo/client";

import { useQuery } from "@apollo/client";

// import { Loaderr } from "../../../components/Loaderr";
// import { Form } from "react-router-dom";
// import { AllElementDragableTab } from "./AllElementDragableTab";
import { FormPlanContext } from "./ContextHelper";
// import { Form } from "react-router-dom";
// import { fallbackHttpConfig } from "@apollo/client";
const GetFieldsByTeamId = gql`
query GetFieldsByTeamId($teamId: String) {
  GetFieldsByTeamId(team_id: $teamId) {
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

const FormBuilderRightSideBar = ({ formType, setCustomEleDeleteId,teamId,isCollapsed,setIsCollapsed
}) => {
  const { board } = useContext(boardConetxt);
  const {formPlan}=useContext(FormPlanContext)
  const { CustomElement, setCustomElement, defaultElement, setDefaultElement,AllFields,primary,setPrimary } =
    useContext(ElemContext);
  const [value, setValue] = useState("");
  const { GetFormFields } = useFormApiHelper();
  // const { TeamData, refetch ,TeamFieldsLoading} = GetFormFields();
  const [elementDisabled,setElementDisabled]=useState(false)
  const [TabElementId,setTabElementId]=useState("default-field-container")
  const { data:TeamData,loading:TeamFieldsLoading,refetch } = useQuery(GetFieldsByTeamId, {
    
    variables: { teamId: teamId }
    
  });

  console.log("TeamData", TeamData)
  useEffect(()=>{
    const data=TeamData?.GetFieldsByTeamId?.map((item)=>{
      if(item.field_sub_type===formType.split(" ")[0].toLowerCase()){
        return item
      }
    })
    console.log(">>MM",data)
  },[TeamData?.GetFieldsByTeamId,formType])
  
  // console.log(">>MM",teamField)
  useEffect(() => {
    // Initialize lists
    const defaultList = [];
    const customList = [];
    const primaryList = [];
    
    // Create a map of elements present on the board for quick lookup
    const boardElementMap = new Map(board?.map(item => [item.elementid, item]));
  
    // Process form fields
    TeamData?.GetFieldsByTeamId?.forEach((item) => {
      const fieldType = formType.split(" ")[0]?.toLowerCase();
  
      // Define the object to be used
      const obj = {
        elementid: item.field_id,
        name: item.field_name,
        type: item.input_type || "InputField",
        settings: item.settings || {},
      };
  
      // Add to appropriate list based on field type
      if (item.field_sub_type?.toLowerCase() === fieldType) {
        if (item.field_type === "default") {
          defaultList.push(obj);
        } else if (item.field_type === "custom") {
          customList.push(obj);
        } else if (item.field_type === "primary") {
          primaryList.push(obj);
        }
      }
    });
  
    // Function to move elements present on the board to the end of the list
    const updateListWithBoardElements = (list) => {
      const listWithBoardElementsAtEnd = list.filter(item => !boardElementMap.has(item.elementid));
      const boardElements = list.filter(item => boardElementMap.has(item.elementid));
      return [...listWithBoardElementsAtEnd, ...boardElements];
    };
  
    // Update lists to include elements already on the board at the end
    const updatedDefaultList = updateListWithBoardElements(defaultList);
    const updatedCustomList = updateListWithBoardElements(customList);
    const updatedPrimaryList = updateListWithBoardElements(primaryList);
  
    // Set state with updated lists
    setPrimary(updatedPrimaryList);
    setDefaultElement(updatedDefaultList);
    setCustomElement(updatedCustomList);
  
    // Check and set elementDisabled
    setElementDisabled(customList.length >= formPlan?.CustomFieldLimit);
  }, [board, TeamData?.GetFieldsByTeamId, formType, formPlan?.CustomFieldLimit]);
  
  // useEffect(() => {
  //   let defaultList = [];
  //   let customList = [];
  //   let primaryList=[]
  //   const tempdata = TeamData?.GetFieldsByTeamId?.map((item) => {

    


  //     //  console.log("item<>",formType.split(" ")[0].toLowerCase()===item?.field_sub_type?.toLowerCase())
      
  //     if (formType.split(" ")[0].toLowerCase()===item?.field_sub_type?.toLowerCase() && item.field_type === "default") {
  //       // const str = item.field_id.split("-")[0];
  //       const obj = {
  //         elementid: item.field_id,
  //         name: item.field_name,
  //         type: item.input_type || "InputField",
  //         settings: item.settings == null ? {} : item.settings,
  //       };
  //       defaultList.push(obj);
  //       // console.log("obj",obj)
  //     } else if (formType.split(" ")[0].toLowerCase()===item?.field_sub_type?.toLowerCase() && item.field_type === "custom") {
  //       // console.log("item<>",[item])
  //       // const str = item.field_id.split("-")[0];

  //       const obj = {
  //         elementid: item.field_id,
  //         name: item.field_name,
  //         type: item.input_type || "InputField",
  //         // settings:{}
  //         settings: item.settings == null ? {} : item.settings,
  //       };
  //       customList.push(obj);
  //     }else if(formType.split(" ")[0].toLowerCase()===item?.field_sub_type?.toLowerCase() && item.field_type === "primary"){
  //       // const str = item.field_id.split("-")[0];
  //       const obj = {
  //         elementid: item.field_id,
  //         name: item.field_name,
  //         type: item.input_type || "InputField",
  //         settings: item.settings == null ? {} : item.settings,
  //       };
  //       primaryList.push(obj);
  //       console.log("PrimaryList",primaryList)
  //     }
  //   });
  //   console.log("PrimaryList",primaryList)
  //   const tempDefaultList=[...defaultList]
  //   board?.map((item) => {
  //     const itemList = tempDefaultList.filter((items) => items?.elementid === item?.elementid);
  //     const lastItemindex=itemList.length-1
  //     const lastIndexList=tempDefaultList.findIndex((items)=>items?.elementid===item?.elementid)
  //     tempDefaultList.splice(lastIndexList,1)
  //     tempDefaultList.push(itemList[lastItemindex])
  //   }
  //   );
  //   console.log("CustomList",customList)

  //   const tempCustomList=Array.from(customList)
  //   board?.map((item) => {
  //     const itemList = tempCustomList.filter((items) => items?.elementid === item?.elementid);
  //     const lastItemindex=itemList.length-1
  //     const lastIndexList=tempCustomList.findIndex((items)=>items?.elementid===item?.elementid)
    
  //     tempCustomList.splice(lastIndexList,1)
  //     tempCustomList.push(itemList[lastItemindex])
    
  //   }
  //   );
  //   const tempPrimaryList=[...primaryList]
  //   console.log("PrimaryList",tempPrimaryList)
  //   board?.map((item) => {
  //     const itemList = tempPrimaryList.filter((items) => items?.elementid === item?.elementid);
  //     const lastItemindex=itemList.length-1
  //     const lastIndexList=tempPrimaryList.findIndex((items)=>items?.elementid===item?.elementid)

  //     tempPrimaryList.splice(lastIndexList,1)
  //     tempPrimaryList.push(itemList[lastItemindex])
  //   }
  //   );


  //   console.log("PrimaryList",tempPrimaryList)
    
  //   setPrimary(tempPrimaryList)
  //   setDefaultElement(tempDefaultList);
  //   setCustomElement(tempCustomList);
  //   console.log("DefaultList",tempCustomList)

  //   // console.log("All field List",[...tempDefaultList,...tempCustomList])
  //   // setAllFields([...tempDefaultList,...tempCustomList])
  //   // setAllFields([])
  //   if(tempCustomList?.length >= formPlan?.CustomFieldLimit){
  //     setElementDisabled(true);
  //   }else{
  //     setElementDisabled(false);
  //   }
   

    

  //   // setAllFields([tempCustomList,tempDefaultList])
  
  // }, [board,TeamData?.GetFieldsByTeamId]);

  // useEffect(() => {
  //   setAllFields([...defaultElement,...CustomElement])
  // }, [CustomElement, defaultElement]);

  console.log("defaultElement", defaultElement, CustomElement,AllFields);
  useEffect(() => {
    console.log("Baord Callled");
    refetch();

    // if(CustomElement.length >= formPlan?.CustomFieldLimit){
    //   setElementDisabled(true);
    // }else{
    //   setElementDisabled(false);
    // }

  }, [board,refetch]);
 

  console.log("PlanDetails<>", formPlan,elementDisabled);

  // useEffect(() => {
  //   // setAllFields([...defaultElement,...CustomElement])  
  //   // TeamData?.GetFieldsByTeamId?.map((item) => {
  //   //   const temp =[]
  //   //   const obj={
  //   //     elementid:item.field_id,
  //   //     name:item.field_name,
  //   //     type:item.input_type,
  //   //     settings:item.settings
  //   //   }
  //   //   // AllFields.push(obj)
  //   //   temp.push(obj)
  //   //   // setAllFields(temp)
  //   // }
  //   // );

  //   // console.log("AllFields",AllFields)

  // }, [TeamFieldsLoading]);

  

   console.log("AllFields",primary)
  

  const filterElements = defaultElement?.filter((item) => {
    
    return (
      item?.settings?.label ||
      item?.name || item?.type)?.toLowerCase()?.includes(value?.toLowerCase());
  });

  const filterCustomElements = CustomElement?.filter((item) => {
    return (
      item?.settings?.label ||
      item?.name ||
      item?.type
    )?.toLowerCase()?.includes(value?.toLowerCase());
  });

  const filterAllElements = AllFields?.filter((item) => {
    return (
    
      item?.settings?.label ||
   
      item?.name ||
      item?.type
    )?.toLowerCase()?.includes(value?.toLowerCase());
  });

  console.log("Forms>>",filterElements,filterCustomElements)
  const handleClick = (e, elementid, ElementName) => {
    e.preventDefault();
    setCustomEleDeleteId({
      elementid: elementid,
      name: ElementName,
      refetchform: refetch,

    });
  };

  let show = true;
  const Element = useCallback(
    ({
      id,
      name,
      type,
      placeholder,
      field_type = "",
      isDisabled = false,
      CustomElement,
      primaryElement,
    }) => {
      const ElementName = placeholder?.label || name || type;

      const str = id?.split("-")[0];
      const Store = ElementsUIGenrate(str);

      return (
        <div
          className={`p-0 ${
            isDisabled && field_type === "default_field" && "disable-default-field-div"
          }`}
        >
          <div
            className={`d-flex p-3 border rounded align-items-center gap-2 fm-ucard ${
              isDisabled && field_type === "default_field" && "disable-default-field"
            }`}
            id={id}
          >
            <div className="me-2">
              <i className="fm-icon iconify" data-icon={Store?.icon} />
            </div>

            <span>{ ElementName }</span>

            {CustomElement && (
              <span className="ms-auto pointer" data-bs-toggle="modal" data-bs-target="#CustomFieldDeleteModal" 
                onClick={(e) => handleClick(e, id, ElementName)}
              >
                <i className="iconify text-dark" data-icon="ph:trash"></i>
              </span>
            )}

            {CustomElement ? (
              <span className="icon-container">
                <i className="small iconify text-primary" data-icon="fa6-solid:bookmark"></i>
              </span>
            ) : primaryElement ? (
              <span className="icon-container ms-auto">
                <img className="small iconify text-primary" src={fublogo} alt="Logo" />
              </span>
            ) : (
              <span className="icon-container ms-auto">
                <i className="small iconify text-primary" data-icon="fa6-solid:cube"></i>
              </span>
            )}
          </div>
        </div>
      );
    },
    []
  );
  console.log("isCollapsed",isCollapsed)
  const handleClickmodal=()=>{
    console.log("isCollapsed",isCollapsed)

    setIsCollapsed(!isCollapsed)
  }

  // if(TeamFieldsLoading) return <div class="d-flex justify-content-center">
  //    <div class="spinner-border" role="status">
  //      <span class="sr-only">Loading...</span>
  //    </div>
  // </div>
console.log("TabElementId",primary)
  return (
    <div className="w-100 pb-5 mb-5">

      <div className="mb-4">
        <div className="w-100 d-flex ">

          <input
            placeholder="Search "
            className="form-control col me-2 input-bn search-bn"
            type="search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{height: "30px !important" }}
          />

          <div className="tooltip-container">
            <button className={`add-bn rounded-circle shadow border ${elementDisabled ? "hover-overlay" : null}`} data-bs-toggle="collapse" data-bs-target="#newF" disabled={elementDisabled} >
              <i className="iconify text-white fs-4" data-icon="mdi:plus"></i>
            </button>
            {elementDisabled && <div className="tooltip-text form-tooltip p-2">You have reached the limit of custom fields</div>}
          </div>

        </div>
      </div>
      
      {/* add-bn rounded-circle shadow border */}

      <nav>
        <div className="nav nav-tabs flex-nowrap fi-tab gap-2" role="tablist">

          {/* <button
            className="nav-link col text-dark"
            id="nav-all-tab"
            data-bs-toggle="tab"
            data-bs-target="#all-field-container"
            type="button"
            role="tab"
            onClick={(e)=>{setTabElementId("all-field-container")}}
          >
            <span className="d-flex align-items-center justify-content-center">
              <span className="d-none d-xl-block">All</span>
            </span>
          </button> */}

          <button
            className="nav-link col text-dark fm-ty"
            id="nav-custom-tab"
            data-bs-toggle="tab"
            data-bs-target="#primary-field"
            type="button"
            role="tab"
            onClick={(e)=>{setTabElementId("custom-field-container")}}

          >
            <span className="d-flex align-items-center justify-content-center">
              {" "}
              <i
                className="iconify me-2"
                data-icon="fa6-solid:cube"
              />
              <span className="d-none d-xl-block">Primary</span>
              
            </span>
          </button>

          <button
            className="nav-link col active text-dark fm-ty"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#default-field-container"
            type="button"
            role="tab"
            onClick={(e)=>{setTabElementId("default-field-container")}}
          >
            <span className="d-flex align-items-center justify-content-center">
              <i className="iconify me-2" data-icon="fa6-solid:cube" />
              <span className="d-none d-xl-block">Default</span>
            </span>
          </button>

          <button
            className="nav-link col text-dark fm-ty"
            id="nav-custom-tab"
            data-bs-toggle="tab"
            data-bs-target="#custom-field-container"
            type="button"
            role="tab"
            onClick={(e)=>{setTabElementId("custom-field-container")}}

          >
            <span className="d-flex align-items-center justify-content-center">
              {" "}
              <i
                className="iconify me-2"
                data-icon="fa6-solid:bookmark"
              />
              <span className="d-none d-xl-block">Custom</span>
              
            </span>
          </button>

          

        </div>
      </nav>
      {/* <div className="tab-content pt-3">
         <AllElementDragableTab TabId={TabElementId} formType={formType} />
      </div> */}

      { TeamFieldsLoading ? ( 
      <div class="d-flex justify-content-center mt-5 pt-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      ) : ( 
      <div className="tooltip-container w-100"> 
      
        <div className="tab-content pt-3">

          <div className="tab-pane fade" id="primary-field" role="tabpanel">
          <Droppable droppableId="PrimaryElements">
              {(provided, index) => (
                <div
                  key={index}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                >
                  {
                  primary?.map((item, index) => {
                    console.log("item All Default>>", item);
                    const isElementInBoard = board?.some(
                      (element) => element?.elementid === item?.elementid
                    );
                    // setElementDisabled(isElementInBoard)/
                    const CustomElement = false
                    const primaryElement=true

                    // const str = item?.elementid?.split("-")[0];
                    console.log("item>><",isElementInBoard)
                    // const uuid = Math.floor(Math.random() * 10000);
                    if (isElementInBoard) {
                      return (
                        <div>
                          <Element
                          key={index}
                          id={item?.elementid}
                          type={item?.type}
                          name={item?.name}
                          placeholder={item?.settings}
                          field_type="default_field"
                          isDisabled={isElementInBoard}
                          // CustomElement={CustomElement}
                          primaryElement={primaryElement}
                          />
                        </div>
                        
                      );
                    } else {
                      return (
                        <DragableElement
                          id={item?.elementid}
                          key={item?.elementid} //if we pass key here then it will give error and drag and drop will not work
                          index={index}
                          element={
                            <Element
                              id={item?.elementid}
                              type={item?.type}
                              name={item?.name}
                              placeholder={item?.settings}
                              field_type="default_field"
                              isDisabled={isElementInBoard}
                              // CustomElement={CustomElement}
                              primaryElement={primaryElement}

                            />
                          }
                          settings={item?.settings}
                          show={show}
                          isDragDisabled={isElementInBoard}
                        />
                      );
                    }
                  })}
                  {filterElements?.length === 0 && (
                        <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                          <span className="iconify display-1" data-icon="fluent:box-search-20-regular"></span>
                          No Default Fields Found
                          <div className="btn-primary btn pointer" data-bs-toggle="collapse" data-bs-target="#newF" >Create New Field</div>
                        </div>
                    
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            
          </div>

          <div className="tab-pane fade show active" id="default-field-container" role="tabpanel">
            
            <Droppable droppableId="DefaultElements">
              {(provided, index) => (
                <div
                  key={index}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                >
                  {
                  filterElements?.map((item, index) => {
                    console.log("item All Default>>", item);
                    const isElementInBoard = board?.some(
                      (element) => element.elementid === item.elementid
                    );
                    // setElementDisabled(isElementInBoard)/
                    const CustomElement = false

                    // const str = item?.elementid?.split("-")[0];
                    console.log("item>><",isElementInBoard)
                    // const uuid = Math.floor(Math.random() * 10000);
                    if (isElementInBoard) {
                      return (
                        <div>
                          <Element
                          key={index}
                          id={item?.elementid}
                          type={item?.type}
                          name={item?.name}
                          placeholder={item?.settings}
                          field_type="default_field"
                          isDisabled={isElementInBoard}
                          CustomElement={CustomElement}
                          />
                        </div>
                        
                      );
                    } else {
                      return (
                        <DragableElement
                          id={item?.elementid}
                          key={item.elementid} //if we pass key here then it will give error and drag and drop will not work
                          index={index}
                          element={
                            <Element
                              id={item?.elementid}
                              type={item?.type}
                              name={item?.name}
                              placeholder={item?.settings}
                              field_type="default_field"
                              isDisabled={isElementInBoard}
                              CustomElement={CustomElement}

                            />
                          }
                          settings={item?.settings}
                          show={show}
                          isDragDisabled={isElementInBoard}
                        />
                      );
                    }
                  })}
                  {filterElements?.length === 0 && (
                        <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                          <span className="iconify display-1" data-icon="fluent:box-search-20-regular"></span>
                          No Default Fields Found
                          <div className="btn-primary btn pointer" data-bs-toggle="collapse" data-bs-target="#newF" >Create New Field</div>
                        </div>
                    
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="tab-pane fade" id="custom-field-container"role="tabpanel">
            <Droppable droppableId="CustomElements" isDropDisabled={true}>
              {(provided, index) => (
                <div
                  key={index}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
                >
                  {filterCustomElements?.map((item, index) => {
                    console.log("item All Custom>>", item)
                    const CustomElement=true
                    const isElementInBoard = board?.some(
                      (element) => element.elementid === item.elementid
                    );
                    // const str=item?.elementid?.split('-')[0]
                    console.log(
                      "ElementINBoard",
                      isElementInBoard,
                      board,
                      item.element
                    );
                    if (isElementInBoard) {
                      // return null
                      return (
                        <Element
                          key={index}
                          id={item?.elementid}
                          type={item.type}
                          name={item?.name}
                          field_type="default_field"
                          placeholder={item?.settings}
                          isDisabled={isElementInBoard}
                          CustomElement={CustomElement}
                        />
                      );
                    } else {
                      return (
                        <DragableElement
                          id={item?.elementid}
                          key={item?.elementid} //if we pass key here then it will give error and drag and drop will not work
                          index={index}
                          element={
                            <Element
                              id={item?.elementid}
                              type={item.type}
                              name={item?.name}
                              placeholder={item?.settings}
                                                            field_type="default_field"
                              isDisabled={isElementInBoard}
                              CustomElement={CustomElement}

                            />
                          }
                          settings={item?.settings}
                          show={show}
                          // isDragDisabled={isElementInBoard}
                        />
                      );
                    }
                  })}
                  {filterCustomElements?.length === 0 && (
                    <div className="d-flex flex-column align-items-center gap-3 text-center mt-3">
                      <span className="iconify display-1" data-icon="fluent:box-search-20-regular"></span>
                      No Custom Fields Found For The Match
                      <div className="btn-primary btn pointer" data-bs-toggle="collapse" data-bs-target="#newF" >Create New Field</div>
                    </div>
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

        </div>
      </div>
      )} 

    </div>

  );
};

export default FormBuilderRightSideBar;
//No default feilds Available