import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

const UpdateWorkflowDetails = gql`
  mutation UpdateWorkflowDetails($workflowId: String!, $workflowName: String, $workflowDescription: String) {
    UpdateWorkflowDetails(workflow_id: $workflowId, workflow_name: $workflowName, workflow_description: $workflowDescription) {
      statusCode
      message
      data
    }
  }
`;

const WorkflowTitle = ({workflowDetails}) => {
    
  const { workflow_id } = useParams();
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [editWorkflowTitle, setEditWorkflowTitle] = useState(true);
  const [editWorkflowDescription, setEditWorkflowDescription] = useState(true);
  const inputRefTitle = useRef(null);
  const inputRefDescription = useRef(null);
  const [updateWorkflowDetails] = useMutation(UpdateWorkflowDetails);

  useEffect(() => {
    setWorkflowName(workflowDetails?.workflow_name);
    setWorkflowDescription(workflowDetails?.workflow_description);
  }, [workflowDetails?.workflow_name, workflowDetails?.workflow_description]);

  const editWorkflowName = () => {
    inputRefTitle?.current?.focus();
    if (!editWorkflowTitle) {
      updateWorkflowDetails({
        variables: {
          workflowId: workflow_id,
          workflowName: workflowName,
        },
      });
    }
    setEditWorkflowTitle(!editWorkflowTitle);
  };

  const editWorkflowDesc = () => {
    inputRefDescription?.current?.focus();
    if (!editWorkflowDescription) {
      updateWorkflowDetails({
        variables: {
          workflowId: workflow_id,
          workflowDescription: workflowDescription,
        },
      });
    }
    setEditWorkflowDescription(!editWorkflowDescription);
  };

  const workflowNameHandler = (e) => {
    e.preventDefault();
    setWorkflowName(e.target.value);
  };

  const workflowDescriptionHandler = (e) => {
    e.preventDefault();
    setWorkflowDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="py-4">
      <form action="" className="d-flex align-items-center" onSubmit={handleSubmit}>
        {editWorkflowTitle ? (
          <h4
            className="m-1"
            style={{
              maxWidth: "240px",
              maxHeight: "500px",
              overflowWrap: "break-word",
            }}
          >
            {workflowName}
          </h4>
        ) : (
          <input
            ref={inputRefTitle}
            id="workflow_title"
            placeholder="Workspace title "
            className={editWorkflowTitle ? "workflow-title text-dark" : "workflow-title border-bottom"}
            type="text"
            name="name"
            value={workflowName}
            onChange={workflowNameHandler}
            disabled={editWorkflowTitle}
            rows={1}
            style={{
              width: `${workflowName?.length + 2}ch`,
              maxWidth: "240px",
              maxHeight: "500px",
              resize: "vertical",
              overflowWrap: "break-word",
            }}
          />
        )}
        <span onClick={editWorkflowName}>
          <i
            className={editWorkflowTitle ? "fs-4 ms-2 d-flex bx bxs-edit pointer" : "fs-4 ms-2 d-flex bx bx-check pointer"}
          />
        </span>
      </form>

      <form action="" className="d-flex align-items-center mt-2" onSubmit={handleSubmit}>
        {editWorkflowDescription ? (
          <p className="text-secondary mt-2 m-1"
          style={{
            maxWidth: "240px",
            maxHeight: "500px",
            overflowWrap: "break-word",
          }}
          >{workflowDescription}</p>
        ) : (
          <input
            ref={inputRefDescription}
            id="workflow_description"
            placeholder="Workspace description"
            className={editWorkflowDescription ? "workflow-description text-dark" : "workflow-description border-bottom"}
            type="text"
            name="description"
            value={workflowDescription}
            onChange={workflowDescriptionHandler}
            disabled={editWorkflowDescription}
            rows={1}
            style={{
              width: `${workflowDescription?.length + 2}ch`,
              maxWidth: "240px",
              maxHeight: "500px",
              resize: "vertical",
              overflowWrap: "break-word",
            }}
          />
        )}
        <span onClick={editWorkflowDesc}>
          <i
            className={editWorkflowDescription ? "fs-4 ms-2 d-flex bx bxs-edit pointer" : "fs-4 ms-2 d-flex bx bx-check pointer"}
          />
        </span>
      </form>
    </div>
  );
};

export default WorkflowTitle;
