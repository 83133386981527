export const WhisselPendingForm=[
    {
        "elementid": "InputField-team-id",
        "type": "InputField",
        "name": "Team ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Team ID",
            "predefine": "whisselrealtygroup",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Do Not Update this field"
        }
    },
    {
        "elementid": "InputField-client-last-name",
        "type": "InputField",
        "name": "Client Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Client Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-client-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3012",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "8794",
                    "value": "Seller",
                    "isCheck": false
                }
            ],
            "label": "Client Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Client Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6665",
                    "value": "Update_Existing_Transaction",
                    "isCheck": false
                },
                {
                    "id": "1022",
                    "value": "Create_New_Transaction",
                    "isCheck": false
                }
            ],
            "label": "AGENTS: DO NOT CHANGE THIS FIELD (it auto-populates based on whether there is a lead for this client in Sisu already) - Creating or Updating Transaction?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "This is referring to the Sisu Transaction, which is created anytime you submit an Appointment or Agreement Signed Form. If you previously submitted an Appointment or Agreement form for this client, this field will default to Update_Existing_Transaction. Don't change it.If you are receiving an error message, change this field to Update_Existing_Transaction."
        },
        "name": "AGENTS: DO NOT CHANGE THIS FIELD (it auto-populates based on whether there is a lead for this client in Sisu already) - Creating or Updating Transaction?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-the-2nd-leg-of-a-homelight-cash-cl",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3229",
                    "value": "First Choice",
                    "isCheck": false
                }
            ],
            "label": "The 2nd leg of a Homelight Cash Close MUST be a new transaction. Please update to Create_New_Transaction< p>",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "The 2nd leg of a Homelight Cash Close MUST be a new transaction. Please update to Create_New_Transaction< p>",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-lead-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6433",
                    "value": "Agent Generated Lead",
                    "isCheck": false
                },
                {
                    "id": "7709",
                    "value": "Team Generated Lead",
                    "isCheck": false
                }
            ],
            "label": "Lead Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-a-seller-transaction-requires-an-a",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9999",
                    "value": "Error",
                    "isCheck": false
                }
            ],
            "label": "A Seller Transaction REQUIRES an Agreement Signed Form to be completed before the Pending Form. Please close this window and complete that form first.< p>",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "A Seller Transaction REQUIRES an Agreement Signed Form to be completed before the Pending Form. Please close this window and complete that form first.< p>",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "CheckBox-a-buyer-transaction-has-already-be",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "1565",
                    "value": "Error",
                    "isCheck": false
                }
            ],
            "label": "A Buyer Transaction has already been created for this lead. Please update the Creating or Updating Transaction field to Update_Existing_Transaction< p>",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "A Buyer Transaction has already been created for this lead. Please update the Creating or Updating Transaction field to Update_Existing_Transaction< p>",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-fub-buyer-deal-id",
        "type": "InputField",
        "name": "FUB Buyer Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Buyer Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-a-seller-transaction-has-already-b",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7340",
                    "value": "Error",
                    "isCheck": false
                }
            ],
            "label": "A Seller Transaction has already been created for this lead. Please update the Creating or Updating Transaction field to Update_Existing_Transaction< p>",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "A Seller Transaction has already been created for this lead. Please update the Creating or Updating Transaction field to Update_Existing_Transaction< p>",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-fub-seller-deal-id",
        "type": "InputField",
        "name": "FUB Seller Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Seller Deal ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    // {
    //     "elementid": "NumberField-error-(buyer)---contact-shannon-or",
    //     "type": "NumberField",
    //     "name": "ERROR (buyer) - Contact Shannon or Chris for immediate support. Source Link Needs Updated< p>",
    //     "field_sub_type": "opportunity",
    //     "settings": {
    //         "label": "ERROR (buyer) - Contact Shannon or Chris for immediate support. Source Link Needs Updated< p>",
    //         "predefine": "",
    //         "size": "large",
    //         "required": true,
    //         "hidden": false,
    //         "minValue": "0",
    //         "maxValue": "",
    //         "instructions": ""
    //     }
    // },
    // {
    //     "elementid": "NumberField-error-(seller)---contact-shannon-o",
    //     "type": "NumberField",
    //     "name": "ERROR (seller) - Contact Shannon or Chris for immediate support. Source Link Needs Updated< p>",
    //     "field_sub_type": "opportunity",
    //     "settings": {
    //         "label": "ERROR (seller) - Contact Shannon or Chris for immediate support. Source Link Needs Updated< p>",
    //         "predefine": "",
    //         "size": "large",
    //         "required": true,
    //         "hidden": false,
    //         "minValue": "0",
    //         "maxValue": "",
    //         "instructions": ""
    //     }
    // },
    {
        "elementid": "DropDown-transaction-stage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7648",
                    "value": "Pending",
                    "isCheck": false
                }
            ],
            "label": "Transaction Stage",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Stage",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-transaction-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9763",
                    "value": "Regular Transaction",
                    "isCheck": false
                },
                {
                    "id": "6240",
                    "value": "New Construction",
                    "isCheck": false
                },
                {
                    "id": "4692",
                    "value": "Referral Fee Only",
                    "isCheck": false
                },
                {
                    "id": "6523",
                    "value": "Homelight Cash Close Program - 1st Leg",
                    "isCheck": false
                },
                {
                    "id": "8580",
                    "value": "Homelight Cash Close Program - 2nd Leg",
                    "isCheck": false
                },
                {
                    "id": "3907",
                    "value": "Homelight Trade-In Program - 1st Leg",
                    "isCheck": false
                },
                {
                    "id": "7339",
                    "value": "Homelight Trade-In Program - 2nd Leg",
                    "isCheck": false
                },
                {
                    "id": "4324",
                    "value": "Agents Capital",
                    "isCheck": false
                },
                {
                    "id": "5076",
                    "value": "REO or Auction",
                    "isCheck": false
                },
                {
                    "id": "3153",
                    "value": "Short Sale",
                    "isCheck": false
                },
                {
                    "id": "2915",
                    "value": "Commercial Transaction",
                    "isCheck": false
                },
                {
                    "id": "8685",
                    "value": "Rental Transaction",
                    "isCheck": false
                },
                {
                    "id": "1125",
                    "value": "Homelight Simple Sale",
                    "isCheck": false
                }
            ],
            "label": "Transaction Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Transaction Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is-there-a-secondary-client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7885",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8048",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary-client",
        "type": "InputField",
        "name": "Secondary Client",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary-client-email",
        "type": "Email",
        "name": "Secondary Client Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-secondary-client-phone",
        "type": "InputField",
        "name": "Secondary Client Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Client Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-signed-vip-agreement",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0407",
                    "value": "VIP Signed",
                    "isCheck": false
                },
                {
                    "id": "3806",
                    "value": "No VIP",
                    "isCheck": false
                }
            ],
            "label": "Signed VIP Agreement",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Signed VIP Agreement",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-did-you-use-the-listing-manager-system?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5875",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9007",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you use the listing manager system?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Did you use the listing manager system?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-street-address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-address-line-2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-zip-code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-country",
        "type": "InputField",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction-amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-under-contract-date",
        "type": "Date",
        "name": "Under Contract Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Under Contract Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-forecasted-close-date",
        "type": "Date",
        "name": "Forecasted Close Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Forecasted Close Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is-the-buyer-doing-a-home-inspection?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9234",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "4705",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "8923",
                    "value": "No - Seller to provide home inspection report",
                    "isCheck": false
                }
            ],
            "label": "Is the buyer doing a home inspection?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is the buyer doing a home inspection?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is-the-buyer-doing-a-termite-inspection?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4177",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9933",
                    "value": "No",
                    "isCheck": false
                },
                {
                    "id": "5482",
                    "value": "No - Seller to provide termite inspection report",
                    "isCheck": false
                }
            ],
            "label": "Is the buyer doing a termite inspection?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is the buyer doing a termite inspection?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite-side-client-name",
        "type": "InputField",
        "name": "Opposite Side Client Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opposite Side Client Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is-there-an-opposite-side-secondary-client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7905",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8347",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there an opposite side secondary client?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there an opposite side secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opposite-side-secondary-client-name",
        "type": "InputField",
        "name": "Opposite Side Secondary Client Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opposite Side Secondary Client Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-agent-company",
        "type": "InputField",
        "name": "Cooperating Agent Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-agent-name",
        "type": "InputField",
        "name": "Cooperating Agent Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-agent-phone",
        "type": "InputField",
        "name": "Cooperating Agent Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "Email-cooperating-agent-email",
        "type": "Email",
        "name": "Cooperating Agent Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-tc-name",
        "type": "InputField",
        "name": "Cooperating TC Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-cooperating-tc-email",
        "type": "Email",
        "name": "Cooperating TC Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-tc-phone",
        "type": "InputField",
        "name": "Cooperating TC Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-escrow-company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8759",
                    "value": "Homelight Escrow - Michaela Pozner",
                    "isCheck": false
                },
                {
                    "id": "3895",
                    "value": "Homelight Escrow - Cash Close Trade-In Transactions",
                    "isCheck": false
                },
                {
                    "id": "2172",
                    "value": "Other",
                    "isCheck": false
                },
                {
                    "id": "7992",
                    "value": "Pinnacle Escrow - Team Liz",
                    "isCheck": false
                },
                {
                    "id": "9308",
                    "value": "Homelight Escrow - Mary Timmen",
                    "isCheck": false
                }
            ],
            "label": "Escrow Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Escrow Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow-company-name",
        "type": "InputField",
        "name": "Escrow Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Company Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow-officer-name",
        "type": "InputField",
        "name": "Escrow Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-escrow-officer-email-(the-contract-will-automatically-be-sen",
        "type": "Email",
        "name": "Escrow Officer Email (the contract will automatically be sent to this email when you upload it on page 2)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Email (the contract will automatically be sent to this email when you upload it on page 2)",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow-officer-phone",
        "type": "InputField",
        "name": "Escrow Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-financing-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6148",
                    "value": "Conventional",
                    "isCheck": false
                },
                {
                    "id": "0356",
                    "value": "VA",
                    "isCheck": false
                },
                {
                    "id": "7573",
                    "value": "FHA",
                    "isCheck": false
                },
                {
                    "id": "4382",
                    "value": "Cash",
                    "isCheck": false
                },
                {
                    "id": "9639",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Financing Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Financing Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-loan-officer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5957",
                    "value": "Jason Hall - Team Home Loans",
                    "isCheck": false
                },
                {
                    "id": "0708",
                    "value": "AJ Schackman - Mavin Mortgage",
                    "isCheck": false
                },
                {
                    "id": "1672",
                    "value": "Other",
                    "isCheck": false
                },
                {
                    "id": "2408",
                    "value": "Cross Country Mortgage - Team Evans",
                    "isCheck": false
                }
            ],
            "label": "Loan Officer",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Loan Officer",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-mortgage-company",
        "type": "InputField",
        "name": "Mortgage Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Mortgage Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan-officer-name",
        "type": "InputField",
        "name": "Loan Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-loan-officer-email",
        "type": "Email",
        "name": "Loan Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan-officer-phone",
        "type": "InputField",
        "name": "Loan Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-title-company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1589",
                    "value": "Fidelity National Title Company - Tom Chapman & Lynne Lapinski",
                    "isCheck": false
                },
                {
                    "id": "5753",
                    "value": "First American - Greg Ives",
                    "isCheck": false
                },
                {
                    "id": "2852",
                    "value": "Lawyer's Title - Dee Dee Burland (Homelight Leads)",
                    "isCheck": false
                },
                {
                    "id": "2757",
                    "value": "Other",
                    "isCheck": false
                }
            ],
            "label": "Title Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-title-company-name",
        "type": "InputField",
        "name": "Title Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Company Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title-rep-name",
        "type": "InputField",
        "name": "Title Rep Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-title-rep-email",
        "type": "Email",
        "name": "Title Rep Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is-there-an-hoa?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7934",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "5034",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is there an HOA?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there an HOA?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa-name",
        "type": "InputField",
        "name": "HOA Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa-management-company",
        "type": "InputField",
        "name": "HOA Management Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa-management-phone",
        "type": "InputField",
        "name": "HOA Management Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Phone",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-solar",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2157",
                    "value": "None",
                    "isCheck": false
                },
                {
                    "id": "6181",
                    "value": "Solar - Paid in Full",
                    "isCheck": false
                },
                {
                    "id": "1925",
                    "value": "Solar - Financed",
                    "isCheck": false
                },
                {
                    "id": "7343",
                    "value": "Solar - PPA",
                    "isCheck": false
                },
                {
                    "id": "8648",
                    "value": "Solar - Leased",
                    "isCheck": false
                },
                {
                    "id": "3111",
                    "value": "Solar - Ownership Unknown",
                    "isCheck": false
                }
            ],
            "label": "Solar",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Solar",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-buyer-agent-commission-percent",
        "type": "NumberField",
        "name": "Buyer Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Buyer Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-listing-agent-commission-percent",
        "type": "NumberField",
        "name": "Listing Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Listing Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-are-you-charging-the-admin-fee,-and-if-so,-who-is-responsibl",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8861",
                    "value": "No - N A",
                    "isCheck": false
                },
                {
                    "id": "8689",
                    "value": "Yes - Seller Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "0198",
                    "value": "Yes - Buyer Paying Fee",
                    "isCheck": false
                },
                {
                    "id": "1637",
                    "value": "Yes - Buyer-to-be-credited-by-Team-Home-Loans",
                    "isCheck": false
                }
            ],
            "label": "Are you charging the admin fee, and if so, who is responsible for paying it?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "1) The admin fee is assumed to be $400 unless specified otherwise.2) To charge the admin fee, you must have the client sign CAR Form CA (Commission Agreement)3) Per VA guidelines, the buyer cannot pay the admin fee if they are getting a VA loan."
        },
        "name": "Are you charging the admin fee, and if so, who is responsible for paying it?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-referral-fee",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4628",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "7956",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Referral Fee",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Referral Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral-fee:-company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Company",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-referral-fee:-agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Agent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-referral-fee:-percent",
        "type": "NumberField",
        "name": "Referral Fee: Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Percent",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-are-you-co-representing-this-client-with-another-agent?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4577",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6426",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Are you co-representing this client with another agent?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you co-representing this client with another agent?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-2nd-whissel-agent-name",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7240",
                    "value": "Abdullah Mohammed",
                    "isCheck": false
                },
                {
                    "id": "6349",
                    "value": "Adam House",
                    "isCheck": false
                },
                {
                    "id": "0740",
                    "value": "Adrian Quijano",
                    "isCheck": false
                },
                {
                    "id": "1522",
                    "value": "Alex Conti",
                    "isCheck": false
                },
                {
                    "id": "1626",
                    "value": "Alyssa Somsamouth",
                    "isCheck": false
                },
                {
                    "id": "2090",
                    "value": "Amanda Witherspoon",
                    "isCheck": false
                },
                {
                    "id": "6438",
                    "value": "Ana Jamo",
                    "isCheck": false
                },
                {
                    "id": "1260",
                    "value": "Andrea Fasulo",
                    "isCheck": false
                },
                {
                    "id": "6253",
                    "value": "Andrew Canavan",
                    "isCheck": false
                },
                {
                    "id": "0603",
                    "value": "Anninda Gibbs",
                    "isCheck": false
                },
                {
                    "id": "0550",
                    "value": "Antonella Marino",
                    "isCheck": false
                },
                {
                    "id": "3267",
                    "value": "Arquimedes Madrigal",
                    "isCheck": false
                },
                {
                    "id": "0615",
                    "value": "Austin Michelle",
                    "isCheck": false
                },
                {
                    "id": "3396",
                    "value": "Billie Guinto",
                    "isCheck": false
                },
                {
                    "id": "9542",
                    "value": "Bob David",
                    "isCheck": false
                },
                {
                    "id": "2393",
                    "value": "Brenda Alvarez",
                    "isCheck": false
                },
                {
                    "id": "3453",
                    "value": "Brian Meinert",
                    "isCheck": false
                },
                {
                    "id": "2547",
                    "value": "Brianda Arreola",
                    "isCheck": false
                },
                {
                    "id": "6494",
                    "value": "Bryan Mancuso",
                    "isCheck": false
                },
                {
                    "id": "6324",
                    "value": "Carlos Rey",
                    "isCheck": false
                },
                {
                    "id": "4882",
                    "value": "Carter Orchulli",
                    "isCheck": false
                },
                {
                    "id": "0672",
                    "value": "Celena Barone",
                    "isCheck": false
                },
                {
                    "id": "6406",
                    "value": "Christa Curtin",
                    "isCheck": false
                },
                {
                    "id": "5361",
                    "value": "Christopher Rossi",
                    "isCheck": false
                },
                {
                    "id": "0965",
                    "value": "Clayton Connolly",
                    "isCheck": false
                },
                {
                    "id": "8728",
                    "value": "Cody Stamm",
                    "isCheck": false
                },
                {
                    "id": "3679",
                    "value": "Corinne Mauro",
                    "isCheck": false
                },
                {
                    "id": "8695",
                    "value": "Courtney Renteria",
                    "isCheck": false
                },
                {
                    "id": "4844",
                    "value": "Courtney Truett",
                    "isCheck": false
                },
                {
                    "id": "9273",
                    "value": "Cynthia Mayorga",
                    "isCheck": false
                },
                {
                    "id": "4610",
                    "value": "Dan Benjamin",
                    "isCheck": false
                },
                {
                    "id": "6328",
                    "value": "Daniela Taylor",
                    "isCheck": false
                },
                {
                    "id": "3884",
                    "value": "Dany Lopez",
                    "isCheck": false
                },
                {
                    "id": "3452",
                    "value": "Dawn Cullen",
                    "isCheck": false
                },
                {
                    "id": "1382",
                    "value": "Domenica Picerni",
                    "isCheck": false
                },
                {
                    "id": "2872",
                    "value": "Elizabeth Diaz",
                    "isCheck": false
                },
                {
                    "id": "3048",
                    "value": "Emma Dearing",
                    "isCheck": false
                },
                {
                    "id": "7669",
                    "value": "Eric Gulal",
                    "isCheck": false
                },
                {
                    "id": "6300",
                    "value": "Evan Wagley",
                    "isCheck": false
                },
                {
                    "id": "9375",
                    "value": "Eve Flaglore",
                    "isCheck": false
                },
                {
                    "id": "5000",
                    "value": "Ewa Baska",
                    "isCheck": false
                },
                {
                    "id": "2577",
                    "value": "Fernando Garay",
                    "isCheck": false
                },
                {
                    "id": "9811",
                    "value": "Francis Ruiz",
                    "isCheck": false
                },
                {
                    "id": "8721",
                    "value": "Frank Perez",
                    "isCheck": false
                },
                {
                    "id": "0304",
                    "value": "Gabriel Montanez",
                    "isCheck": false
                },
                {
                    "id": "9449",
                    "value": "Gabriel Talamantes",
                    "isCheck": false
                },
                {
                    "id": "6665",
                    "value": "Gabriela Gonzalez",
                    "isCheck": false
                },
                {
                    "id": "1338",
                    "value": "Gautier Argemi",
                    "isCheck": false
                },
                {
                    "id": "0275",
                    "value": "Hannah Ohman",
                    "isCheck": false
                },
                {
                    "id": "2143",
                    "value": "Harrison Haynes",
                    "isCheck": false
                },
                {
                    "id": "0568",
                    "value": "Hazael Garay",
                    "isCheck": false
                },
                {
                    "id": "7038",
                    "value": "Ian Worthington",
                    "isCheck": false
                },
                {
                    "id": "1266",
                    "value": "India Bonin",
                    "isCheck": false
                },
                {
                    "id": "7684",
                    "value": "Jacob Carmona",
                    "isCheck": false
                },
                {
                    "id": "5876",
                    "value": "J.C. Agajanian",
                    "isCheck": false
                },
                {
                    "id": "2475",
                    "value": "Janet Carmody",
                    "isCheck": false
                },
                {
                    "id": "2781",
                    "value": "Jarrod Norris",
                    "isCheck": false
                },
                {
                    "id": "5281",
                    "value": "Jeremy McHone",
                    "isCheck": false
                },
                {
                    "id": "2909",
                    "value": "Jennifer Crosby",
                    "isCheck": false
                },
                {
                    "id": "4850",
                    "value": "Jen DiJulio",
                    "isCheck": false
                },
                {
                    "id": "5235",
                    "value": "Jenny Adib",
                    "isCheck": false
                },
                {
                    "id": "5356",
                    "value": "Jesse Hodges",
                    "isCheck": false
                },
                {
                    "id": "7845",
                    "value": "Jesus Soberanis",
                    "isCheck": false
                },
                {
                    "id": "4442",
                    "value": "Joe OMeara",
                    "isCheck": false
                },
                {
                    "id": "9039",
                    "value": "Joel Schneider",
                    "isCheck": false
                },
                {
                    "id": "6086",
                    "value": "Jordan Gibson",
                    "isCheck": false
                },
                {
                    "id": "3349",
                    "value": "Jordan Graham",
                    "isCheck": false
                },
                {
                    "id": "9345",
                    "value": "Julia Marchisio",
                    "isCheck": false
                },
                {
                    "id": "4931",
                    "value": "Justin Verdugo",
                    "isCheck": false
                },
                {
                    "id": "2343",
                    "value": "Karlee Van Dyke",
                    "isCheck": false
                },
                {
                    "id": "0152",
                    "value": "Katie Lussier",
                    "isCheck": false
                },
                {
                    "id": "5437",
                    "value": "Katy Carlson",
                    "isCheck": false
                },
                {
                    "id": "7435",
                    "value": "Katya Roukoz",
                    "isCheck": false
                },
                {
                    "id": "2584",
                    "value": "Kelli Metcalf",
                    "isCheck": false
                },
                {
                    "id": "9540",
                    "value": "Kennedy Hannom",
                    "isCheck": false
                },
                {
                    "id": "9524",
                    "value": "Kevin Piechota",
                    "isCheck": false
                },
                {
                    "id": "2931",
                    "value": "Kiki Parsons",
                    "isCheck": false
                },
                {
                    "id": "6841",
                    "value": "Kimberly La Bore",
                    "isCheck": false
                },
                {
                    "id": "0441",
                    "value": "Kimo Quance",
                    "isCheck": false
                },
                {
                    "id": "0480",
                    "value": "Koby Blankson",
                    "isCheck": false
                },
                {
                    "id": "9767",
                    "value": "Kristina Roberts",
                    "isCheck": false
                },
                {
                    "id": "7522",
                    "value": "Kwantida Payakka",
                    "isCheck": false
                },
                {
                    "id": "2493",
                    "value": "Kyle Whissel",
                    "isCheck": false
                },
                {
                    "id": "4107",
                    "value": "Leonard Franklin",
                    "isCheck": false
                },
                {
                    "id": "1415",
                    "value": "Lexie Lyons",
                    "isCheck": false
                },
                {
                    "id": "8713",
                    "value": "Lisa Cardoza",
                    "isCheck": false
                },
                {
                    "id": "1008",
                    "value": "Lisa Florendo",
                    "isCheck": false
                },
                {
                    "id": "1361",
                    "value": "Lisa Santos",
                    "isCheck": false
                },
                {
                    "id": "3715",
                    "value": "Louis Goletto",
                    "isCheck": false
                },
                {
                    "id": "3420",
                    "value": "Macavin Weems",
                    "isCheck": false
                },
                {
                    "id": "5429",
                    "value": "Mai Truong",
                    "isCheck": false
                },
                {
                    "id": "9838",
                    "value": "Marcus Bryant",
                    "isCheck": false
                },
                {
                    "id": "2505",
                    "value": "Marena Aon",
                    "isCheck": false
                },
                {
                    "id": "1492",
                    "value": "Matt Huneycutt",
                    "isCheck": false
                },
                {
                    "id": "0673",
                    "value": "Matthew Minshall",
                    "isCheck": false
                },
                {
                    "id": "4456",
                    "value": "Melissa Anderkin",
                    "isCheck": false
                },
                {
                    "id": "1831",
                    "value": "Michael Grandy",
                    "isCheck": false
                },
                {
                    "id": "0265",
                    "value": "Michael Szymczak",
                    "isCheck": false
                },
                {
                    "id": "5319",
                    "value": "Michelle House",
                    "isCheck": false
                },
                {
                    "id": "2057",
                    "value": "Mike Boyd",
                    "isCheck": false
                },
                {
                    "id": "1626",
                    "value": "Mike Proctor",
                    "isCheck": false
                },
                {
                    "id": "1009",
                    "value": "Myrna Yang",
                    "isCheck": false
                },
                {
                    "id": "4208",
                    "value": "Nakia Simms",
                    "isCheck": false
                },
                {
                    "id": "4083",
                    "value": "Nico Ayala",
                    "isCheck": false
                },
                {
                    "id": "1096",
                    "value": "Nicholas Coker",
                    "isCheck": false
                },
                {
                    "id": "8950",
                    "value": "Paul Rucker",
                    "isCheck": false
                },
                {
                    "id": "5198",
                    "value": "Perry Asghari",
                    "isCheck": false
                },
                {
                    "id": "4049",
                    "value": "Pratham Khera",
                    "isCheck": false
                },
                {
                    "id": "3824",
                    "value": "Quan Nguyen",
                    "isCheck": false
                },
                {
                    "id": "3191",
                    "value": "Rachel Ohara",
                    "isCheck": false
                },
                {
                    "id": "5927",
                    "value": "Ric Contreras",
                    "isCheck": false
                },
                {
                    "id": "9959",
                    "value": "Richelle Flores",
                    "isCheck": false
                },
                {
                    "id": "3684",
                    "value": "Sarah Pisciotta",
                    "isCheck": false
                },
                {
                    "id": "9839",
                    "value": "Sean Jamali",
                    "isCheck": false
                },
                {
                    "id": "5537",
                    "value": "Sean Jones",
                    "isCheck": false
                },
                {
                    "id": "3055",
                    "value": "Spencer Siegel",
                    "isCheck": false
                },
                {
                    "id": "8430",
                    "value": "Stephanie Wohlfiel",
                    "isCheck": false
                },
                {
                    "id": "4503",
                    "value": "Steve Schuster",
                    "isCheck": false
                },
                {
                    "id": "2643",
                    "value": "Steven Wener",
                    "isCheck": false
                },
                {
                    "id": "4508",
                    "value": "Tad Murroughs",
                    "isCheck": false
                },
                {
                    "id": "6185",
                    "value": "Tareq Vasquez",
                    "isCheck": false
                },
                {
                    "id": "2599",
                    "value": "Taylor Sandoval",
                    "isCheck": false
                },
                {
                    "id": "7468",
                    "value": "Toi Carden",
                    "isCheck": false
                },
                {
                    "id": "4832",
                    "value": "Troy Nunez",
                    "isCheck": false
                },
                {
                    "id": "9477",
                    "value": "Tom Lux",
                    "isCheck": false
                },
                {
                    "id": "5857",
                    "value": "Tommy House",
                    "isCheck": false
                },
                {
                    "id": "9157",
                    "value": "Twanda Turner",
                    "isCheck": false
                },
                {
                    "id": "0241",
                    "value": "Valerie Narmontas",
                    "isCheck": false
                },
                {
                    "id": "5187",
                    "value": "Vanessa Teo",
                    "isCheck": false
                },
                {
                    "id": "2371",
                    "value": "Vaughn Douglass",
                    "isCheck": false
                },
                {
                    "id": "5235",
                    "value": "Wes Parker",
                    "isCheck": false
                }
            ],
            "label": "2nd Whissel Agent Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "2nd Whissel Agent Name",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-secondary-follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Secondary Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-gross-commission-percent",
        "type": "NumberField",
        "name": "Gross Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Gross Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-gci-amount",
        "type": "NumberField",
        "name": "GCI Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "GCI Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent-commission-amount",
        "type": "NumberField",
        "name": "Agent Commission Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agent Commission Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-appt-entry-id",
        "type": "InputField",
        "name": "Appt Entry ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appt Entry ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-admin-fee",
        "type": "NumberField",
        "name": "Admin Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Admin Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-tc-fee",
        "type": "NumberField",
        "name": "TC Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "TC Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-email-(for-automation)",
        "type": "Email",
        "name": "Email (For Automation)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Email (For Automation)",
            "predefine": "whisselrealtygroupnotes.51ana@zapiermail.com",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-email-(for-automation)-indie",
        "type": "Email",
        "name": "Email (For Automation) Indie",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Email (For Automation) Indie",
            "predefine": "whisselrealtygroupnotes.habv6u@zapiermail.com",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant-phone",
        "type": "InputField",
        "name": "Tenant Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not add letters or extensions to this field"
        }
    },
    {
        "elementid": "DropDown-who-is-paying-for-staging?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2790",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "1450",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "4980",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Who is paying for staging?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Who is paying for staging?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-staging-needed",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4960",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3782",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8692",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Staging Needed",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Staging Needed",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-target-photo-date",
        "type": "Date",
        "name": "Target Photo Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Target Photo Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-sign-requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0954",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3590",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8877",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Sign Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Sign Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-settlement-date",
        "type": "Date",
        "name": "Settlement Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Settlement Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-agreement-expiration-date",
        "type": "Date",
        "name": "Agreement Expiration Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Expiration Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-drone-footage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3652",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3856",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6070",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Drone Footage",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Drone Footage",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-tenant-name",
        "type": "InputField",
        "name": "Tenant Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-photo-notes",
        "type": "InputField",
        "name": "Photo Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Photo Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-do-the-sellers-text",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4469",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7285",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "0405",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Do the sellers text",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do the sellers text",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-direct-mail-marketing",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1454",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "0143",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "2370",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Direct Mail Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Direct Mail Marketing",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-elevated-photos",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6772",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "2526",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "7008",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Elevated Photos",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Elevated Photos",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-signed-date",
        "type": "Date",
        "name": "Signed Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Signed Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-contractor-box-code",
        "type": "InputField",
        "name": "Contractor Box Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Contractor Box Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-cancellation expiration-fee",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "5778",
                    "value": "I have read and agree to the cancellation expiration fee in the popup window",
                    "isCheck": false
                }
            ],
            "label": "Cancellation Expiration Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Cancellation Expiration Fee: When utilizing the listing management system, if the listing expires or cancels after the photo video shoot is complete, a fee of $500 will be charged. This will be auto-debited from the credit card you have on file with the company."
        },
        "name": "Cancellation Expiration Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Email-tenant-email",
        "type": "Email",
        "name": "Tenant Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-seller-mailing-address",
        "type": "FreeText",
        "name": "Seller Mailing Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-street-address",
        "type": "InputField",
        "name": "Seller Mailing Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-address-line-2",
        "type": "InputField",
        "name": "Seller Mailing Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-city",
        "type": "InputField",
        "name": "Seller Mailing Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-state",
        "type": "InputField",
        "name": "Seller Mailing Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-zip-code",
        "type": "InputField",
        "name": "Seller Mailing Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-country",
        "type": "InputField",
        "name": "Seller Mailing Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-do-you-have-a-key",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7563",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "4348",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6505",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Do you have a key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you have a key",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-occupancy",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6180",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "9269",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "0952",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Occupancy",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Occupancy",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-type-property-description-for-mls-and-marketing",
        "type": "TextArea",
        "name": "Type Property Description for MLS and Marketing",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Type Property Description for MLS and Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const WhisselPendingFormConditions=
       [
        {
          condtions: [
            [
              {
                data_type: "inputfield",
                expected_value: "",
                operator: "not_equals",
                field_id: "InputField-fub-buyer-deal-id"
              },
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              },
              {
                data_type: "dropdown",
                expected_value: "Create_New_Transaction",
                operator: "equals",
                field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
              },
              {
                data_type: "dropdown",
                expected_value: "Homelight Cash Close Program - 2nd Leg",
                operator: "not_equals",
                field_id: "DropDown-transaction-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "CheckBox-a-buyer-transaction-has-already-be"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "inputfield",
                expected_value: "",
                operator: "not_equals",
                field_id: "InputField-fub-seller-deal-id"
              },
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              },
              {
                data_type: "dropdown",
                expected_value: "Create_New_Transaction",
                operator: "equals",
                field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
              },
              {
                data_type: "dropdown",
                expected_value: "Referral Fee Only",
                operator: "not_equals",
                field_id: "DropDown-transaction-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-a-seller-transaction-has-already-b"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Yes",
                operator: "equals",
                field_id: "DropDown-is-there-a-secondary-client?"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-secondary-client",
            "Email-secondary-client-email",
            "InputField-secondary-client-phone"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Other",
                operator: "equals",
                field_id: "DropDown-escrow-company"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-escrow-company-name",
            "InputField-escrow-officer-name",
            "Email-escrow-officer-email-(the-contract-will-automatically-be-sen",
            "InputField-escrow-officer-phone"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Other",
                operator: "equals",
                field_id: "DropDown-loan-officer"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-mortgage-company",
            "InputField-loan-officer-name",
            "Email-loan-officer-email",
            "InputField-loan-officer-phone"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Conventional",
                operator: "equals",
                field_id: "DropDown-financing-type"
              }
            ],
            [
              {
                data_type: "dropdown",
                expected_value: "VA",
                operator: "equals",
                field_id: "DropDown-financing-type"
              }
            ],
            [
              {
                data_type: "dropdown",
                expected_value: "FHA",
                operator: "equals",
                field_id: "DropDown-financing-type"
              }
            ],
            [
              {
                data_type: "dropdown",
                expected_value: "Other",
                operator: "equals",
                field_id: "DropDown-financing-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-loan-officer"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Yes",
                operator: "equals",
                field_id: "DropDown-is-there-an-hoa?"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-hoa-management-company",
            "InputField-hoa-management-phone",
            "InputField-hoa-name"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Yes",
                operator: "equals",
                field_id: "DropDown-referral-fee"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-referral-fee:-company",
            "InputField-referral-fee:-agent",
            "NumberField-referral-fee:-percent"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Yes",
                operator: "equals",
                field_id: "DropDown-are-you-co-representing-this-client-with-another-agent?"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-2nd-whissel-agent-name"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-signed-vip-agreement"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-did-you-use-the-listing-manager-system?"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "hide",
          Fields: [
            "NumberField-listing-agent-commission-percent"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Yes",
                operator: "equals",
                field_id: "DropDown-is-there-an-opposite-side-secondary-client?"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-opposite-side-client-name"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ],
            [
              {
                data_type: "dropdown",
                expected_value: "Create_New_Transaction",
                operator: "equals",
                field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "FreeText-address",
            "InputField-address-street-address",
            "InputField-address-address-line-2",
            "InputField-address-city",
            "InputField-address-state",
            "InputField-address-zip-code",
            "InputField-address-country"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Create_New_Transaction",
                operator: "equals",
                field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
              },
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              },
              {
                data_type: "dropdown",
                expected_value: "Referral Fee Only",
                operator: "not_equals",
                field_id: "DropDown-transaction-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-a-seller-transaction-requires-an-a"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              },
              {
                data_type: "dropdown",
                expected_value: "Update_Existing_Transaction",
                operator: "equals",
                field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
              },
              {
                data_type: "dropdown",
                expected_value: "Homelight Cash Close Program - 2nd Leg",
                operator: "equals",
                field_id: "DropDown-transaction-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-the-2nd-leg-of-a-homelight-cash-cl"
          ],
          ConditionName: "Condition Name"
        },
        // {
        //   condtions: [
        //     [
        //       {
        //         data_type: "dropdown",
        //         expected_value: "Update_Existing_Transaction",
        //         operator: "equals",
        //         field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
        //       },
        //       {
        //         data_type: "dropdown",
        //         expected_value: "Buyer",
        //         operator: "equals",
        //         field_id: "DropDown-client-type"
        //       },
        //       {
        //         data_type: "inputfield",
        //         expected_value: "",
        //         operator: "equals",
        //         field_id: "InputField-fub-buyer-deal-id"
        //       }
        //     ]
        //   ],
        //   Visibility: "show",
        //   Fields: [
        //     "NumberField-error-(buyer)---contact-shannon-or"
        //   ],
        //   ConditionName: "Condition Name"
        // },
        // {
        //   condtions: [
        //     [
        //       {
        //         data_type: "dropdown",
        //         expected_value: "Update_Existing_Transaction",
        //         operator: "equals",
        //         field_id: "DropDown-agents:-do-not-change-this-field-(it-auto-populates-based-on"
        //       },
        //       {
        //         data_type: "dropdown",
        //         expected_value: "Seller",
        //         operator: "equals",
        //         field_id: "DropDown-client-type"
        //       },
        //       {
        //         data_type: "inputfield",
        //         expected_value: "",
        //         operator: "equals",
        //         field_id: "InputField-fub-seller-deal-id"
        //       }
        //     ]
        //   ],
        //   Visibility: "show",
        //   Fields: [
        //     "NumberField-error-(seller)---contact-shannon-o"
        //   ],
        //   ConditionName: "Condition Name"
        // },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-title-company"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              },
              {
                data_type: "dropdown",
                expected_value: "Other",
                operator: "equals",
                field_id: "DropDown-title-company"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-title-company-name",
            "InputField-title-rep-name",
            "Email-title-rep-email"
          ],
          ConditionName: "Condition Name"
        }
      ]
    
 