import React from 'react'
import { useState } from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import img from '../../../assets/images/ld-img.png'
import { LeaderboardContext } from './ContextHelper'
import { useEffect } from 'react'

export const OpportunityModal = ({filter,opplist}) => {
    const [startDate, setStartDate] = useState(null);
    const { leaderboard,setLeaderboard}= React.useContext(LeaderboardContext)
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedParameter, setSelectedParameter] = useState('');
    const [AddcolParameter, setAddcolParameter] = useState('');

    const handleAddcolParameterChange = (event) => {
      setAddcolParameter(event.target.value);
    };


  const handleParameterChange = (event) => {
    setSelectedParameter(event.target.value);
  };

    const handleChange = (event) => {
      setSelectedOption(event.target.id);
    };
    
    const ShowFilterIcon = (filter) => {
      console.log("??",typeof filter)
      if(filter == false){
        return (
          <span>
          <i
          className="iconify text-danger"
          data-icon="bxs:check-circle"
        />
        </span>
        )
      }else{
        return (
          <span>
          <i
          className="iconify text-success"
          data-icon="bx:bx-error"
          />
          </span>
        )
      }
    }


    // console.log("??",filter ? <i className="iconify text-success" data-icon="bxs:check-circle" /> : <i className="iconify text-danger" data-icon="bx:bx-error" />)
    useEffect(()=>{
      const temp={
      "Start Date":startDate,
      "End Date":endDate,
      "Selected Option":selectedOption,
      "Selected Parameter":selectedParameter,
      "AddcolParameter":AddcolParameter,
      // "opplist":opplist
      }
      setLeaderboard({...leaderboard,...temp})
    },[startDate,endDate,selectedOption,selectedParameter,AddcolParameter])
  return (
    <>
  {/* <div className="container py-5 my-5">
    <div
      className="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#oppCreate"
    >
      Create Opp table
    </div>
    <div
      className="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#oppCreate2"
    >
      Create Opp table
    </div>
  </div> */}
  <div className="modal fade" id="oppCreate" tabIndex={-1} role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
    <div className="modal-dialog modal-lg">
      <div className="modal-content border-0 shadow-lg ">
        <div className="modal-header px-5">
          <h5 className="modal-title fs-6">Create New Leaderboard</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body px-5">
          <div className="text-center py-5 px-5">
            <img className="mb-3" src={img} height={200} />
            <h3 className="fw-bold">Leaderboards</h3>
            <h5 className="fw-light">The fun way to drive accountability</h5>
            <p className="mt-4 small fw-light">
              Leaderboards are created from the opportunity table. you can
              generate a leaderboard for different data parameters and from
              custom fields. It’s super easy and fun to generate. You can apply
              filter or use default Data table
            </p>
            <button type="button" className="px-4 btn btn-primary" data-bs-toggle="modal"   data-bs-dismiss="modal"
            aria-label="Close"
      data-bs-target="#oppCreate2">
              Create New Leaderboard
            </button>
          </div>
        </div>
        <div className="modal-footer border-0"></div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="oppCreate2" tabIndex={-1}>
    <div className="modal-dialog modal-lg">
      <div className="modal-content border-0 shadow-lg ">
        <div className="modal-header px-5">
          <h5 className="modal-title fs-6">Create New Leaderboard</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body px-5 pt-4">
          <div className="d-flex align-items-center">
            <div>
              {/* {
                ShowFilterIcon(filter)
              } */}
              <i
                className={filter ? "iconify text-success" : "iconify text-danger"}
                data-icon={filter ? "bxs:check-circle" : "bx:bx-error"}
              />

              {/* {filter ? (<i
                className="iconify text-success"
                data-icon="bxs:check-circle"
              />): (<i
                className="iconify text-danger"
                data-icon="bx:bx-error"
                />)} */}
               <span className="text-decoration-underline small text-secondary">
                Filter applied
              </span>
            </div>
            <i className="text-secondary small mx-2">
              NB: You have the option to apply filters to streamline the
              dataset.
            </i>
          </div>
          <div className="mt-5 mb-5">
            <p style={{ fontWeight: 500 }}>
              Which data parameter would you like to use for grouping your
              leaderboard?
            </p>
            <select className="form-control form-select input-bn" required="" onChange={handleParameterChange} value={selectedParameter}>
              <option disabled="" selected="">
                Select parameter
              </option>
              {/* <option value="stage">Stage</option> */}
              <option value="Agents">Agents</option>
            </select>
          </div>
          <div className="mb-5">
            <p className="m-0 mb-2" style={{ fontWeight: 500 }}>
              How do you want your leaderboards
            </p>
            <span className="small text-secondary">
              Please choose the action you'd like to perform on the
              leaderboards.
            </span>
            <div className="mt-3">
              <div className="mb-3">
                <label
                  className="d-flex form-check-label gap-3 p-3 border shadow-sm rounded-3"
                  htmlFor="countRow"
                >
                  <input
                    className="form-check-input d-x-cdheck"
                    type="radio"
                    name="actiontype"
                    id="countRow"
                    onChange={handleChange}
                    value={selectedOption}
                  />
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: 500 }}>Count Rows</span>
                    <span className="mt-1 small text-secondary">
                      This function counts the total number of data row{" "}
                    </span>
                  </div>
                </label>
              </div>
              <div className="mb-3">
                <label
                  className="d-flex form-check-label gap-3 p-3 border shadow-sm rounded-3"
                  htmlFor="addCol"
                >
                  <input
                    className="form-check-input d-x-check"
                    type="radio"
                    name="actiontype"
                    id="addCol"
                    onChange={handleChange}
                    value={selectedOption}
                  />
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: 500 }}>Add Data Column</span>
                    <span className="mt-1 small text-secondary">
                      This function sums up all numeric datas only{" "}
                    </span>
                  </div>
                </label>
              </div>
              {
                selectedOption == 'addCol' && (<><div className="mt-5 mb-5">
                <p style={{ fontWeight: 500 }}>
                Select Data Column to add
                </p>
                <select className="form-control form-select input-bn" required="" onChange={handleAddcolParameterChange} value={AddcolParameter}>
                  <option disabled="" selected="">
                    Select parameter
                  </option>
                  <option value="Price">Price</option>
                  <option value="Commission Percent">Commission Percent</option>
                </select>
              </div>
                
                </>)
              }
            </div>
          </div>
          <div className="mb-4">
            <p className="m-0 mb-2" style={{ fontWeight: 500 }}>
              Select your Timeframe
            </p>
            <span className="small text-secondary">
              Choose the timeframe for which you'd like the leaderboards to be
              displayed.
            </span>
            <div className="d-flex align-items-end flex-nowrap input-group mt-3">
              <div>
                <label className="small">Start date</label>
                <input
                  className="form-control rounded-0 search-fgy dy-filt"
                  type="date"
                  placeholder="start date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                
                />
              </div>
              <hr className="col-1 mb-4 my-auto" />
              <div>
                <label className="small">End date</label>
                <input
                  className="form-control search-fgy dy-filt rounded-0"
                  type="date"
                  placeholder="end date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer px-5 py-4 border-0">
          <button type="button" className="px-4 btn btn-primary-outline"   data-bs-dismiss="modal"
            aria-label="Close">
            Cancel
          </button>
          
          <button type="button" className="px-4 btn btn-primary" onClick={()=>{navigate('/leaderboard')}}   data-bs-dismiss="modal"
            aria-label="Close" >
            Create New Leaderboard
          </button>
          
        </div>
      </div>
    </div>
  </div>
</>

  )
}
