

export const AppointmentFormFields=[
    {
        "elementid": "DropDown-Appointment-Set-By",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0033",
                    "value": "ISA",
                    "isCheck": false
                },
                {
                    "id": "1490",
                    "value": "OSA",
                    "isCheck": false
                },
                {
                    "id": "8597",
                    "value": "Admin",
                    "isCheck": false
                }
            ],
            "label": "Appointment Set By",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Set By",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_isa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3135",
                    "value": "Daniel Poston",
                    "isCheck": false
                },
                {
                    "id": "0245",
                    "value": "Deeksha T",
                    "isCheck": false
                }
            ],
            "label": "ISAs",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "ISAs",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Admin",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0343",
                    "value": "Jim Smith",
                    "isCheck": false
                }
            ],
            "label": "Admin",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Admin",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-appt_set_lead_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9971",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "2305",
                    "value": "Seller",
                    "isCheck": false
                },
                {
                    "id": "3180",
                    "value": "BuyerSeller",
                    "isCheck": false
                }
            ],
            "label": "Appointment Set Lead Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Set Lead Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-opp_last_name",
        "type": "InputField",
        "name": "Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-are_you_folks_planning_on_making_a_move_in_the_next_3_6_mont",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5713",
                    "value": "0 - 3 Months",
                    "isCheck": false
                },
                {
                    "id": "7316",
                    "value": "4 - 6 Months",
                    "isCheck": false
                },
                {
                    "id": "2910",
                    "value": "7 - 12 Months",
                    "isCheck": false
                }
            ],
            "label": "Are you folks planning on making a move in the next 3-6 Months?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you folks planning on making a move in the next 3-6 Months?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-do_you_have_an_idea_on_when_you_d_like_to_close?",
        "type": "InputField",
        "name": "Do you have an idea on when you'd like to close?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Do you have an idea on when you'd like to close?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-if_you_were_to_move__would_you_be_staying_in_the_area_or_mov",
        "type": "InputField",
        "name": "If you were to move, would you be staying in the area or moving out of the area?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "If you were to move, would you be staying in the area or moving out of the area?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_s_your_primary_motivation_for_making_a_move?",
        "type": "InputField",
        "name": "What's your primary motivation for making a move?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What's your primary motivation for making a move?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-do_you_have_a_realtor_to_help_you_when_the_time_is_right?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5759",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "1423",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Do you have a realtor to help you when the time is right?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you have a realtor to help you when the time is right?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-are_you_100%_committed_to_that_realtor?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7822",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "3743",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Are you 100% committed to that realtor?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you 100% committed to that realtor?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Headings-Appointment_Details",
        "type": "Headings",
        "settings": {
            "title": "Appointment Details",
            "size": "left",
            "inputType": "text"
        },
        "name": "Appointment Details"
    },
    {
        "elementid": "DropDown-did_you_schedule_an_appointment?",
        "name": "Did you schedule an appointment?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1359",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "2357",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Did you schedule an appointment?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_appt_date",
        "type": "Date",
        "name": "Appointment Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-appointment_time",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2631",
                    "value": "07:30 AM",
                    "isCheck": false
                },
                {
                    "id": "8298",
                    "value": "07:45 AM",
                    "isCheck": false
                },
                {
                    "id": "9673",
                    "value": "08:00 AM",
                    "isCheck": false
                },
                {
                    "id": "1180",
                    "value": "08:15 AM",
                    "isCheck": false
                },
                {
                    "id": "9161",
                    "value": "08:30 AM",
                    "isCheck": false
                },
                {
                    "id": "2920",
                    "value": "08:45 AM",
                    "isCheck": false
                },
                {
                    "id": "2854",
                    "value": "09:00 AM",
                    "isCheck": false
                },
                {
                    "id": "5796",
                    "value": "09:15 AM",
                    "isCheck": false
                },
                {
                    "id": "2269",
                    "value": "09:30 AM",
                    "isCheck": false
                },
                {
                    "id": "7865",
                    "value": "09:45 AM",
                    "isCheck": false
                },
                {
                    "id": "8321",
                    "value": "10:00 AM",
                    "isCheck": false
                },
                {
                    "id": "1097",
                    "value": "10:15 AM",
                    "isCheck": false
                },
                {
                    "id": "4610",
                    "value": "10:30 AM",
                    "isCheck": false
                },
                {
                    "id": "6448",
                    "value": "10:45 AM",
                    "isCheck": false
                },
                {
                    "id": "2773",
                    "value": "11:00 AM",
                    "isCheck": false
                },
                {
                    "id": "7103",
                    "value": "11:15 AM",
                    "isCheck": false
                },
                {
                    "id": "7926",
                    "value": "11:30 AM",
                    "isCheck": false
                },
                {
                    "id": "6207",
                    "value": "11:45 AM",
                    "isCheck": false
                },
                {
                    "id": "3145",
                    "value": "12:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9780",
                    "value": "12:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8225",
                    "value": "12:30 PM",
                    "isCheck": false
                },
                {
                    "id": "7180",
                    "value": "12:45 PM",
                    "isCheck": false
                },
                {
                    "id": "1397",
                    "value": "01:00 PM",
                    "isCheck": false
                },
                {
                    "id": "1156",
                    "value": "01:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1314",
                    "value": "01:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9429",
                    "value": "01:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5830",
                    "value": "02:00 PM",
                    "isCheck": false
                },
                {
                    "id": "0137",
                    "value": "02:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8106",
                    "value": "02:30 PM",
                    "isCheck": false
                },
                {
                    "id": "7518",
                    "value": "02:45 PM",
                    "isCheck": false
                },
                {
                    "id": "4236",
                    "value": "03:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6496",
                    "value": "03:15 PM",
                    "isCheck": false
                },
                {
                    "id": "8654",
                    "value": "03:30 PM",
                    "isCheck": false
                },
                {
                    "id": "4531",
                    "value": "03:45 PM",
                    "isCheck": false
                },
                {
                    "id": "2566",
                    "value": "04:00 PM",
                    "isCheck": false
                },
                {
                    "id": "9237",
                    "value": "04:15 PM",
                    "isCheck": false
                },
                {
                    "id": "7183",
                    "value": "04:30 PM",
                    "isCheck": false
                },
                {
                    "id": "1165",
                    "value": "04:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5182",
                    "value": "05:00 PM",
                    "isCheck": false
                },
                {
                    "id": "8351",
                    "value": "05:15 PM",
                    "isCheck": false
                },
                {
                    "id": "4363",
                    "value": "05:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6599",
                    "value": "05:45 PM",
                    "isCheck": false
                },
                {
                    "id": "5660",
                    "value": "06:00 PM",
                    "isCheck": false
                },
                {
                    "id": "3808",
                    "value": "06:15 PM",
                    "isCheck": false
                },
                {
                    "id": "6672",
                    "value": "06:30 PM",
                    "isCheck": false
                },
                {
                    "id": "9783",
                    "value": "06:45 PM",
                    "isCheck": false
                },
                {
                    "id": "6459",
                    "value": "07:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6671",
                    "value": "07:15 PM",
                    "isCheck": false
                },
                {
                    "id": "3639",
                    "value": "07:30 PM",
                    "isCheck": false
                },
                {
                    "id": "6921",
                    "value": "07:45 PM",
                    "isCheck": false
                },
                {
                    "id": "8969",
                    "value": "08:00 PM",
                    "isCheck": false
                },
                {
                    "id": "6555",
                    "value": "08:15 PM",
                    "isCheck": false
                },
                {
                    "id": "1155",
                    "value": "08:30 PM",
                    "isCheck": false
                }
            ],
            "label": "Appointment Time",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Time",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-App-timezone",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1466",
                    "value": "America/New_York",
                    "isCheck": false
                },
                {
                    "id": "7485",
                    "value": "America/Los_Angeles",
                    "isCheck": false
                }
            ],
            "label": "Appointment Timezone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Timezone",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Appt-Location",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4864",
                    "value": "Office",
                    "isCheck": false
                },
                {
                    "id": "6480",
                    "value": "Other Address",
                    "isCheck": false
                },
                {
                    "id": "6319",
                    "value": "Seller Property Address",
                    "isCheck": false
                },
                {
                    "id": "7533",
                    "value": "Buyer Inquiry Address",
                    "isCheck": false
                },
                {
                    "id": "5931",
                    "value": "Zoom",
                    "isCheck": false
                },
                {
                    "id": "8067",
                    "value": "Phone",
                    "isCheck": false
                }
            ],
            "label": "Appointment Location",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Location",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-appointment_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2003",
                    "value": "No Type",
                    "isCheck": false
                },
                {
                    "id": "3602",
                    "value": "Buyer consultation",
                    "isCheck": false
                },
                {
                    "id": "3727",
                    "value": "Listing",
                    "isCheck": false
                },
                {
                    "id": "8100",
                    "value": "Showing",
                    "isCheck": false
                }
            ],
            "label": "Appointment Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-zoom-link",
        "type": "InputField",
        "name": "Zoom Link",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Zoom Link",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address",
        "type": "InputField",
        "name": "Address of Appointment-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_address2",
        "type": "InputField",
        "name": "Address of Appointment-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_city",
        "type": "InputField",
        "name": "Address of Appointment-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_state",
        "type": "InputField",
        "name": "Address of Appointment-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp_postal_code",
        "type": "InputField",
        "name": "Address of Appointment-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address of Appointment-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_country",
        "type": "DropDown",
        "name": "Address of Appointment-Country",
        "field_sub_type": "opportunity",
        "settings": {}
    },
    {
        "elementid": "TextArea-App-Description",
        "type": "TextArea",
        "name": "Appointment Description",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appointment Description",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "This field will be added to the appointment description and the client will be able to read it."
        }
    },
    {
        "elementid": "DropDown-are_you_the_primary_decision_maker__and_are_there_any_other_",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4772",
                    "value": "Yes - Primary and Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "8026",
                    "value": "Yes - Primary but NOT Only Decision Maker",
                    "isCheck": false
                },
                {
                    "id": "2522",
                    "value": "No - Not Primary Decision Maker",
                    "isCheck": false
                }
            ],
            "label": "Are you the primary decision maker, and are there any other decision makers?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you the primary decision maker, and are there any other decision makers?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-will_they_be_available_for_the_appointment?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8962",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8146",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Will they be available for the appointment?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Will they be available for the appointment?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary_contact",
        "name": "Secondary Contact Name",
        "type": "InputField",
        "settings": {
            "label": "Secondary Contact Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "Contact",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "type": "Email",
        "name": "Secondary Contact email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-may_i_ask_where_you_received_our_name_and_information_from_o",
        "type": "InputField",
        "name": "May I ask where you received our name and information from or who can we thank for referring you?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "May I ask where you received our name and information from or who can we thank for referring you?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-second_source",
        "type": "InputField",
        "name": "Second Source",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Second Source",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Buyer_Section_Appt_Form",
        "type": "Headings",
        "settings": {
            "title": "Buyer Section",
            "size": "left",
            "inputType": "text"
        },
        "name": "Buyer Section"
    },
    {
        "elementid": "DropDown-do_you_currently_rent_or_own_your_own_home?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4688",
                    "value": "Rent",
                    "isCheck": false
                },
                {
                    "id": "2923",
                    "value": "Own",
                    "isCheck": false
                }
            ],
            "label": "Do you currently rent or own your own home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you currently rent or own your own home?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-when_does_your_lease_end?",
        "type": "InputField",
        "name": "When does your lease end?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "When does your lease end?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_low",
        "type": "NumberField",
        "name": "Home Search Price Low",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price Low",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_search_price_high",
        "type": "NumberField",
        "name": "Home Search Price High",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Search Price High",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-have_you_been_pre_approved_by_a_mortgage_company?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5145",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "7229",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Have you been pre-approved by a mortgage company?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Have you been pre-approved by a mortgage company?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "FreeText-inquiry_address",
        "type": "FreeText",
        "name": "Inquiry Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_street_address",
        "type": "InputField",
        "name": "Inquiry Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_address_line_2",
        "type": "InputField",
        "name": "Inquiry Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_city",
        "type": "InputField",
        "name": "Inquiry Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_state",
        "type": "InputField",
        "name": "Inquiry Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_zip_code",
        "type": "InputField",
        "name": "Inquiry Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-inquiry_address_country",
        "type": "InputField",
        "name": "Inquiry Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Inquiry Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_areas_are_you_most_interested_in?",
        "type": "InputField",
        "name": "What areas are you most interested in?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What areas are you most interested in?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_are_the_specifics_you_re_looking_for_in_a_home?",
        "type": "InputField",
        "name": "What are the specifics you're looking for in a home?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What are the specifics you're looking for in a home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Headings-Seller_Section",
        "type": "Headings",
        "settings": {
            "title": "Seller Section",
            "size": "left",
            "inputType": "text"
        },
        "name": "Seller Section"
    },
    {
        "elementid": "DropDown-rental",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8529",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "9958",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Rental",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Rental",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-do_you_know_what_you_owe_on_your_home?",
        "type": "NumberField",
        "name": "Do you know what you owe on your home?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Do you know what you owe on your home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-home_value_low",
        "type": "NumberField",
        "name": "Home Value Low",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Value Low",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "How much are you hoping to sell your home for? What price range do you think it's in? Just give me your best guess."
        }
    },
    {
        "elementid": "NumberField-home_value_high",
        "type": "NumberField",
        "name": "Home Value High",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Home Value High",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "How much are you hoping to sell your home for? What price range do you think it's in? Just give me your best guess."
        }
    },
    {
        "elementid": "FreeText-the_address_of_the_home_you_are_selling_is_",
        "type": "FreeText",
        "name": "The address of the home you are selling is:",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__street_address",
        "type": "InputField",
        "name": "The address of the home you are selling is:-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-Street Address",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__address_line_2",
        "type": "InputField",
        "name": "The address of the home you are selling is:-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__city",
        "type": "InputField",
        "name": "The address of the home you are selling is:-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-City",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__state",
        "type": "InputField",
        "name": "The address of the home you are selling is:-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-State",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__zip_code",
        "type": "InputField",
        "name": "The address of the home you are selling is:-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-Zip Code",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-the_address_of_the_home_you_are_selling_is__country",
        "type": "InputField",
        "name": "The address of the home you are selling is:-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "The address of the home you are selling is:-Country",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-number_of_bedrooms",
        "type": "NumberField",
        "name": "Number of bedrooms",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number of bedrooms",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
   
    {
        "elementid": "NumberField-number_of_garage_spaces",
        "type": "NumberField",
        "name": "Number of garage spaces",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number of garage spaces",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-what_are_the_most_important_things_to_you_in_the_sale_of_you",
        "type": "InputField",
        "name": "What are the most important things to you in the sale of your home?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What are the most important things to you in the sale of your home?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-would_you_prefer_to_buy_before_listing_your_home__or_do_you_",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2038",
                    "value": "Buy First",
                    "isCheck": false
                },
                {
                    "id": "3130",
                    "value": "Sell First",
                    "isCheck": false
                }
            ],
            "label": "Would you prefer to buy before listing your home, or do you want to sell first?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Would you prefer to buy before listing your home, or do you want to sell first?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-what_have_you_done_to_your_home__if_anything__since_you_boug",
        "type": "InputField",
        "name": "What have you done to your home, if anything, since you bought it?",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "What have you done to your home, if anything, since you bought it?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-listing_type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6062",
                    "value": "Regular Listing",
                    "isCheck": false
                },
                {
                    "id": "6334",
                    "value": "GO Listing",
                    "isCheck": false
                },
                {
                    "id": "6677",
                    "value": "ZO Listing",
                    "isCheck": false
                }
            ],
            "label": "Listing Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Listing Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is_the_basement_finished?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7433",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "8514",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Is the basement finished?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is the basement finished?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-bedroom_and_bathroom_breakdown_by_level",
        "type": "InputField",
        "name": "Bedroom and bathroom breakdown by level",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Bedroom and bathroom breakdown by level",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "How many bedrooms and bathrooms are on each level"
        }
    },
    {
        "elementid": "Headings-Additional_Info",
        "type": "Headings",
        "settings": {
            "title": "Additional Info",
            "size": "left",
            "inputType": "text"
        },
        "name": "Additional Info"
    },
    {
        "elementid": "DropDown-Appointment-Assignment",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3931",
                    "value": "Assign OSA Now",
                    "isCheck": false
                },
                {
                    "id": "3632",
                    "value": "Assign OSA Later",
                    "isCheck": false
                }
            ],
            "label": "Appointment Assignment",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Appointment Assignment",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-Send-Confirmation",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8717",
                    "value": "Yes",
                    "isCheck": false
                },
                {
                    "id": "6378",
                    "value": "No",
                    "isCheck": false
                }
            ],
            "label": "Send Confirmation",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Send Confirmation",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-opp_assigned_osa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1376",
                    "value": "Carol Danvers",
                    "isCheck": false
                },
                {
                    "id": "2642",
                    "value": "Clark Kent",
                    "isCheck": false
                },
                {
                    "id": "9538",
                    "value": "Daniel Poston",
                    "isCheck": false
                },
                {
                    "id": "2613",
                    "value": "Peter Parker",
                    "isCheck": false
                }
            ],
            "label": "Assigned OSA",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Assigned OSA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid":"CheckBox-D_I_S_C-Profile",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "9167",
                    "value": "(D)ominant",
                    "isCheck": false
                },
                {
                    "id": "0514",
                    "value": "(I)nfluencer",
                    "isCheck": false
                },
                {
                    "id": "4571",
                    "value": "(S)upportive",
                    "isCheck": false
                },
                {
                    "id": "6512",
                    "value": "(C)onscientiousness",
                    "isCheck": false
                }
            ],
            "label": "D.I.S.C. Profile",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "D.I.S.C. Profile",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-email_(for_automation)",
        "type": "Email",
        "name": "Email (For Automation)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Email (For Automation)",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]

export const AppointmentFormCondition= [
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Seller",
                    "operator": "equals",
                    "field_id": "DropDown-appt_set_lead_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "BuyerSeller",
                    "operator": "equals",
                    "field_id": "DropDown-appt_set_lead_type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Headings-Seller_Section",
            "NumberField-do_you_know_what_you_owe_on_your_home?",
            "NumberField-home_value_low",
            "NumberField-home_value_high",
            "FreeText-the_address_of_the_home_you_are_selling_is_",
            "InputField-the_address_of_the_home_you_are_selling_is__street_address",
            "InputField-the_address_of_the_home_you_are_selling_is__address_line_2",
            "InputField-the_address_of_the_home_you_are_selling_is__city",
            "InputField-the_address_of_the_home_you_are_selling_is__state",
            "InputField-the_address_of_the_home_you_are_selling_is__zip_code",
            "InputField-the_address_of_the_home_you_are_selling_is__country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Other Address",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "FreeText-address",
            "InputField-opp_address",
            "InputField-opp_address2",
            "InputField-opp_city",
            "InputField-opp_state",
            "InputField-opp_postal_code",
            "DropDown-opp_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Buyer",
                    "operator": "equals",
                    "field_id": "DropDown-appt_set_lead_type"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "BuyerSeller",
                    "operator": "equals",
                    "field_id": "DropDown-appt_set_lead_type"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Headings-Buyer_Section_Appt_Form",
            "DropDown-do_you_currently_rent_or_own_your_own_home?",
            "NumberField-home_search_price_low",
            "NumberField-home_search_price_high",
            "DropDown-have_you_been_pre_approved_by_a_mortgage_company?",
            "FreeText-inquiry_address",
            "InputField-inquiry_address_street_address",
            "InputField-inquiry_address_address_line_2",
            "InputField-inquiry_address_city",
            "InputField-inquiry_address_state",
            "InputField-inquiry_address_zip_code",
            "InputField-inquiry_address_country"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes - Primary but NOT Only Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-are_you_the_primary_decision_maker__and_are_there_any_other_"
                }
            ],
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "No - Not Primary Decision Maker",
                    "operator": "equals",
                    "field_id": "DropDown-are_you_the_primary_decision_maker__and_are_there_any_other_"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-secondary_contact",
            "DropDown-will_they_be_available_for_the_appointment?",
            "Contact-secondary_contact_phone",
            "Email-secondary_contact_email"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "ISA",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-opp_isa"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Admin",
                    "operator": "equals",
                    "field_id": "DropDown-Appointment-Set-By"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-Admin"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Rent",
                    "operator": "equals",
                    "field_id": "DropDown-do_you_currently_rent_or_own_your_own_home?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-when_does_your_lease_end?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-do_you_have_a_realtor_to_help_you_when_the_time_is_right?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-are_you_100%_committed_to_that_realtor?"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-did_you_schedule_an_appointment?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "Date-opp_appt_date",
            "DropDown-App-timezone",
            "DropDown-appointment_time",
            "DropDown-Appt-Location",
            "Headings-Appointment_Details",
            "TextArea-App-Description"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Zoom",
                    "operator": "equals",
                    "field_id": "DropDown-Appt-Location"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "InputField-zoom-link"
        ],
        "ConditionName": "Condition Name"
    },
    {
        "condtions": [
            [
                {
                    "data_type": "dropdown",
                    "expected_value": "Yes",
                    "operator": "equals",
                    "field_id": "DropDown-did_you_schedule_an_appointment?"
                }
            ]
        ],
        "Visibility": "show",
        "Fields": [
            "DropDown-appointment_type"
        ],
        "ConditionName": "Condition Name"
    }
]