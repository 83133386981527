import { useEffect } from "react";
import { ElementsUIGenrate } from "./ElementsUIGenrate";
import { gql, useQuery,useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

const UpdateFormFields = gql`
mutation UpdateField($fieldId: String, $formId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, form_id: $formId, field_data: $fieldData) {
    field_id
  }
}`

export default function Element({ element,setDeleteField,IsaList,osaList,AdminList,Elementtype }) {
  const disableElementList = [
    "InputField-Follow-up-boss-lead-id",
    "InputField-opp-key",
  ];
  const params = useParams();

  const [UpdateField] = useMutation(UpdateFormFields);

  // useEffect(()=>{
  //   if(element?.elementid ==  "DropDown-opp_isa" ){
  //     UpdateField({
  //       variables: {
  //         fieldId: element?.elementid,
  //         formId: params?.id,
  //         fieldData: {
  //           field_name: element?.name,
  //           input_type: element?.type,
  //           settings: element?.settings,
  //         },
  //       },
  //     });
  //   }
  // },[])
 
  let UI;
  if (disableElementList.includes(element?.elementid)) {
    return null;
  } else {
    const Store = ElementsUIGenrate(element?.type);
    UI = Store?.ui;
  }
 

  return <UI elementid={element.elementid} element={element} setDeleteField={setDeleteField} IsaList={IsaList} osaList={osaList} AdminList={AdminList} Elementtype={Elementtype}/>;
}
