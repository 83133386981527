import React, { useState, useEffect, useContext } from "react";
import PlatformTriggersList from "./ListPlatformTriggers";
import TestTrigger from "./TestTrigger";
import TypingDots from "../../../assets/images/typing-dots.gif";
import { WorkFlowSessionInfo } from '../../../pages/automation-builder/workflows/EditWorkflow'
import { gql, useQuery } from "@apollo/client";


const FormData = gql`
  query FormData($formId: String) {
    formData(form_id: $formId) {
      form_id
      form_name
      form_description
      form_fields
    }
  }
`;

function Trigger({
  triggerdetails,
  setTriggerDetails,
  selected_action,
  setSelected_action,
  action_index = 1
}) {
  const [testTrigger, setTestTrigger] = useState("");
  const [isTriggerCreated, setIsTriggerCreated] = useState("not_created");
  const[details, setdetails] = useState({});
  const [testAgain, settestAgain] = useState(true);
  const {session, setFormDetails, updating} = useContext(WorkFlowSessionInfo);
  const [showDetails, setShowDetails] = useState(session?.trigger);
  const [test_result, set_test_result] = useState(session?.trigger?.action_status === "SUCCESS" ? false : true );
  const [formId, setFormId] = useState("");
  const { loading: formloading, data: formdata } = useQuery(FormData, {
    skip: !formId,
    variables: {
      formId,
    },
  });

useEffect(() => {
 if(triggerdetails.data_mapper?.platform === "INTERFACE"){
  setFormId(triggerdetails.data_mapper?.trigger_id)
  }
}, [triggerdetails]);

  useEffect(() => {
   if (selected_action !== "trigger"){
    setTestTrigger("")
    if(session?.trigger){
      setShowDetails(true)
    }
   }
  }, [selected_action]);

  useEffect(() => {
    if(session?.trigger){
      setShowDetails(true)
    }
    else{
      setShowDetails(false)
    }
  }, []);

  useEffect(() => {
    let form_Details = formdata?.formData;
    setFormDetails(form_Details);
  }, [formdata]);


  useEffect(() => {
    session?.trigger?.action_status === "SUCCESS" ? set_test_result(false) : set_test_result(true)
  }, [session]);

  
const formatFieldName = (fieldName) => {
  const wordsArray = fieldName.replace(/([A-Z])/g, ' $1').trim().split(' ');
  const formattedWords = wordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return formattedWords;
};

  return (
    <>
      <div>
        {triggerdetails.data_mapper?.trigger_id ? (
          <div
            className=" bg-white p-4 rounded-3 border mb-2"
            onClick={() => setSelected_action("trigger")}
          >
                {isTriggerCreated === "creating" || formloading  ? (
                <>
                  <div className="d-flex flex-column align-items-center" style={{ height: "127px"}}>
                  <p className="m-0 mt-4">{formloading ? "Loading details" : "Changing trigger"}</p>
                    <div>
                      <img style={{ height: "60px" }} src={TypingDots} alt="Changing trigger" />
                    </div>
                  </div>
                  </>
                )
                : (
                <>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div className="d-flex mb-3">
                    <strong> {action_index+". "}</strong> 
                    <i
                      className="iconify p-0 fs-2 text-primary"
                      data-icon="ic:sharp-bolt"
                    ></i>
                    {triggerdetails.data_mapper?.platform === "FUB" && (
                    <span>
                      <span className="text-capitalize">{triggerdetails.data_mapper?.type}</span>
                      <br/>
                      <span className="text-secondary small">
                        {triggerdetails.data_mapper?.field_id
                          ? `Triggered when a ${formatFieldName(triggerdetails.data_mapper.field_id)} field is changed`
                          : triggerdetails.data_mapper?.description}
                      </span>
                    </span>
                    )}
                    {triggerdetails.data_mapper?.platform === "INTERFACE" && (
                      <span>
                       <span className="text-capitalize">InterFace Forms</span>
                       <br/>
                      <span className="text-secondary small">
                         {formdata?.formData?.form_name}</span>
                      <br/>
                       <span className="text-secondary small">
                         {formdata?.formData?.form_description}
                       </span>
                     </span>
                      )}
                    </div>

                  <div className="d-flex align-items-center mb-3">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#triggerModal"
                      // onClick={()=> setTestTrigger("")}
                    >
                      Change Trigger
                    </button>
                  </div>
                </div>

                <div className="modal-header">
                <div
                  className="d-flex align-items-center mt-3 ms-2 tooltip-container"
                >
                  <i
                    className="iconify p-0 fs-3 me-1 text-primary"
                    data-icon="ic:round-network-check"
                  ></i>
                  <button className={`d-none d-md-block text-decoration-underline btn btn-outline-none ${updating ? 'disabled-test' : ''}`}
                      onClick={() => {
                        if (!updating) {
                          setShowDetails(false);
                          settestAgain(true);
                          testTrigger === "tested_trigger" ? setTestTrigger("test_trigger_again") : setTestTrigger("tested_trigger");
                        }
                      }}
                    >
                      Test Trigger
                      {/* {testTrigger ? "Test Trigger Again" : "Test Trigger" } */}
                  </button>
                  {updating && (
                    <div className="tooltip-text">
                      Cannot test trigger when workflow is being updated
                    </div>
                  )}
                  { test_result && (
                  <p className="text-danger m-3">
                    Please test the  trigger!
                  </p>)}
                </div>
                {session?.trigger && (
                <button
                type="button"
                className=" dropdown-toggle mt-3 btn-outline"
                data-bs-toggle="collapse"
                data-bs-target="#test_automation"
                onClick={() => {setShowDetails(!showDetails)
                  setTestTrigger("test_trigger_again")
                  settestAgain(false)   
              }}
              >
                {showDetails ? "Show previous test details" : "Hide" }
              </button>
                 )}
                </div>
                {testTrigger === "tested_trigger" && (
                  <TestTrigger
                    setTriggerDetails={setTriggerDetails}
                    triggerdetails={triggerdetails}
                    trigger_id={triggerdetails.data_mapper?.trigger_id}
                    setTestTrigger={setTestTrigger}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                )} 
                {!showDetails && testTrigger === "test_trigger_again" && (
                  
                  <div
                  id="test_automation"
                  // className={`${showDetails ?  "show accordion-collapse collapse" : ""  }`}
                  >
                  <TestTrigger
                    setTriggerDetails={setTriggerDetails}
                    triggerdetails={triggerdetails}
                    trigger_id={triggerdetails.data_mapper?.trigger_id}
                    setTestTrigger={setTestTrigger}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                  </div>
                 )}
                </>
                )
                }
          </div>
        ) : (
          <>
          {isTriggerCreated === "creating" ? (
            <>
             <div className=" bg-white p-4 rounded-3 border">
              <div className="d-flex flex-column align-items-center" style={{ height: "127px"}}>
              <p className="m-0 mt-4">Adding trigger</p>
                <div>
                  <img style={{ height: "60px" }} src={TypingDots} alt="Changing trigger" />
                </div>
              </div>
            </div>
              </>
            )
            :
            (
            <div data-bs-toggle="modal" data-bs-target="#triggerModal">
              <div className=" bg-white p-3 rounded-3 border select-trigger">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <i
                      className="iconify p-0 display-4 text-primary"
                      data-icon="ic:sharp-bolt"
                    ></i>
                    <h6>
                      {triggerdetails.data_mapper?.trigger_id
                        ? "Change Trigger"
                        : "No trigger selected! Click to select a trigger for this automation."}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            )}
          </>
        )}
      </div>

      <PlatformTriggersList triggerdetails={triggerdetails} setTriggerDetails={setTriggerDetails}
      details={details}
      setdetails={setdetails}
      setIsTriggerCreated={setIsTriggerCreated}
      />
    </>
  );
}

export default Trigger;
