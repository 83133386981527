export const WhisselClosedForm = [

    {
        "elementid": "Email-email-(for-automation)",
        "type": "Email",
        "name": "Email (For Automation)",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Email (For Automation)",
            "predefine": "whisselrealtygroupnotes.51ana@zapiermail.com",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-email-(for-automation)-indie",
        "type": "Email",
        "name": "Email (For Automation) Indie",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Email (For Automation) Indie",
            "predefine": "whisselrealtygroupnotes.habv6u@zapiermail.com",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-Follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-client-last-name",
        "type": "InputField",
        "name": "Client Last Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Client Last Name",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-opp-key",
        "type": "InputField",
        "name": "Opp Key",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Opp Key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Please do not touch this field"
        }
    },
    {
        "elementid": "DropDown-client-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6543",
                    "value": "Buyer",
                    "isCheck": false
                },
                {
                    "id": "5711",
                    "value": "Seller",
                    "isCheck": false
                }
            ],
            "label": "Client Type",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Client Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-creating-updating-transaction",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7220",
                    "value": "Update_Existing_Transaction",
                    "isCheck": false
                },
                {
                    "id": "1822",
                    "value": "Create_New_Transaction",
                    "isCheck": false
                }
            ],
            "label": "Creating or Updating Transaction?",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Creating or Updating Transaction?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-fub-buyer-deal-id",
        "type": "InputField",
        "name": "FUB Buyer Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Buyer Deal ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-fub-seller-deal-id",
        "type": "InputField",
        "name": "FUB Seller Deal ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "FUB Seller Deal ID",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-appt-entry-id",
        "type": "InputField",
        "name": "Appt Entry ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Appt Entry ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-transaction-stage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4349",
                    "value": "Closed",
                    "isCheck": false
                },
                {
                    "id": "2664",
                    "value": "Pending",
                    "isCheck": false
                }
            ],
            "label": "Transaction Stage",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": "If cancelling a pending transaction, choose pending here."
        },
        "name": "Transaction Stage",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-pending-stage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3746",
                    "value": "Pending Cancelled",
                    "isCheck": false
                }
            ],
            "label": "Pending Stage",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Pending Stage",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is-there-a-secondary-client?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9234",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7986",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "7238",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Is there a secondary client?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a secondary client?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-secondary-follow-up-boss-lead-id",
        "type": "InputField",
        "name": "Secondary Follow Up Boss Lead ID",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Follow Up Boss Lead ID",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Contact-secondary_contact_phone",
        "type": "InputField",
        "name": "Secondary Contact",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-secondary_contact_email",
        "name": "Secondary Contact Email",
        "type": "Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-secondary-contact-phone",
        "type": "InputField",
        "name": "Secondary Contact Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Secondary Contact Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-address",
        "type": "FreeText",
        "name": "Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-street-address",
        "type": "InputField",
        "name": "Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-address-line-2",
        "type": "InputField",
        "name": "Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-city",
        "type": "InputField",
        "name": "Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-state",
        "type": "InputField",
        "name": "Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-zip-code",
        "type": "InputField",
        "name": "Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-address-country",
        "type": "InputField",
        "name": "Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-transaction-amount",
        "type": "NumberField",
        "name": "Transaction Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Transaction Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-gci-amount",
        "type": "NumberField",
        "name": "GCI Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "GCI Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent-commission-amount",
        "type": "NumberField",
        "name": "Agent Commission Amount",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agent Commission Amount",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-agent-commission-percent",
        "type": "NumberField",
        "name": "Agent Commission Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agent Commission Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-tc-fee",
        "type": "NumberField",
        "name": "TC Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "TC Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-referral-fee",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8492",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "4949",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "7288",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Referral Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Referral Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-referral-fee:-company",
        "type": "InputField",
        "name": "Referral Fee: Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-referral-fee:-agent",
        "type": "InputField",
        "name": "Referral Fee: Agent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Agent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-referral-fee:-percent",
        "type": "NumberField",
        "name": "Referral Fee: Percent",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Referral Fee: Percent",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-sign-requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3639",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "8358",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "2500",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Sign Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Sign Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is-there-a-transaction/admin-fee,-and-if-so,-who-is-responsi",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9886",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "6075",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "5093",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Is there a Transaction/Admin fee, and if so, who is responsible for paying it?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there a Transaction/Admin fee, and if so, who is responsible for paying it?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "NumberField-admin-fee",
        "type": "NumberField",
        "name": "Admin Fee",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Admin Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_signed_date",
        "type": "Date",
        "name": "Signed Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Signed Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_agreement_expiration_date",
        "type": "Date",
        "name": "Agreement Expiration Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Agreement Expiration Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-sign-install-date",
        "type": "Date",
        "name": "Sign Install Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Sign Install Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-open-house-date",
        "type": "Date",
        "name": "Open House Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Open House Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-target-photo-date",
        "type": "Date",
        "name": "Target Photo Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Target Photo Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-mls-live-date",
        "type": "Date",
        "name": "MLS Live Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "MLS Live Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-under-contract-date",
        "type": "Date",
        "name": "Under Contract Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Under Contract Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_forecasted_close_date",
        "type": "Date",
        "name": "Forecasted Close Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Forecasted Close Date",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-opp_settlement_date",
        "type": "Date",
        "name": "Settlement Date",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Settlement Date",
            "predefine": "",
            "size": "large",
            "required": true,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-is-there-hoa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "6821",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3382",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "9655",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Is there HOA",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is there HOA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-hoa-name",
        "type": "InputField",
        "name": "HOA Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa-management-company",
        "type": "InputField",
        "name": "HOA Management Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-hoa-management-phone",
        "type": "InputField",
        "name": "HOA Management Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "HOA Management Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "TextArea-Notes",
        "type": "TextArea",
        "name": "Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-opp_assigned_osa",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "4552",
                    "value": "Daniel Poston",
                    "isCheck": false
                }
            ],
            "label": "Assigned OSA",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Assigned OSA",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "Date-date-2",
        "type": "Date",
        "name": "Date 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Date 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-date-3",
        "type": "Date",
        "name": "Date 3",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Date 3",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-date-4",
        "type": "Date",
        "name": "Date 4",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Date 4",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Date-date-5",
        "type": "Date",
        "name": "Date 5",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Date 5",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-number",
        "type": "NumberField",
        "name": "Number",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-number",
        "type": "NumberField",
        "name": "Number",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-number",
        "type": "NumberField",
        "name": "Number",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "NumberField-number",
        "type": "NumberField",
        "name": "Number",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Number",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-solar",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3899",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "8656",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "9932",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Solar",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Solar",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-title-company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1705",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "8877",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8030",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Title Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Title Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-title-company-name",
        "type": "InputField",
        "name": "Title Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Company Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-title-rep-name",
        "type": "InputField",
        "name": "Title Rep Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-title-rep-email",
        "type": "Email",
        "name": "Title Rep Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Title Rep Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-escrow-company",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3816",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "4750",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "3465",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Escrow Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Escrow Company",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-escrow-company-name",
        "type": "InputField",
        "name": "Escrow Company Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Company Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow-officer-name",
        "type": "InputField",
        "name": "Escrow Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-escrow-officer-email",
        "type": "Email",
        "name": "Escrow Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-escrow-officer-phone",
        "type": "InputField",
        "name": "Escrow Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Escrow Officer Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-financing-type",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "2560",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "5991",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "2501",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Financing Type",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Financing Type",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-loan-officer",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1859",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "6302",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6198",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Loan Officer",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Loan Officer",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-mortgage-company",
        "type": "InputField",
        "name": "Mortgage Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Mortgage Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan-officer-name",
        "type": "InputField",
        "name": "Loan Officer Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-loan-officer-email",
        "type": "Email",
        "name": "Loan Officer Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-loan-officer-phone",
        "type": "InputField",
        "name": "Loan Officer Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Loan Officer Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-coming-soon-marketing",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "3643",
                    "value": "MLS Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": "0181",
                    "value": "Zillow Coming Soon Listing",
                    "isCheck": false
                },
                {
                    "id": "3570",
                    "value": "Coming Soon Yard Sign",
                    "isCheck": false
                }
            ],
            "label": "Coming Soon Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Coming Soon Marketing",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-staging-needed",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5587",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "6058",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "5836",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Staging Needed",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Staging Needed",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-who-is-paying-for-staging?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8289",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7667",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "0941",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Who is paying for staging?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Who is paying for staging?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-photo-requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1533",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "1043",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "3854",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Photo Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Photo Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-does-your-listing-meet-the-twilight-photo-guidelines?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1213",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "5093",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "2875",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Does your listing meet the Twilight Photo Guidelines?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Does your listing meet the Twilight Photo Guidelines?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-video-requirements",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3704",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7895",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "1692",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Video Requirements",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Video Requirements",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-drone-footage",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "9618",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "0764",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "0050",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Drone Footage",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Drone Footage",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-elevated-photos",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8997",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "7218",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8433",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Elevated Photos",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Elevated Photos",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-are-you-using-the-listing-manager-system?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7149",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "9205",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8119",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Are you using the listing manager system?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Are you using the listing manager system?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-photo-notes",
        "type": "InputField",
        "name": "Photo Notes",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Photo Notes",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-direct-mail-marketing",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "8679",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3644",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "1292",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Direct Mail Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Direct Mail Marketing",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "CheckBox-water/sewer---check-all-that-apply",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "3035",
                    "value": "Public Water",
                    "isCheck": false
                },
                {
                    "id": "4704",
                    "value": "Public Sewer",
                    "isCheck": false
                },
                {
                    "id": "5831",
                    "value": "Septic",
                    "isCheck": false
                },
                {
                    "id": "8028",
                    "value": "Well Water",
                    "isCheck": false
                }
            ],
            "label": "Water/Sewer - Check All That Apply",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Water/Sewer - Check All That Apply",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-occupancy",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1868",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "8433",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "4450",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Occupancy",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Occupancy",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-do-you-have-a-key",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "0467",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "3030",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "9953",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Do you have a key",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do you have a key",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-contractor-box-code",
        "type": "InputField",
        "name": "Contractor Box Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Contractor Box Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant-name",
        "type": "InputField",
        "name": "Tenant Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-tenant-phone",
        "type": "InputField",
        "name": "Tenant Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-tenant-email",
        "type": "Email",
        "name": "Tenant Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Tenant Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "FreeText-seller-mailing-address",
        "type": "FreeText",
        "name": "Seller Mailing Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": false,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-street-address",
        "type": "InputField",
        "name": "Seller Mailing Address-Street Address",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Street Address",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-address-line-2",
        "type": "InputField",
        "name": "Seller Mailing Address-Address Line 2",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Address Line 2",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-city",
        "type": "InputField",
        "name": "Seller Mailing Address-City",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-City",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-state",
        "type": "InputField",
        "name": "Seller Mailing Address-State",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-State",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-zip-code",
        "type": "InputField",
        "name": "Seller Mailing Address-Zip Code",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Zip Code",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-seller-mailing-address-country",
        "type": "InputField",
        "name": "Seller Mailing Address-Country",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Seller Mailing Address-Country",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "DropDown-do-the-sellers-text",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "7588",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "2446",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "7800",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Do the sellers text",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Do the sellers text",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-is-this-a-flipped-property-with-an-existing-title-binder?",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "3130",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "9648",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "6510",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Is this a flipped property with an existing title binder?",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Is this a flipped property with an existing title binder?",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "TextArea-type-property-description-for-mls-and-marketing",
        "type": "TextArea",
        "name": "Type Property Description for MLS and Marketing",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Type Property Description for MLS and Marketing",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "CheckBox-cancellation/expiration-fee",
        "type": "CheckBox",
        "settings": {
            "options": [
                {
                    "id": "6942",
                    "value": "I have read and agree to the cancellation/expiration fee in the popup window",
                    "isCheck": false
                }
            ],
            "label": "Cancellation/Expiration Fee",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": "Cancellation/Expiration Fee: When utilizing the listing management system, if the listing expires or cancels after the photo/video shoot is complete, a fee of $500 will be charged. This will be auto-debited from the credit card you have on file with the company."
        },
        "name": "Cancellation/Expiration Fee",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-listing-manager",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "1040",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "5045",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "8168",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Listing Manager",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Listing Manager",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "DropDown-signed-vip-agreement",
        "type": "DropDown",
        "settings": {
            "options": [
                {
                    "id": "5923",
                    "value": "First Choice",
                    "isCheck": false
                },
                {
                    "id": "0854",
                    "value": "Second Choice",
                    "isCheck": false
                },
                {
                    "id": "9937",
                    "value": "Third Choice",
                    "isCheck": false
                }
            ],
            "label": "Signed VIP Agreement",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        },
        "name": "Signed VIP Agreement",
        "field_sub_type": "opportunity"
    },
    {
        "elementid": "InputField-cooperating-agent-name",
        "type": "InputField",
        "name": "Cooperating Agent Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-agent-phone",
        "type": "InputField",
        "name": "Cooperating Agent Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-agent-company",
        "type": "InputField",
        "name": "Cooperating Agent Company",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Company",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-tc-name",
        "type": "InputField",
        "name": "Cooperating TC Name",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Name",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "InputField-cooperating-tc-phone",
        "type": "InputField",
        "name": "Cooperating TC Phone",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Phone",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-cooperating-agent-email",
        "type": "Email",
        "name": "Cooperating Agent Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating Agent Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    },
    {
        "elementid": "Email-cooperating-tc-email",
        "type": "Email",
        "name": "Cooperating TC Email",
        "field_sub_type": "opportunity",
        "settings": {
            "label": "Cooperating TC Email",
            "predefine": "",
            "size": "large",
            "required": false,
            "hidden": true,
            "minValue": "0",
            "maxValue": "",
            "instructions": ""
        }
    }
]
export const WhisselCloseFormConditon= [
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Update_Existing_Transaction",
                operator: "equals",
                field_id: "DropDown-creating-updating-transaction"
              },
              {
                data_type: "dropdown",
                expected_value: "Buyer",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-fub-buyer-deal-id"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Closed",
                operator: "equals",
                field_id: "DropDown-transaction-stage"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "Date-opp_settlement_date",
            "NumberField-transaction-amount",
            "FreeText-address",
            "InputField-address-street-address",
            "InputField-address-address-line-2",
            "InputField-address-city",
            "InputField-address-state",
            "InputField-address-zip-code",
            "InputField-address-country"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Update_Existing_Transaction",
                operator: "equals",
                field_id: "DropDown-creating-updating-transaction"
              },
              {
                data_type: "dropdown",
                expected_value: "Seller",
                operator: "equals",
                field_id: "DropDown-client-type"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "InputField-fub-seller-deal-id"
          ],
          ConditionName: "Condition Name"
        },
        {
          condtions: [
            [
              {
                data_type: "dropdown",
                expected_value: "Pending",
                operator: "equals",
                field_id: "DropDown-transaction-stage"
              }
            ]
          ],
          Visibility: "show",
          Fields: [
            "DropDown-pending-stage"
          ],
          ConditionName: "Condition Name"
        }
      ]
    
  
