import React, {
  useState,
  useEffect,
  useCallback,
  useContext
} from "react";
import ArrPng from "../assets/images/arr.png";
import PopulateCustomField from "./Fub/PopulateCustomField";
import AddTag from "./Fub/AddTag";
import TestGenericAction from "./TestGenericAction";
import UpdateUser from "./Fub/UpdateUser";
import ActionPlans from "./Fub/ActionPlans";
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";
import FubViewOnly from "./view-only-actions/FubViewOnly";
import UpdateFubDeal from "./Fub/UpdateFubDeal";
import CreateFubDeal from "./Fub/CreateFubDeal";


const optionsList = [
  {
    "Set Tag": {
      "FIlter By Asigned Agent":
        "Filter if call was created by an Assigned Agent",
      "Populate Custom Field": "Populate FUb Custom Field",
      "Set Tag": "Set Tag",
    },
    "Populate Custom Fields": {
      "FIlter By Asigned Agent":
        "Filter if call was created by an Assigned Agent",
      "Populate Custom Field": "Populate FUb Custom Field",
    },
    "Update Person":{},
    "Apply Action Plan":{},
    // "Create Deal":{},
    // "Update Deal":{}
  },
];

const Fub = ({
  action_index,
  UpdateAction,
  action_info,
  workflowHelper,
  action_id,
  selected_action,
  setSelected_action,
  triggerdetails,
}) => {

  const [testAction, setTestAction] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [testAgain, settestAgain] = useState(true);
  const {session, updating} = useContext(WorkFlowSessionInfo);
  const [test_result, set_test_result] = useState(session?.[action_id]?.action_status === "SUCCESS" ? false : true);

  const update_data_mapper = (key, value) => {
    UpdateAction({
      data_mapper: { ...action_info?.data_mapper, [key]: value },
    });
  };
  
  const [selectAccordionTab, setSelectAccordionTab] = useState("custom");

  const [selectAutomation, setSelectAutomation] = useState(action_info.automation_name || "");

  const [selected_modal_id, select_modal_id] = useState("");
  const select_modal = useCallback((field_id) => {
    select_modal_id(field_id);
  }, []);

  useEffect(() => {
    if (selectAutomation) {
      UpdateAction({
      automation_name: selectAutomation,
      test_status: "Not Tested",
    });
  }
}, [selectAutomation]);

useEffect(() => {
  session?.[action_id]?.action_status === "SUCCESS" ? set_test_result(false) : set_test_result(true)
}, [session]);


useEffect(() => {
  if(session?.[action_id]){
    setTestAction("test_action_again")
    setShowDetails(true)
  }
  else{
    setShowDetails(false)
  }
}
, []);

const getAutomationOptions = () => {
return Object.keys(optionsList).map((key)=>{
  return Object.keys(optionsList[key]).map((key1)=>{
    return <option value={key1}>{key1}</option>
  }
  )
})
};

  const selectFields = () => {
    if(selectAutomation==="Set Tag"){
      return (
        <AddTag
        update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          action_info={action_info}
          workflowHelper={workflowHelper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          triggerdetails={triggerdetails}
          data_mapper={action_info.data_mapper}
        />
      );
    }
    else if(selectAutomation==="Populate Custom Fields"){
      return (
        <PopulateCustomField
          data_mapper={action_info.data_mapper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      );
    }
    else if(selectAutomation==="Update Person"){
      return (
        <UpdateUser
          data_mapper={action_info.data_mapper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      );
    }
    else if(selectAutomation==="Apply Action Plan"){
      return (
        <ActionPlans
          data_mapper={action_info.data_mapper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      );
    }
    else if(selectAutomation==="Create Deal"){
      return (
        <CreateFubDeal
          data_mapper={action_info.data_mapper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      );
    }
    else if(selectAutomation==="Update Deal"){
      return (
        <UpdateFubDeal
          data_mapper={action_info.data_mapper}
          action_id={action_id}
          select_modal={select_modal}
          selected_modal_id={selected_modal_id}
          update_data_mapper={update_data_mapper}
          UpdateAction={workflowHelper.update_action_info(action_id)}
        />
      );
    }
  };

  return (
    <>
        {selected_action !== action_info.action_id ? (
           <div
             className="pointer"
             onClick={() => setSelected_action(action_info.action_id)}
           >
          <FubViewOnly action_index={action_index} selectAutomation={selectAutomation} test_result={test_result} />
          </div>
        ) : (
          <>
          <div className="d-flex justify-content-center">
        <img height="70" src={ArrPng} alt="" />
      </div>
      <div className="dot-border rounded-3 bg-white gx-2 mb-2 p-4 border position-relative">
        { test_result &&
          (
          <div className="d-flex align-items-start mb-3 tooltip-container bvjk">
            <i
              className="iconify text-danger mns-auto"
              data-icon="mdi:info-circle"
            ></i>
            <div className="tooltip-text">
              Please test the action before saving workflow!{" "}
            </div>
          </div>
        )}
        <div className="w-100 d-flex justify-content-between">
          {!action_info.is_default ? (
            <p
              className="text-decoration-underline wait text-secondary small pointer"
              onClick={() => {
                workflowHelper?.change_action_to_unknown(action_id);
              }}
            >
              <i className="iconify" data-icon="mdi:arrow-left"></i>Change
            </p>
          ) : (
            <p></p>
          )}
          <span className="pointer" onClick={() => workflowHelper?.remove_action(action_id)}>
            <i
              className="iconify p-0 fs-5 text-secondary wait"
              data-icon="mdi:trash"
            ></i>
          </span>
        </div>
          <div className="w-100 mb-4 pointer">
            <div className="d-flex align-items-center bg-white py-3 pb-2">
            <strong> {action_index+". "}</strong> 
              <img
                className="me-2 p-0 fs-5 ms-2"
                height="20"
                src="https://assets-global.website-files.com/5c87ecb83f656c685ed4cf87/5caf60869e5e1c447811df96_logo_alt.png "
                alt=""
              />
            </div>
          </div>
          <p className="w-100 txt-gy mb-3">Select an action for this automation</p>
          <form className="position-relative">
            <div className="">
              <div className="input-group mb-3">
                <select
                  placeholder="Select Tag"
                  className="form-control form-select input-bn"
                  required
                  name="select_automation"
                  value={selectAutomation}
                  onInput={(e) => {
                    setSelectAutomation(e.target.value);
                  }}
                >
                  <option value="" selected hidden>
                    Select an automation
                  </option>
                  {getAutomationOptions()}
                </select>
              </div>
              <i className="iconify fs-3 text-secondary"data-icon="mdi:arrow-down-thin"></i>
            </div>
            {selectFields()}
          </form>
        
          {selectAutomation && (
          <div className="w-100 mt-4 modal-header">
            <div
              className="d-flex align-items-center  tooltip-container" 
            >
              <i
                className="iconify p-0 fs-3 me-1 text-primary"
                data-icon="ic:round-network-check"
              ></i>
              <button className={`d-none d-md-block text-decoration-underline btn btn-outline-none ${updating ? 'disabled-test' : ''}`}
                onClick={()=> {
                  if(!updating){
                    setShowDetails(false)
                    settestAgain(true)  
                    testAction === "tested_action" ? setTestAction("test_action_again") :  setTestAction("tested_action");
                  }  
                  }}
                >{testAction ? "Test Action Again" : "Test Action" }
              </button>
              {updating && (
                  <div className="tooltip-text">
                  Cannot test action when workflow is being updated
                  </div>
                )}
              
              { test_result && ( 
                <p className="text-danger m-3">
                  Please test the action!
                </p>
              )}
            </div>
            {testAction && testAction !== "test_action" && (
              <button
              type="button"
              className=" dropdown-toggle mt-1 btn-outline"
              data-bs-toggle="collapse"
              data-bs-target="#test_fub_automation"
              onClick={() => {setShowDetails(!showDetails)
                setTestAction("test_action_again")
                settestAgain(false)   
            }}
            >
              {showDetails ? "Show previous test details" : "Hide" }
            </button>
                )}
          </div>        
          )}

          {testAction === "tested_action" && (
                <TestGenericAction
                  testAction={testAction}
                  setTestAction={setTestAction}
                  action_id={action_info.action_id}
                  set_test_result={set_test_result}
                  setShowDetails={setShowDetails}
                  selected_action={selected_action}
                  testAgain={testAgain}
                /> 
          )}

          {!showDetails && testAction === "test_action_again" && (
            
            <div
            id="test_fub_automation"
            className={`${showDetails ?  "show accordion-collapse collapse" : ""  }`}
            >
            <TestGenericAction
              testAction={testAction}
              setTestAction={setTestAction}
              action_id={action_info.action_id}
              set_test_result={set_test_result}
              setShowDetails={setShowDetails}
              selected_action={selected_action}
              testAgain={testAgain}
            /> 
            </div>
          )}       
        </div>
          </>
        )}{" "}
    </>
  );
};
export default Fub;
