import React, { useContext, useState} from "react";
import { gql, useMutation } from '@apollo/client';
import { WorkFlowSessionInfo } from '../../../pages/automation-builder/workflows/EditWorkflow'
import FieldBasedTriggerModal from "./FieldBasedTriggerModal";


const CreateFubTrigger = gql`
mutation CreateFubTrigger($type: String!, $fieldId: String) {
  CreateFubTrigger(type: $type, field_id: $fieldId) {
    statusCode
    message
    data
    trigger_id
  }
}`;

export function TriggersList({ platform, triggerdetails, setplatform, setTriggerDetails, availableTriggers, details, setdetails, setIsTriggerCreated}) {

  const [fieldKey, setFieldKey] = useState("");
  const [showFieldsDropdown, setShowFieldsDropdown] = useState(false);
  const { workflow_helper } = useContext(WorkFlowSessionInfo);

  const [
    createtrigger,
    { loading: creatingtrigger, error: createerror },
  ] = useMutation(CreateFubTrigger, {
    onCompleted: (createfubdata) => {
      if(createfubdata?.CreateFubTrigger?.statusCode >= 300){
         createfubdata?.CreateFubTrigger?.data?.errorMessage
          ? alert(createfubdata?.CreateFubTrigger?.data?.errorMessage) 
          : alert("An unkown error occured.")
      }
      else{
        setTriggerDetails({ ...details,test_status:"Not Tested", data_mapper: { ...details.data_mapper, trigger_id: createfubdata?.CreateFubTrigger.trigger_id, field_id: fieldKey } });
        workflow_helper.current.add_trigger({ ...details, test_status:"Not Tested",data_mapper: { ...details.data_mapper, trigger_id: createfubdata?.CreateFubTrigger.trigger_id } });
        setFieldKey("");
      }
      setIsTriggerCreated("created");
    },
  });

  const handleTriggerDetails = (trigger, triggertype) => {
    if (trigger.trigger_id) {
      setTriggerDetails({
        ...triggerdetails,
        action_id: "trigger",
        api_name: "trigger",
        api_type: "trigger",
        data_mapper: {
          type: triggertype,
          name: trigger.name,
          description: trigger.description,
          trigger_id: trigger.trigger_id,
          platform: platform,
          field_id: fieldKey
        },
      });
      workflow_helper.current.add_trigger({
        ...triggerdetails,
        action_id: "trigger",
        api_name: "trigger",
        api_type: "trigger",
        data_mapper: {
          type: triggertype,
          name: trigger.name,
          description: trigger.description,
          trigger_id: trigger.trigger_id,
          platform: platform,
          field_id: fieldKey
        },
      });

      setplatform("");
      setIsTriggerCreated("creating");
    } else {

      setdetails({
        ...triggerdetails,
        action_id: "trigger",
        api_name: "trigger",
        api_type: "trigger",
        data_mapper: {
          type: triggertype,
          name: trigger.name,
          description: trigger.description,
          trigger_id: "",
          platform: platform,
          field_id: fieldKey
        },
      });

      createtrigger({
        variables: {
          type: triggertype,
          fieldId: fieldKey
        },
      });
      setIsTriggerCreated("creating");
    }
  }

  if (creatingtrigger) return 'Loading...';

  if (createerror) return `Error! ${createerror.message}`;

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          onClick={() => {
            setplatform("");
          }}
        >
          <i className="iconify me-2 back-btn" data-icon="ep:back"></i>
        </div>
        <p className="m-0">Select Trigger you want to Connect</p>
      </div>
      <div className="my-4 row row-cols-m2 gx-4">
        {Object.entries(availableTriggers).map(
          ([triggerCategory, triggers], index) => (
            
            <div className="p-d0 mb-4">
              <div  
                className="border-bottom d-flex justify-content-between align-items-center py-2 pointer"
                data-bs-toggle="collapse"
                data-bs-target={`#trig-${index}`}
                onClick={()=>setShowFieldsDropdown(false)}
              >
                <span>{triggerCategory} triggers</span>
                <i className="iconify" data-icon="mdi:chevron-down"></i>
              </div>
              <div id={`trig-${index}`} className="ps-lg-2 pt-3 collapse">
                {Object.entries(triggers).map(([triggertype, trigger], i) => (
                  <div className="mb-2">
                  <div
                    className={`text-primary d-flex justify-content-between align-items-center py-2 border rounded-2 px-2 ${!trigger.included_inplan ? "pointer-disabled" : "pointer"}`}
                    key={i}
                    data-bs-toggle={`${trigger.name !== "Person Updated" && trigger.name !== "Deal Updated" ? "modal" : ""}`}
                    // data-bs-toggle= "modal"
                    data-bs-target={`${trigger.name !== "Person Updated" && trigger.name !== "Deal Updated" ? "#triggerModal" : "#new-sync"}`}
                    aria-disabled={!trigger.included_inplan}
                    onClick={() => {
                      if(!trigger.included_inplan){
                        return
                      }
                      if(trigger.name !== "Person Updated" && trigger.name !== "Deal Updated"){
                        handleTriggerDetails(trigger, triggertype)
                    }
                    else{
                      setShowFieldsDropdown(!showFieldsDropdown)
                    }
                    }}
                  >
                    <span>
                      <i className="iconify" data-icon="ic:sharp-bolt"></i>{" "}
                      {trigger.name}
                      {(trigger.name === "Person Updated" || trigger.name === "Deal Updated") && (
                        <span className="m-2 rounded-pill border border-warning fs-small px-2 py-1 alert-warning fw-bold ">
                          Beta
                        </span>
                      )}
                    </span>
                    <i className="iconify" data-icon="mdi:chevron-right"></i>
                  </div>
                  { !trigger.included_inplan && (<small className="text-danger mt-0">* Please upgrade the plan to use this trigger.</small>)}
                    {(trigger.name === "Person Updated" || trigger.name === "Deal Updated") &&
                      showFieldsDropdown &&
                      <FieldBasedTriggerModal setFieldKey={setFieldKey}
                        key={trigger.trigger_id}
                        handleTriggerDetails={handleTriggerDetails}
                        trigger={trigger}
                        triggertype={triggertype}
                        /> 
                      } 
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default TriggersList;
