import React, { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import useFormApiHelper from '../../../utils/useFormApiHelper'
import { Loaderr } from '../../../components/Loaderr';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { boardConetxt } from './ContextHelper';

const getFubData = gql`
query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`

export default function SettingsPage() {
  const { GetFormData, UpdateForm, UpdateFormSettings} = useFormApiHelper();
  const { board } = useContext(boardConetxt);
  // const { FormFieldsData, refetch } = GetFormFields();
  const { updateFormSetting } = UpdateFormSettings();
  const [pipelineInteracted, setPipelineInteracted] = React.useState(false);
  const [stageInteracted, setStageInteracted] = React.useState(false);


  const params = useParams();
  const { formDataLoading, formData } = GetFormData(params);
  const [description, setDescription] = React.useState("")
  const [FormEntryData, setFormEntryData] = React.useState({
    "pipelineName": "",
    "StageName": "",
    "Description": ""
   
  });
  const { data: fubData } = useQuery(getFubData, {
    variables: {
      type: "pipelines"
    },

  })
  const [status] = React.useState(null)
  console.log("fubData", fubData,FormEntryData);
  const inputHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "pipelineName") setPipelineInteracted(true);
    if (name === "StageName") setStageInteracted(true);
    setFormEntryData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const {  updateFormLoading} = UpdateForm();



  // useEffect(()=>{
  //   if (FormEntryData?.pipelineName && FormEntryData?.StageName) {
  //     updateFormSetting({
  //     variables: {
  //       formId: params.id,     
  //       formDescription: description,
  //       pipeline: FormEntryData?.pipelineName,
  //       stage: FormEntryData?.StageName,
  //       // status:status

  //     }
  //   })
  // }
  // },[FormEntryData, description])
  useEffect(() => {
    if (pipelineInteracted || stageInteracted ) {
      updateFormSetting({
        variables: {
          formId: params.id,
          formDescription: description,
          pipeline: FormEntryData?.pipelineName,
          stage: FormEntryData?.StageName,
          status:formData?.formData?.status
        }
      })
    }
  }, [FormEntryData, description, pipelineInteracted, stageInteracted])

  console.log(">>>", formData?.formData?.status)
const handleDescription=(e)=>{
  setDescription(e.target.value)

}
 useEffect(()=>{
// if(description){
  setDescription(formData?.formData?.form_description)
  setFormEntryData((prev)=>({
    ...prev,
    "pipelineName":formData?.formData?.pipeline,
    "StageName": formData?.formData?.stage,
  }))
  // setStatus(formData?.formData?.form_status)
// }  
 },[formData])
 console.log("FormEntryData??", FormEntryData)

  
  console.log("FormEntryData", description,formData?.formData)
  let SaveStatus;
  if (updateFormLoading) {
    SaveStatus = (
      <span
        className="text-secondary d-flex align-items-center"
        style={{ fontSize: "11px" }}
      >
        {" "}
        <i className="bx bx-loader-circle fs-4"></i>{" "}
        <span className="d-none d-lg-block">Saving</span>
      </span>
    );
  } else {
    SaveStatus = (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>{" "}
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  }

  // const handleStatus = (e) => {
  //   console.log("status", e);
  //   setStatus(e);
  //   updateFormSetting({
  //     variables: {
  //       formId: params.id,
  //       status: e
  //     }
  //   })
    
  // }


  if (formDataLoading) return <Loaderr />
  return (
    <>
      <div className="main fmc-main">
        <section className="fm-condition">
          <section className="col-xxl-5 col-lg-6 col-md-9 mb-5 pb-5 col-12 mx-auto">
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 py-4">
              <h5 className="m-0">
                <i className="iconify me-1 nav_icon" data-icon="fa-solid:cog" />{" "}
                Settings
              </h5>
            </div>
            {/* -------------------------------------------------------------- */}
            <div className="mx-auto bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="w-100 d-flex justify-content-between m-0 mb-3">
              <h5 className="fs-6 m-0 mb-4">Form Settings</h5>
          <span>{board?.length >= 0 ? SaveStatus : ""}</span>
        </div>
              <div className="mb-3 disable-default-field-div">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="tdesign:form"
                  />
                  Form Type
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3 disable-default-field">
                  <option >{formData?.formData?.form_type}</option>
                </select>
              </div>
              <div className="mb-3">
              <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Form Description</span>
                    </span>
                  </label>
                <div className=" mb-3">
                  <textarea
                    type="text"
                    className="form-control input-bn"
                    id="floatingInput"
                    name='Description'
                    placeholder="Form Description"
                    value={description} 
                    onChange={(e)=>{handleDescription(e)}}
                  />
                </div>
              </div>
              {formData?.formData?.form_type == "Opportunity Forms" && <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:house-user"
                  />
                  Select Pipeline
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="pipelineName"
                value={FormEntryData?.pipelineName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {fubData?.GetFubAllData?.output?.map((item, index) => (
                    <option key={index} value={item.id}>{item.name}</option>
                  ))


                  }

                </select>
              </div>}
     {/* <div className="iconify mb-3" data-icon="cil:arrow-bottom" /> */}
       {formData?.formData?.form_type == "Opportunity Forms" && <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:bars-staggered"
                  />
                  Select stage
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="StageName"
                value={FormEntryData?.StageName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {

                    fubData?.GetFubAllData?.output.map((item, index) => {
                      console.log("C", FormEntryData?.pipelineName,item.id)
                      if (item.id == FormEntryData?.pipelineName) {
                        return (
                          item.stages.map((items, index) => {
                            return (
                              <option key={index} value={items.id}>{items.name}</option>
                            )
                          })
                        )
                      }
                    }
                    )
                  }
                  {/* <option>-- Select Stage --</option>
            <option>Appointment Met</option> */}
                </select>
              </div>}

            {/* <div className='mb-3 d-flex justify-content-between'>
              <label className="mb-2 d-flex align-items-center">
                <i
                  className="iconify fs-6 me-2 nav_icon"
                  data-icon="fa6-solid:file-signature"
                />
                Form Status
              </label>
              

             

              <Switch
                onChange={(e) => {
                  handleStatus(e)
                }}
                checked={status}
              />





              </div> */}
              {/* <span>Enable to show Form in Embedded App</span> */}

            </div>
            {/* --------------------------------------------------------------- */}
            
            {/* <div className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="d-flex justify-content-between">
                <h5 className="fs-6 m-0">Field Mapping</h5>
               <NavLink to={`/forms/fieldmapping/${params.id}`} target="_blank">
                <span>
                <i
                  className="iconify fs-6 me-2 nav_icon pointer"
                  data-icon="fa6-solid:arrow-up-right-from-square"
                />
                </span>
               </NavLink>
              </div>
              <hr className="h op-10" />
              <div>
                <p className="fs-6 m-0 mt-3">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:arrows-split-up-and-left"
                  />
                  Total mapped field : <b className="text-primary">14</b>
                </p>
                <p className="text-secondary small mt-2">
                  There are 14 form field connected and mapped to FUB or respective
                  CRM
                </p>
              </div>
            </div> */}

           


            {/* --------------------------------------------------------------- */}
            {/* <div className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="d-flex justify-content-between">
                <h5 className="fs-6 m-0">Automation Trigger </h5>
                <i
                  className="iconify fs-6 me-2 nav_icon pointer"
                  data-icon="fa6-solid:arrow-up-right-from-square"
                />
              </div>
              <hr className="h op-10" />
              <div>
                <p className="fs-6 m-0 mt-3">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:bolt"
                  />
                  Total Workflow triggered : <b className="text-primary">8</b>
                </p>
                <p className="text-secondary small mt-2">
                  Forms can be used to trigger workflow in automation builder, this
                  could be upon submission of form or selection of a field.{" "}
                </p>
              </div>
            </div> */}
            {/* --------------------------------------------------------------- */}
          </section>
        </section>
      </div>

    </>

  )
}
