import "./form-builder-style.css";
import { FormBuilderMiddleBar } from "./FormBuilderMiddleBar";
import { ToastContainer } from "react-toastify";

import { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import uuidGenerator from "../../../utils/uuidGenerator";
import { boardConetxt } from "./ContextHelper";
import { ElemContext } from "./ContextHelper";
import { ElementContext } from "./SettingContext";
import ElementsSettingsModal from "../../../components/form-builder-components/BoardElementsUI/ElementsSettingsModal";
import FormBuilderLeftSideBar from "./FormBuilderLeftSideBar";
import FormBuilderRightSideBar from "./FormBuilderRightSideBar";
// import { makeApiCall } from "../../../modules/MakeApiRequest";
import { gql} from "@apollo/client";
import { useQuery } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";
// import { conditionContext } from "./ContextHelper";
import bst from "../../../assets/images/interface_analytics.png";
import { DeleteModalComponet } from "../../../components/form-builder-components/BoardElementsUI/DeleteModalComponet";
import { FormPlanContext } from "./ContextHelper";
// import { set } from "react-hook-form";
// import Switch from "react-switch"
import { CustomeElementDeleteModal } from "../../../components/CustomeElementDeleteModal";
import { get } from "react-hook-form";
// const createField= gql`
// mutation CreateField($fieldData: GraphQLJSON) {
//   CreateField(field_data: $fieldData) {
//     field_id
//   }
// }`

const getISAUser=gql`
query GetISAOSAUsers($teamId: String) {
  GetISAOSAUsers(team_id: $teamId) {
    isa_list
    osa_list
  }
}`

const getAdminUser=gql`query GetAdminUsers($teamId: String) {
  GetAdminUsers(team_id: $teamId) {
    id
    name
  }
}`

const DashBoardForFormBuilder = () => {
  const [setElement, setSetElement] = useState({});
  const [url] = useState("");
  // const [ setColor] = useState("");
  const [CustomElement, setCustomElement] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [AllFields,setAllFields]=useState([])
const [DeleteField,setDeleteField]=useState(null)
const {formPlan}=useContext(FormPlanContext)
const [CustomEleDeleteId, setCustomEleDeleteId] = useState({
  elementid: "",
  name: "",
  refetchform:null

});

  const [defaultElement, setDefaultElement] = useState([]);

  const [getFormData, setGetFormData] = useState();

  const { board, setboard } = useContext(boardConetxt);
  // const { setCondition } = useContext(conditionContext);

  const params = useParams();
  const formNameRef = useRef(null);
  const { GetFormData, UpdateFormName, CreateFieldFuntion, UpdateForm } = useFormApiHelper();
  // UpdateForm

  
 const {createFieldMutation}=CreateFieldFuntion()
  const {UpdateFormData}=UpdateFormName()
  const { formDataLoading, formData } = GetFormData(params);
  const {updateFormLoading}=UpdateForm()
  const [name, setName] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);

  // const  formName=useRef("")
  const [index, setIndex] = useState(null);
  const [formStatus, setFormStatus] = useState(null);

  const { data, loading } = useQuery(getISAUser, {
    variables: {
      teamId: getFormData?.team_id,
    },
  });

  const { data:adminData, loading:adminLoading } = useQuery(getAdminUser, {
    variables: {
      teamId: getFormData?.team_id,
    },
  });

  console.log("admin",adminData?.GetAdminUsers)

console.log("PlanLimit",data?.GetISAOSAUsers)
  useEffect(() => {
    const newelement = formData?.formData?.form_fields?.map((item) => {
      // console.log("item><<",item)
      return { ...item };
    });    
    setboard(newelement);
    let getForm = formData?.formData;
    setGetFormData(getForm);
    setName(getForm?.form_name);
    setFormStatus(getForm?.status);
    
  }, [formData?.formData,formData?.formData?.form_fields]);

console.log("isCollapsed",formData?.formData?.form_conditions)


// Later on, you can just call this function to update the form color and url
  //console.log("element =====>",board)
  // useEffect(() => {
  //   UpdateFormData({
  //     variables: {
  //       formId: formData?.formData?.form_id,
  //       logo_url: url,
  //       color_scheme: color,
  //     },
  //   });
  // }, [color, url]);

  // useEffect(() => {
  //   // board?.map((item) => {
  //   //   const temp=Array.from(defaultElement)
  //   //   const itemList = temp.filter((items) => items?.elementid == item?.elementid);
  //   //   const lastItemindex=itemList.length-1
  //   //   const lastIndexList=temp.findIndex((items)=>items?.elementid==item?.elementid)
  //   //   temp.splice(lastIndexList,1)
  //   //   temp.push(itemList[lastItemindex])
  //   //   setDefaultElement(temp)
  //   // });
  // },[]);
  // useEffect(() => { 
  //   const temp=Array.from(defaultElement)
  //   board?.map((item) => {
  //     const itemList = temp.filter((items) => items?.elementid == item?.elementid);
  //     const lastItemindex=itemList.length-1
  //     const lastIndexList=temp.findIndex((items)=>items?.elementid==item?.elementid)
  //     temp.splice(lastIndexList,1)
  //     temp.push(itemList[lastItemindex])
  //   }
  //   );
  //   setDefaultElement(temp)
    

  // }, []);
  if (formDataLoading || updateFormLoading) return <Loaderr />;

  console.log("newelement",board)
 


  if (formDataLoading) return <Loaderr />;
  const onDragEnd = (result) => {
    console.log("result", result)
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId === "DefaultElements") {
      //console.log("DefaultElements", result);
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
            const ElementType = result.draggableId.split("-")[0];
      console.log("ElementType: ", endIndex,cloneUsers);
      let newelement = {
        elementid:result.draggableId,
        type: ElementType,
        // name: "",
        settings: {},
      };
      defaultElement?.map((item) => {
        if (item?.elementid === result?.draggableId) {
          newelement.name = item.name;
          newelement.linked_to = item.linked_to;
          newelement.settings = item.settings;
        }
      });
      console.log("newelement", newelement);

      cloneUsers.splice(endIndex, 0, newelement);
      let uniqueData = Array.from(
        cloneUsers.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
      );
      console.log("cloneUsers",cloneUsers)
      
      setboard(uniqueData);
      //removeElementDefault(ElementType);
      return;
    }

    if(result.source.droppableId === "AllElements"){
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
      const ElementType = result.draggableId.split("-")[0];

      let newelement = {
        elementid: result.draggableId,
        name: ElementType,
        settings: {},
      };
      AllFields?.map((item) => {
        if (item?.elementid === result.draggableId) {
          newelement.type = item.type;
          newelement.settings = item.settings;
          newelement.name = item.name;
        }
      });
      console.log("newelement", newelement)
      cloneUsers.splice(endIndex, 0, newelement);
      let uniqueData = Array.from(
        cloneUsers.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
      );

      setboard(uniqueData);
      return;
    }

 

    if (result.source.droppableId === "CustomElements") {
      //console.log("CustomElements", result);
      const endIndex = result.destination.index;

      const cloneUsers = Array.from(board);
      // const ElementType = result.draggableId.split("-")[0];
      // //console.log("ElementType: ", ElementType);
      let newelement = {
        elementid: result.draggableId,
        // type:"",
        // name: ElementType,
        settings: {},
      };
      CustomElement?.map((item) => {
        if (item?.elementid === result.draggableId) {
          newelement.type = item.type;
          newelement.settings = item.settings;
          newelement.name = item.name;
       
        }
      });
      console.log("newelement", newelement)
      cloneUsers.splice(endIndex, 0, newelement);
      let uniqueData = Array.from(
        cloneUsers.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
      );

      setboard(uniqueData);
      // removeElementCustom(ElementType);
      return;
    }

    if (result.source.droppableId === "PrimaryElements") {
      //console.log("PrimaryElements", result);
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
      const ElementType = result.draggableId.split("-")[0];
      //console.log("ElementType: ", ElementType);
      let newelement = {
        elementid: result.draggableId,
        type: ElementType,
        // name: "",
        settings: {},
      };
      primary?.map((item) => {
        if (item?.elementid === result.draggableId) {
          newelement.name = item.name;
          newelement.linked_to = item.linked_to;
          newelement.settings = item.settings;
        }
      });
      //console.log("newelement", newelement);

      cloneUsers.splice(endIndex, 0, newelement);
      let uniqueData = Array.from(
        cloneUsers.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
      );
      //console.log("cloneUsers",cloneUsers)

      setboard(uniqueData);
      //removeElementDefault(ElementType);
      return;
    }
    

    // //console.log(result)
    if (result.source.droppableId === "Elements") {
      //console.log("Elements", result);
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
            let newelement = {
        elementid: String(uuidGenerator(result.draggableId)?.elementid),
        type: result?.draggableId,
        name: result.draggableId,
        settings: {},
      };

      createFieldMutation({
  variables: {
    fieldData: {
      field_id: newelement?.elementid,
      input_type: newelement?.type,
      field_name: newelement.type,
      field_type: "custom",
      field_sub_type:formData?.formData?.form_type.split(" ")[0].toLowerCase(),
      settings: {},
    }
  }
})

cloneUsers.splice(endIndex, 0, newelement);
let uniqueData = Array.from(
  cloneUsers.reduce((map, item) => map.set(item.elementid, item), new Map()).values()
);
      setboard(uniqueData);

      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const cloneUsers = Array.from(board);
    const [removed] = cloneUsers.splice(startIndex, 1);
    cloneUsers.splice(endIndex, 0, removed);
    console.log("CloneUsers",cloneUsers)

    setboard(cloneUsers);
  };
  // console.log("newboard",formData?.formData?.form_type)

  const style = {
    overflowY: "auto",
    height: "100vh",
  };
  const show = board?.length > 0 ? style : null;

  //Handle Reset Form
  // const HandleRestForm = (e) => {
  //   console.log("formData?.formData?.form_type==>",formData?.formData?.form_type)
  //   if(formData?.formData?.form_type==="Opportunity Forms"){
  //     updateForm({
  //       variables: {
  //         formId: params.id,
  //         formData: {
  //           ...formData?.formData,
  //           form_fields: ["InputField-Follow-up-boss-lead-id","InputField-opp-key"],
  //           form_conditions:[],
  //           form_name: getFormData?.form_name,
  //           form_description: getFormData?.form_description,
            
           
  //         },
  //       },
  //     });
  //     setboard([])
  //     setCondition([])
  //   }else if(formData?.formData?.form_type=="Lead Forms"){
  //     updateForm({
  //       variables: {
  //         formId: params.id,
  //         formData: {
  //           ...formData?.formData,
  //           form_fields: ["InputField-Follow-up-boss-lead-id"],
  //           form_conditions:[],
  //           form_name: getFormData?.form_name,
  //           form_description: getFormData?.form_description,
          
  //         },
  //       },
  //     });
  //     setboard([])
  //     setCondition([])
  //   }
    
  //   // e.preventDefault();
  //   // updateForm({
  //   //   variables: {
  //   //     formId: formData?.formData?.form_id,
  //   //     form_fields: [],
  //   //     form_conditions: [],
  //   //     form_name: formData?.formData?.form_name,
  //   //     form_type: formData?.formData?.form_type,
  //   //     // logo_url: "",
  //   //     // color_scheme: "",

  //   //   },
  //  // })
    
  //   // setboard([]);
  //   // setCondition([]);
  //   //console.log("HandleRestForm",board)
  // };

  //handle Upload Logo
  // const handleUploadLogo = (e) => {
  //   e.preventDefault();
  //   setUrl(e.target.value);
  // };
  console.log("handleUploadLogo", url);

  //handle color change
  // const handleColorChange = (e) => {
  //   localStorage.setItem("color", e.target.value);
  //   setColor(e.target.value);
  // };
  const handleRename = () => {
    setIndex(name);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setName(formNameRef.current.value);
    setIndex(formNameRef.current.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    console.log("handleSave");
    UpdateFormData({
      variables: {
        formId: formData?.formData?.form_id,
        formName: name,
      },
    });
    setIndex(null);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setName(name);
    setIndex(null);
  };
  const handleChangeLogo = () => {
    // setShowLogo(false)
  };

  const handleLiveChange = () => {
    setFormStatus(!formStatus);
   
  };

  // console.log("duplicateFormData",formStatus)
  return (
    <div style={show} onClick={handleChangeLogo}>
      <DeleteModalComponet DeleteField={DeleteField} />
      <ToastContainer />
      <CustomeElementDeleteModal CustomEleDeleteId={CustomEleDeleteId} setCustomEleDeleteId={setCustomEleDeleteId} />

      <ElementContext.Provider value={{ setSetElement }}>
        <ElemContext.Provider
          value={{
            CustomElement,
            setCustomElement,
            defaultElement,
            setDefaultElement,
            AllFields,
            setAllFields ,
            primary,
            setPrimary,
          }}
        >
          {/* <boardConetxt.Provider value={{ board, setboard,color,setColor }}> */}
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="main fmc-main p-0" style={{ marginTop: "64px", position:"fixed" }}>
              <section>
                <div className="d-lg-none builder-stop">
                  <div className="text-center p-5 d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={bst} 
                      className="img-fluid col-5 px-2 mb-5"
                      alt=""
                    />
                    <h2>OOPS !!! We are sorry you cant build on mobile</h2>
                    <p>
                      Please kindly open form builder on your desktop or PC to
                      continue using this engine.
                    </p>
                  </div>
                </div>

                <section className="d-flex fm-stack" style={{ width: "100%" }}>

                  <div className=" pre-field col-lg-4 col-xxl-3 bg-white py-3 px-2 p-xl-3 border-end">
                    <FormBuilderRightSideBar formType={formData?.formData?.form_type} setCustomEleDeleteId={setCustomEleDeleteId}  teamId={getFormData?.team_id}  IsaList={data?.GetISAOSAUsers?.isa_list}
                    isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} 
/>
                  </div>
                  
                  <div className=" new-field col-lg-4 col-xxl-3 bg-white py-3 px-2 p-xl-3 border-end shadow-lg collapse" id="newF">
                    <FormBuilderLeftSideBar IsaList={data?.GetISAOSAUsers?.isa_list} />
                  </div>

                  <section className="build-contianer col px-5 mb-5 py-5 mx-auto" style={board?.length === 0 ? null : { overflow: "scroll" }}>
                    <div className="container col-xl-10 mx-auto bg-white d-flex align-items p-4 px-xl-5 py-4 mb-3 rounded-3 border">
                      <h5 className=" m-0">
                        {index === name ? (
                          <>
                            <input
                              type="text"
                              className="text pb-1 pt-0 tr-ease"
                              style={{
                                border: "none",
                                borderBottom:"1px solid #dee2e6",
                                outline: "none",
                                padding: "0",
                                margin: "0",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                              value={name}
                              ref={formNameRef}
                              onChange={handleChange}
                            />
                            <span
                              onClick={(e) => {
                                handleSave(e);
                              }}
                              style={{ color: "green" }}
                            >
                              <i
                                className="iconify pointer me-2 fs-5"
                                data-icon="iconoir:check"
                                // width="25"
                                // height="25"
                              ></i>
                            </span>
                            <span
                              onClick={(e) => {
                                handleCancel(e);
                              }}
                              style={{ color: "red" }}
                            >
                              <i
                                className="iconify pointer fs-5"
                                data-icon="iconoir:cancel"
                                // width="25"
                                // height="25"
                              ></i>
                            </span>
                          </>
                        ) : (
                          <>
                            <span>{name}</span>
                          </>
                        )}
                      </h5>
                      {index == null && (
                        <span
                          className="d-flex align-items-center"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            handleRename();
                          }}
                        >
                          <i
                            className="iconify pointer text-secondary"
                            data-icon="iconamoon:edit-fill"
                            width="20"
                            height="20"
                          ></i>
                        </span>
                      )}
                      
                      <div  className="d-flex align-items-center" style={{ marginLeft: "auto" }}>

                        <div className="form-check form-switch border-end me-3 pe-3">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={()=>{handleLiveChange()}} checked={formStatus} />
                          <label className="form-check-label small ml-1" for="flexSwitchCheckDefault">
                            {/* <Switch
                            ch
                             onColor="#86d3ff"
                             onHandleColor="#2693e6"
                             handleDiameter={10}
                             uncheckedIcon={false}
                             checkedIcon={false}
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                             height={17}
                             width={38}
                             className="react-switch"
                             id="material-switch"
                            /> */}
                            Live</label>
                        </div>

                        <div className="d-flex">
                            {/* <div className="dropdown pointer">
                              <i
                                className="iconify fs-4  "
                                data-icon="eva:more-horizontal-outline"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                              />
                              <div
                                className="dropdown-menu shadow border-0" aria-labelledby="dropdownMenuButton">
                                <span
                                  onClick={(e) => {
                                    HandleRestForm(e);
                                  }}
                                >
                                  <a className="dropdown-item small" href="#">
                                    Reset form
                                  </a>
                                </span>
                              </div>
                            </div> */}
                        </div>
                      </div>
                      
                    </div>
                    <FormBuilderMiddleBar setDeleteField={setDeleteField} getFormData={getFormData} formStatus={formStatus}  osaList={data?.GetISAOSAUsers?.osa_list

                    }             IsaList={data?.GetISAOSAUsers?.isa_list}
                    AdminList={adminData?.GetAdminUsers}
  />
                  </section>

                 
                </section>

              </section>
            </div>
          </DragDropContext>
          {Object.keys(setElement)?.length !== 0 ? (
            <ElementsSettingsModal
              elementid={setElement?.elementid}
              type={setElement?.type}
              IsaList={data?.GetISAOSAUsers?.isa_list}
              osaList={data?.GetISAOSAUsers?.osa_list}
              AdminList={adminData?.GetAdminUsers}
            />
          ) : (
            <></>
          )}
          {/* </boardConetxt.Provider> */}
        </ElemContext.Provider>
      </ElementContext.Provider>
    </div>
  );
};

export default DashBoardForFormBuilder;
