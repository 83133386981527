import React, { useState, useEffect, useRef } from "react";
import NoWorkflow from "../../assets/images/no-flow.png";
import Header from "../../components/header-component";
import { Loaderr } from "../../components/Loaderr";
import { gql, useQuery } from '@apollo/client';
import SingleTemplate from "../../components/SingleTemplate";
import SingleWorkflow from "./workflows/SingleWorkflow";
import '../../assets/css/automationBuilder.css';
import { Spinner } from "../../assets/images/three-dots-loading";
import debounce from "lodash/debounce";

const Workflows = gql`
  query Workflows($limit: Int, $offset: Int, $searchTerm: String) {
  Workflows(limit: $limit, offset: $offset, search_term: $searchTerm){
      workflow_name
      workflow_description
      trigger_id
      workflow_id
      status
      trigger_details {
        platform
        type
      }
    }
  }`;

const Templates = gql`
  query GetTemplates {
    GetTemplates {
      template_id
      template_name
      template_description
      status
      show_in_ui
    }
  }`;

  const WorkflowUsageStats = gql`
  query WorkflowUsageStats {
    workflowUsageStats {
      next_billing_date
      lifetime_actions_run
      billing_actions_run
      billing_limit
      actions_run_today
    }
  }`;

const WorkflowsCount = gql`
  query Query($searchTerm: String) {
    WorkflowsCount(search_term: $searchTerm)
  }`;

const AutomationBuilderDashboard = () => {
  
  const [workflowsList, setWorkflowsList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [workflowStats, setWorkflowStats] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isRefetching, setIsRefetching] = useState(false);
  const limit = 10;
  const { loading:workflowsLoading, data:workflowsData, fetchMore, refetch } = useQuery(Workflows);
  const {loading: templateLoading, data: templateData} = useQuery(Templates);
  const {data: workflowStatsData} = useQuery(WorkflowUsageStats);
  const {data: workflowsCountData} = useQuery(WorkflowsCount, {
    variables: { searchTerm: searchValue },
  });

  const handlePageClick = (page) => {
    if (page < 1 || page > Math.ceil(workflowsCountData?.WorkflowsCount / limit)) {
      return;
    }
  
    setCurrentPage(page);
    fetchMore({
      variables: {
        offset: (page -1) * limit,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          Workflows: [...fetchMoreResult.Workflows],
        };
      },
    });
  };

  useEffect(() => {
    let workflows = workflowsData?.Workflows;
    setWorkflowsList(workflows);
  }, [workflowsData?.Workflows]);
  
  useEffect(() => {
    let templates = templateData?.GetTemplates;
    setTemplateList(templates);
  }, [templateData]);

  useEffect(() => {
    let stats = workflowStatsData?.workflowUsageStats;
    setWorkflowStats(stats);
  }, [workflowStatsData]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    debounce(async (value) => {
      setIsRefetching(true);
      await refetch({ limit, offset: 0, searchTerm: value });
      setIsRefetching(false);
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  if (workflowsLoading || templateLoading ) return <Loaderr />;

  function searchList() {
    
    const filteredOptions = workflowsList?.filter(option => {
      // Check if the values are string before applying string methods
      const workflowNameString = option.workflow_name ? option.workflow_name.toString() : '';
      const workflowDescriptionString = option.workflow_description ? option.workflow_description.toString() : '';
      
      // Check if the search term is present in either the name or the description
      return (
        workflowNameString.toLowerCase().split(" ").join("").includes(searchValue.toLowerCase().split(" ").join("")) ||
        workflowDescriptionString.toLowerCase().split(" ").join("").includes(searchValue.toLowerCase().split(" ").join(""))
      );
    });
    
    const renderedOptions = filteredOptions?.map((option, i) => (
    <SingleWorkflow workflow={option} workflowsList={workflowsList} setWorkflowsList={setWorkflowsList} />
    ));
    return (
      <div>
        {renderedOptions?.length !== 0 ? (
          renderedOptions
        ) : (
          <div className="mt-3">{isRefetching ? "Searching workflows..." :  "No matches found."}</div>
        )}
      </div>
    );
  }

  let billing_limit = workflowStats?.billing_limit;

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(workflowsCountData?.WorkflowsCount / limit);
  
    const maxVisibleButtons = 5;
    const halfVisibleButtons = Math.floor(maxVisibleButtons / 2);
  
    let startPage = Math.max(1, currentPage - halfVisibleButtons);
    let endPage = Math.min(totalPages, currentPage + halfVisibleButtons);
  
    if (totalPages <= maxVisibleButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= halfVisibleButtons) {
        endPage = maxVisibleButtons;
      } else if (currentPage + halfVisibleButtons >= totalPages) {
        startPage = totalPages - maxVisibleButtons + 1;
      }
    }
  
    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  
    return (
      <div className="pagination-container">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {(currentPage !== 1 && totalPages > 5) && (
              <li className="page-item pointer">
                <div className="page-link" aria-label="Previous" onClick={() => handlePageClick(currentPage - 1)}>
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </div>
              </li>
            )}
            {pageNumbers.map((pageNumber) => (
              <li
                className={`page-item pointer ${pageNumber === currentPage ? 'active' : ''}`}
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
              >
                <div className="page-link">{pageNumber}</div>
              </li>
            ))}
            {(currentPage !== totalPages && totalPages > 5) && (
              <li className="page-item pointer">
                <div className="page-link" aria-label="Next" onClick={() => handlePageClick(currentPage + 1)}>
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  };
  

  return (
    <>
      <Header />
      <div className=" pt-5 container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5"></i>
              <i className="bx bx-arrow-to-right me-2 fs-5"></i>
              <i className="bx bx-desktop me-2 fs-5"></i>
            </div>

            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>

        <h5>Automation builder</h5>
        <p className="text-secondary mt-2">
          Sync, map fields and trigger automations with ease and create
          conditions with the desired platform. To create an automation you must
          first create a trigger to build an automation. For further help and
          assitance, contact <a className="text-primary text-decoration-underline" href="mailto:support@interface.re">here</a> and find out more about automating your
          work with your favourite platform.
        </p>

        {
          <>
            <section className="d-flex mb-3 flex-wrap">
              {/* <div
                className="col-lg-auto col-12"
                data-bs-toggle="modal"
                data-bs-target="#listOfUserTriggersModal"
              >
                <div className="d-flex align-items-center card-g bg-white me-md-3 my-md-3 my-2 p-3 pe-4 border shaddow-lg rounded-3">
                    <i
                      className="iconify me-2 p-0 display-4 text-primary"
                      data-icon="ic:sharp-bolt"
                    ></i>
                    <b className="me-2 fs-4">8</b>
                    <span>triggers</span>
                  </div>
              </div>

              <div className="col-lg-auto col-md-6 col-12">
                <div className="d-flex align-items-center card-g bg-white me-md-3 my-md-3 my-2 p-3 pe-4 border shaddow-lg rounded-3">
                  <i
                    className="iconify me-2 p-0 display-4 text-primary"
                    data-icon="ri:flow-chart"
                  ></i>
                  <b className="me-2 fs-4">2/3</b>
                  <span>Workflow used</span>
                  <span className="badge bg-warning rounded-pill ms-2 fw-normal text-small">
                    Free
                  </span>
                </div>
              </div> */}

              <div className="col-lg-auto col-md-6 col-12">
              <div className="d-flex flex-column justify-content-center card-g bg-white me-md-3 my-md-3 my-2 p-3 px-4 border shaddow-lg rounded-3">
                <div className="d-flex flex-wrap align-items-center mb-1">
                  {workflowStats && (() => {
                      if (billing_limit / 1000 < 1) {
                          return (
                              <b className="me-2 fs-6">{workflowStats?.billing_actions_run}/{billing_limit}</b>
                          );
                      } else if (billing_limit / 1000 >= 1 && billing_limit / 1000 < 1000) {
                          return (
                              <b className="me-2 fs-6">{workflowStats?.billing_actions_run}/{billing_limit / 1000}K</b>
                          );
                      } else {
                          return (
                              <b className="me-2 fs-6">{workflowStats?.billing_actions_run}/{billing_limit / 1000000}M</b>
                          );
                      }
                  })()}
                  <span className="me-lg-5 me-3">Actions</span>
                  {/* <span className="btn btn-small ms-auto btn-primary text-small rounded-pill fw-normal text-small">
                    + Recharge
                  </span> */}
                </div>

                <div className="progress w-100 mt-3 rounded-pill" style={{ height: "10px" }}>
                <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                        width: `${(workflowStats?.billing_actions_run / billing_limit) * 100}%`
                    }}
                    aria-valuenow={(workflowStats?.billing_actions_run / billing_limit) * 100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              </div>
            </section>

            <section>
              <ul className="nav nav-tabs" id="myTab-auto" role="tablist">

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                >
                  <i className="iconify fs-5 me-1" data-icon="ri:flow-chart"></i>
                  Automation
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                >
                  <i className="iconify fs-5 me-1" data-icon="bxs:widget"></i>
                  Templates
                </button>
              </li>
              </ul>

              <div className="tab-content" id="myTabContent-auto">

                <div className="tab-pane fade show active pt-3" id="profile" role="tabpanel">
                
                  
                  <div className="d-flex align-items-center justify-content-between flex-wrap py-3 border-Xbottom">
                    <h5 className="p-0 m-0">
                      Total <b>{workflowsCountData?.WorkflowsCount || 0}</b> {searchValue ? `workflows found for search term "${searchValue}"` : ""}
                    </h5>

                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search workflows"
                        value={searchValue}
                        onChange={(e)=>handleSearchChange(e.target.value)}
                      />
                      {isRefetching && <span className="text-secondary d-flex align-items-center ms-2">{Spinner}</span>}
                    </div>
                  </div>
                  {workflowsList?.length !== 0 ? (
                    <>
                      {searchList()}
                      {!isRefetching && (
                      <div className="pagination-controls">
                      {renderPageNumbers()}
                      </div>
                      )}
                    </>
                  ) : (
                    searchValue ? (<div className="mt-3">No matches found.</div>) : (
                    <div
                      id="workflow-list"
                      className="row gx-4 gy-1 flex-wrap mt-2 pb-5 mb-5"
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center col-lg-9 col-xl-4 mx-auto pt-5">
                        <img
                          className="img-fluid mb-3"
                          src={NoWorkflow}
                          alt=""
                        />
                        <h5>No Workflow created</h5>
                        <p className="text-secondary">
                          To learn more about triggers and workflow clik here{" "}
                        </p>
                      </div>
                    </div>
                    )
                  )}
                </div>

                <div className="tab-pane fade pt-4"id="home"role="tabpanel">
                
                  <span className="mt-4 text-secondary">Templates are predefined automation tailored built by us that are mostly used and best suited for you. All you need is to toggle on and off when needed</span>
                  
                  <section className="row row-cols-lg-3 row-cols-md-2 gx-4 gy-1 my-3 pb-5">     
                  {templateList?.map((template) => {
                    return (
                      <SingleTemplate template={template} />
                    );  
                  }
                  )}
                  </section>
                </div>
              </div>
            </section>
          </>
        }
      </div>
    </>
  );
};

export default AutomationBuilderDashboard;